import React, { useState } from "react";
import {
  Buttons,
  ContentSpan,
  Details,
  Placeholder,
  StyledDragIcon,
  StyledIconContent,
  Text,
  Title,
  Wrapper,
} from "./styles";
import { useLocale, useUserInfo } from "hooks";
import { dateFormatter } from "constants/utils";
import { useTranslation } from "react-i18next";
import {
  CategoryDTO,
  ContractAttachmentListItemDTO,
  ContractTemplateV1DTO,
} from "openapi";
import { getDarkenedColor, getSoftenedColor } from "utils/color";
import { Tag } from "new-components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { theme } from "theme";
import DragIcon from "assets/svg/drag-icon-2.svg?react";

type ListItemFields = "id" | "createdAt" | "categoryId";

type StringNode = string | React.ReactNode;

export type ListItem = (Partial<Pick<ContractTemplateV1DTO, ListItemFields>> &
  Partial<Pick<ContractAttachmentListItemDTO, ListItemFields | "parentId">>) & {
  name?: StringNode;
};

export type DocumentListItemProps = {
  id?: string;
  active?: boolean;
  authorName?: string;
  buttons?: JSX.Element;
  category?: CategoryDTO;
  showHoverEffect?: boolean;
  label?: JSX.Element;
  listItem: ListItem;
  onClick?: () => void;
  placeholder?: StringNode;
  isDraggable?: boolean;
  tags?: React.ReactNode;
  style?: React.CSSProperties | undefined;
  iconContent?: {
    icon: React.ReactNode;
    text: string;
  };
  buttonPlaceholder?: React.ReactNode;
};

const DocumentListItem = ({
  active = false,
  authorName,
  buttons,
  category,
  showHoverEffect = true,
  id,
  isDraggable = false,
  label,
  listItem,
  onClick,
  placeholder,
  tags,
  iconContent,
  style,
  buttonPlaceholder,
}: DocumentListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transition,
    transform,
    isDragging,
  } = useSortable({
    id: id as string,
  });

  const { locale } = useLocale();
  const { t } = useTranslation();
  const { userInfo } = useUserInfo();

  const userDateFormat = userInfo?.dateFormat;

  const formattedDate = dateFormatter(
    locale,
    listItem.createdAt,
    userDateFormat
  );

  const [hoverState, setHoverState] = useState(false);

  const handleMouseEnter = () => setHoverState(true);
  const handleMouseLeave = () => setHoverState(false);

  return (
    <Wrapper
      data-testid="document-list-item"
      $hoverEffect={showHoverEffect}
      $active={active}
      $isDraggable={isDraggable}
      $isClickable={!!onClick}
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
        ...style,
      }}
      $hoverState={hoverState}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledDragIcon
        {...attributes}
        {...listeners}
        className="document-list-item-drag-icon"
        data-testid="document-list-item-drag-icon"
        style={{
          cursor: isDragging ? "grabbing" : "grab",
        }}
      >
        <DragIcon style={{ height: "100%" }} />
      </StyledDragIcon>
      <Text className="document-description" onClick={onClick}>
        <Title>{listItem.name}</Title>
        {placeholder ? (
          <Details>{placeholder}</Details>
        ) : (
          <Details>
            <>
              {label}
              {category && (
                <Tag
                  variant="custom"
                  backgroundColor={
                    getSoftenedColor(category.color) ?? theme.color.blue[200]
                  }
                  color={
                    getDarkenedColor(category.color) ?? theme.color.blue[600]
                  }
                >
                  {category?.name[locale]}
                </Tag>
              )}
              {tags && (
                <ContentSpan $shouldAddContentBefore={Boolean(category)}>
                  {tags}
                </ContentSpan>
              )}
              {iconContent && (
                <ContentSpan
                  $shouldAddContentBefore={Boolean(category || tags)}
                >
                  {
                    <StyledIconContent>
                      {iconContent.icon}
                      {iconContent.text}
                    </StyledIconContent>
                  }
                </ContentSpan>
              )}
              {authorName && (
                <ContentSpan
                  $shouldAddContentBefore={Boolean(
                    category || tags || iconContent
                  )}
                >
                  {t("common.labels.addedBy")} {authorName}
                </ContentSpan>
              )}
              {listItem.createdAt && (
                <ContentSpan
                  $shouldAddContentBefore={Boolean(
                    category || tags || authorName
                  )}
                >
                  {formattedDate}
                </ContentSpan>
              )}
            </>
          </Details>
        )}
      </Text>
      {buttonPlaceholder && (
        <Placeholder className="document-placeholder">
          {buttonPlaceholder}
        </Placeholder>
      )}
      {buttons && <Buttons className="document-buttons">{buttons}</Buttons>}
    </Wrapper>
  );
};

export default DocumentListItem;
