import { ColumnState } from "ag-grid-community";
import { SearchType, SEARCH_TYPES } from "hooks/useFullTextSearch";

import { ViewItemDTO } from "openapi";

const getTemporarySearchKey = (type: ViewItemDTO.type) => {
  const TEMPORARY_STATE_KEY = "temporary-search";
  return `${type}-${TEMPORARY_STATE_KEY}`;
};

const getTemporaryStateKey = (type: ViewItemDTO.type) => {
  const TEMPORARY_STATE_KEY = "temporary-column-state";
  return `${type}-${TEMPORARY_STATE_KEY}`;
};

const getTemporaryFilterKey = (type: ViewItemDTO.type) => {
  const TEMPORARY_FILTER_KEY = "temporary-filter-model";
  return `${type}-${TEMPORARY_FILTER_KEY}`;
};

const getTemporaryActiveViewKey = (type: ViewItemDTO.type) => {
  const ACTIVE_VIEW_KEY = "active-view";
  return `${type}-${ACTIVE_VIEW_KEY}`;
};

const getTemporarySearchType = (type: ViewItemDTO.type) => {
  const ACTIVE_VIEW_KEY = "search-type";
  return `${type}-${ACTIVE_VIEW_KEY}`;
};

export const clearTemporaryState = (type: ViewItemDTO.type) => {
  sessionStorage.removeItem(getTemporaryStateKey(type));
  sessionStorage.removeItem(getTemporaryFilterKey(type));
  sessionStorage.removeItem(getTemporaryActiveViewKey(type));
  sessionStorage.removeItem(getTemporarySearchKey(type));
  sessionStorage.removeItem(getTemporarySearchType(type));
};

export const clearTemporaryStates = () => {
  for (const type of Object.values(ViewItemDTO.type)) {
    clearTemporaryState(type);
  }
};

export const persistTemporaryState = (
  type: ViewItemDTO.type,
  columnState: ColumnState[] | null
) => {
  if (!columnState) {
    sessionStorage.removeItem(getTemporaryStateKey(type));
    return;
  }
  sessionStorage.setItem(
    getTemporaryStateKey(type),
    JSON.stringify(columnState)
  );
};

export const retrieveTemporaryState = (
  type: ViewItemDTO.type
): ColumnState[] | null => {
  const state = sessionStorage.getItem(getTemporaryStateKey(type));
  if (!state) {
    return null;
  }
  return JSON.parse(state) as ColumnState[];
};

export const persistTemporaryFilter = (
  type: ViewItemDTO.type,
  columnState: Record<string, unknown> | null
) => {
  if (!columnState) {
    sessionStorage.removeItem(getTemporaryFilterKey(type));
    return;
  }
  sessionStorage.setItem(
    getTemporaryFilterKey(type),
    JSON.stringify(columnState)
  );
};

export const retrieveTemporaryFilter = (
  type: ViewItemDTO.type
): Record<string, unknown> => {
  const state = sessionStorage.getItem(getTemporaryFilterKey(type));
  if (!state) {
    return {};
  }
  return JSON.parse(state) as Record<string, unknown>;
};

export const persistActiveView = (
  type: ViewItemDTO.type,
  id: string | null
) => {
  if (!id) {
    sessionStorage.removeItem(getTemporaryActiveViewKey(type));
    return;
  }
  sessionStorage.setItem(getTemporaryActiveViewKey(type), id);
};

export const retrieveActiveView = (type: ViewItemDTO.type): string | null => {
  const state = sessionStorage.getItem(getTemporaryActiveViewKey(type));
  if (!state) {
    return null;
  }
  return state;
};

const emitSessionStorageChangedEvent = (type = "sessionStorageChanged") => {
  const event = new Event(type);
  window.dispatchEvent(event);
};

export const retrieveTemporarySearch = (type: ViewItemDTO.type) => {
  const state = sessionStorage.getItem(getTemporarySearchKey(type));
  if (!state) {
    return "";
  }
  return state;
};

export const persistTemporarySearch = (
  type: ViewItemDTO.type,
  search?: string
) => {
  if (!search) {
    sessionStorage.removeItem(getTemporarySearchKey(type));
  } else {
    sessionStorage.setItem(getTemporarySearchKey(type), search);
  }

  emitSessionStorageChangedEvent();
};

export const persistTemporarySearchType = (
  type: ViewItemDTO.type,
  searchType?: SearchType
) => {
  if (!searchType) {
    sessionStorage.removeItem(getTemporarySearchType(type));
  } else {
    sessionStorage.setItem(getTemporarySearchType(type), searchType);
  }

  emitSessionStorageChangedEvent();
};

export const retrieveTemporarySearchType = (
  type: ViewItemDTO.type
): SearchType => {
  const state = sessionStorage.getItem(getTemporarySearchType(type));
  if (!state) {
    return SEARCH_TYPES.LIST;
  }

  return state as SearchType;
};
