import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import localeDE from "@react-pdf-viewer/locales/lib/de_DE.json";
import localeEN from "@react-pdf-viewer/locales/lib/en_US.json";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import { useLocale } from "hooks/GlobalStateHooks";
import { Language } from "shared/i18n/i18n";
import { getToolbarRenderFunction } from "./internal/Toolbar";
import { PageRenderer } from "./internal/PageRenderer";
import type { Block } from "aws-sdk/clients/textract";

import workerURL from "pdfjs-dist/build/pdf.worker.min?url";
import { ocrSearchPlugin } from "./internal/search/OCRSearchPlugin";
import { Search } from "./internal/search/types";
import { CircularProgress } from "@mui/material";
import { usePDFViewerActionsState } from "./PDFViewerActionContext";
import RenderError from "./internal/renderError/RenderError";
import { useFullTextSearch } from "hooks/useFullTextSearch";

type PDFViewerProps = {
  fileURL: string;
  fileName?: string;
  textractBlocks?: Block[];
  handleSelection?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const PDFViewer: FC<PDFViewerProps> = ({
  fileURL,
  fileName,
  textractBlocks,
  handleSelection,
}) => {
  const { locale } = useLocale();
  const {
    handleFullTextSearchQueryChange,
    isFullTextSearch,
    fullTextSearchQuery,
  } = useFullTextSearch();
  const [showOCR, setShowOCR] = useState(isFullTextSearch);
  const [searchRegex, setSearchRegex] = useState<RegExp | undefined>(
    new RegExp(fullTextSearchQuery)
  );
  const [rotationDelta, setRotationDelta] = useState<number>(0);

  const { jumpToOffset } = usePDFViewerActionsState();

  const onSearchChanged = useCallback((search: Search | undefined) => {
    setSearchRegex(search && search.regex);
    handleFullTextSearchQueryChange(search?.search ?? "");
  }, []);

  const blocks = useMemo(() => textractBlocks ?? [], [textractBlocks]);

  const ocrSearchPluginInstance = ocrSearchPlugin({
    blocks: blocks,
  });

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { OCRSearchPopover, OCRLayer } = ocrSearchPluginInstance;

  const toolbar = useMemo(() => {
    return getToolbarRenderFunction(
      fileURL,
      showOCR,
      !!blocks?.length,
      () => {
        setShowOCR(!showOCR);
      },
      (props) => (
        <OCRSearchPopover {...props} onSearchChanged={onSearchChanged} />
      )
    );
  }, [showOCR, blocks]);

  const layoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar: toolbar,
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator: (file) => fileName ?? file.name,
      },
    },
  });

  useEffect(() => {
    if (jumpToOffset) {
      if (!showOCR) {
        setShowOCR(true);
        setTimeout(() => {
          ocrSearchPluginInstance.jumpTo(jumpToOffset);
        }, 1000);
      } else {
        ocrSearchPluginInstance.jumpTo(jumpToOffset);
      }
    }
  }, [jumpToOffset]);

  if (!fileURL) {
    return null;
  }

  return (
    <Worker workerUrl={workerURL}>
      <Viewer
        fileUrl={fileURL}
        localization={locale === Language.de ? localeDE : localeEN}
        plugins={[layoutPluginInstance, ocrSearchPluginInstance]}
        onRotate={(e) => {
          setRotationDelta(e.rotation);
        }}
        renderPage={(props) => (
          <PageRenderer
            renderPageProps={props}
            showOCR={showOCR}
            blocks={blocks}
            searchRegex={searchRegex}
            rotationDelta={rotationDelta}
            handleSelection={handleSelection}
            OCRLayer={OCRLayer}
          />
        )}
        renderLoader={() => <CircularProgress sx={{ margin: "auto" }} />}
        renderError={RenderError}
      />
    </Worker>
  );
};
