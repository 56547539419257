import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import {
  isTotalUploadSizeValid,
  getSizeLimit,
  totalSizeInMegaBytes,
} from "components/ContractUpload/helpers";
import {
  Dropzone,
  FolderIcon,
  Description,
  Caption,
  Divider,
  Button,
  Footer,
} from "./styles";
import { UploadSingleFileProps } from ".";
import { useAddDocumentMutation } from "shared/api/documents";
import { useTeam } from "../../contexts/team/hooks";

const SingleFileUpload = ({
  contractId,
  showSelectTemplateButton,
  showImportFromTemplateModal,
  setIsLoading,
  contractTeamId,
}: UploadSingleFileProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: addDocument } = useAddDocumentMutation();
  const { organizationId } = useTeam();
  const handleUpload = async (file: File) => {
    if (!contractId) {
      enqueueSnackbar("This is not an existing contract!", {
        variant: "error",
      });
      return;
    }

    const isValidSize = isTotalUploadSizeValid([file]);

    if (!isValidSize) {
      enqueueSnackbar(
        t("contractUpload.validation.rejectedBySize", {
          maxSize: getSizeLimit(),
          fileSize: totalSizeInMegaBytes([file]).toFixed(1),
        }),
        {
          variant: "error",
        }
      );
      return;
    }

    try {
      setIsLoading(true);
      if (contractTeamId) {
        await addDocument({
          organizationId: organizationId,
          file: file,
          contractId: contractId,
          teamId: contractTeamId,
        });
        enqueueSnackbar(t("contractUpload.successfulUpload.subtitle"), {
          variant: "success",
        });
      }
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar(t("contractUpload.failedUpload"), {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const handleMultipleFilesDropped = (fileRejections: FileRejection[]) => {
    if (
      fileRejections.every(({ errors }) => {
        return errors.every(({ code }) => code === ErrorCode.TooManyFiles);
      })
    ) {
      enqueueSnackbar(
        t("contractUpload.validation.maxContractPerUpload", {
          maxUploadLimit: 1,
        }),
        { variant: "warning" }
      );
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        handleMultipleFilesDropped(fileRejections);
        return;
      }
      const file = acceptedFiles[0];
      void handleUpload(file);
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "application/pdf": [".pdf"] },
  });

  return (
    <>
      <Dropzone {...getRootProps()} isFullHeight={!showSelectTemplateButton}>
        <input {...getInputProps()} data-cy="dropzone" />
        <FolderIcon />
        <Description>{t("contractUpload.dropZoneText")}</Description>
        <Caption>{t("contractUpload.dropZoneCaption")}</Caption>

        <Button id="uploadButton" variant="outlined">
          {t("contractUpload.buttons.selectContract")}
        </Button>
      </Dropzone>

      {showSelectTemplateButton && (
        <Footer>
          <Divider>{t("common.labels.or")}</Divider>

          <Button
            onClick={showImportFromTemplateModal}
            id="selectTemplateButton"
            variant="outlined"
          >
            {t("contractUpload.buttons.selectTemplate")}
          </Button>
        </Footer>
      )}
    </>
  );
};

export default SingleFileUpload;
