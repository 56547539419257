import styled from "@emotion/styled";
import { theme } from "theme";

export const Menu = styled.div`
  border: 1px solid ${theme.color.red[600]};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`;

export const tooltipStyles = {
  tooltip: {
    fontWeight: `${theme.font.weight.semiBold}`,
    backgroundColor: "#364FA6",
    color: "#F9F6EE",
    fontSize: `${theme.font.size.body}`,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
    borderRadius: `${theme.labelBorderRadius}`,
    maxWidth: "313px",
    maxHeight: "auto",
  },
};

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`;

export const TooltipRow = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
`;

export const RowIcon = styled.div`
  min-width: 28px;
`;

export const RowTitle = styled.span`
  font-weight: ${theme.font.weight.semiBold};
  margin-right: var(--spacing-xxs);
`;

export const RowDescription = styled.div`
  font-weight: ${theme.font.weight.normal};
  display: block;
  line-height: 1.5;
  margin-top: -0.25em;
  margin-bottom: -0.25em;
`;
