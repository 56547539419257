/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ViewDTO } from "../models/ViewDTO";
import type { ViewInputDTO } from "../models/ViewInputDTO";
import type { ViewUpdateDTO } from "../models/ViewUpdateDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ContractViewService {
  /**
   * Saves a new  contract grid view
   * @param teamId
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static create(
    teamId: string,
    requestBody: ViewInputDTO
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contract-view",
      query: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Returns all views saved by the user or shared by other team members
   * @param teamId
   * @returns ViewDTO
   * @throws ApiError
   */
  public static getAll(teamId: string): CancelablePromise<ViewDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/contract-view",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * @deprecated
   * Updates a contract grid view
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static updateDeprecated(
    id: string,
    requestBody: ViewInputDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/contract-view/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Updates a contract grid view
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static update(
    id: string,
    requestBody: ViewUpdateDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/contract-view/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Deletes a contract view
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static delete(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/contract-view/{id}",
      path: {
        id: id,
      },
    });
  }
}
