/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FormulaFieldData } from "./FormulaFieldData";
import type { I18nDto } from "./I18nDto";
import type { ListFieldData } from "./ListFieldData";
import type { NumberFieldConfiguration } from "./NumberFieldConfiguration";

export type ContractFieldCreateDTOV1 = {
  oldStandardField: boolean;
  prompt?: Record<string, any> | null;
  type: ContractFieldCreateDTOV1.type;
  name: I18nDto;
  data?: FormulaFieldData | ListFieldData | null;
  configuration?: NumberFieldConfiguration | null;
  visibleId: string | null;
};

export namespace ContractFieldCreateDTOV1 {
  export enum type {
    TEXTFIELD = "TEXTFIELD",
    TEXTAREA = "TEXTAREA",
    NUMBER = "NUMBER",
    DATE = "DATE",
    LIST = "LIST",
    AMOUNT = "AMOUNT",
    LINK = "LINK",
    COUNTRY = "COUNTRY",
    FORMULA = "FORMULA",
    DURATION = "DURATION",
    CONTACT = "CONTACT",
  }
}
