import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NewModal, CardWrapper, SectionHeader } from "components";
import { TeamDTO, TeamInfoDto } from "openapi";
import { routePaths } from "constants/";
import DeleteModalContent from "./DeleteModalContent";

const DeleteSection = ({
  currentEntity,
}: {
  currentEntity?: TeamDTO | TeamInfoDto;
  isOrganization?: boolean;
}) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isOrganization = !(currentEntity as TeamDTO)?.parentTeam;

  return (
    <>
      <CardWrapper>
        <SectionHeader
          title={
            isOrganization
              ? t("pages.settings.organization.overview.sections.delete.title")
              : t("pages.settings.team.overview.sections.delete.title")
          }
          baseButtonText={t("common.buttons.delete")}
          onButtonClick={() => setShowDeleteModal(true)}
          noPadding
          buttonVariant="secondary"
          buttonId="entity-delete-button"
          buttonColor="danger"
        />
        <p>
          {isOrganization
            ? t(
                "pages.settings.organization.overview.sections.delete.description"
              )
            : t("pages.settings.team.overview.sections.delete.description")}
        </p>
      </CardWrapper>

      <NewModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        title={
          isOrganization
            ? t(
                "pages.settings.organization.overview.sections.delete.modal.title"
              )
            : t("pages.settings.team.overview.sections.delete.modal.title")
        }
        body={
          <DeleteModalContent
            handleClose={() => setShowDeleteModal(false)}
            entity={currentEntity}
            isOrganization={isOrganization}
            redirectTo={routePaths.SETTINGS_TEAMS}
          />
        }
        maxWidth="sm"
      />
    </>
  );
};

export default DeleteSection;
