import { ColumnState } from "ag-grid-community";
import _ from "lodash";
import { ViewDTO, ViewItemDTO } from "openapi";
import { AGGrid, OverviewState } from "./types";

export const synchronizeGrid = (agGrid: AGGrid) => {
  if (!agGrid.initialized) {
    return;
  }
  const gridApi = agGrid.gridRef.current?.api;
  if (!gridApi) {
    return;
  }

  if (agGrid.columnState.length > 0 && agGrid.initialColumnState.length > 0) {
    gridApi.applyColumnState({
      state: agGrid.columnState,
      applyOrder: true,
    });
  } else {
    gridApi.resetColumnState();
  }
  gridApi.setFilterModel(agGrid.filterModel);
};

export const areUnsavedFilterChangesPresent = (
  filterModel: Record<string, unknown>,
  activeView: ViewItemDTO | undefined
) => {
  if (activeView) {
    const parsedViewFilterModel = activeView.filter
      ? (JSON.parse(activeView.filter) as Record<string, unknown>)
      : {};

    if (!_.isEqual(filterModel, parsedViewFilterModel)) {
      return true;
    }
  } else {
    if (Object.keys(filterModel).length > 0) {
      return true;
    }
  }
  return false;
};

export const areUnsavedColumnChangesPresent = (
  prevState: OverviewState,
  columnState: ColumnState[],
  activeView: ViewItemDTO | undefined
) => {
  if (activeView) {
    const parsedViewState = JSON.parse(activeView.data) as ColumnState[];

    if (
      !_.isEqual(
        columnState.filter((col) => !col.hide),
        parsedViewState.filter((col) => !col.hide)
      )
    ) {
      return true;
    }
  } else {
    if (
      prevState.agGrid.initialColumnState &&
      columnState.length > 0 &&
      !_.isEqual(
        columnState.filter((col) => !col.hide),
        prevState.agGrid.initialColumnState.filter((col) => !col.hide)
      )
    ) {
      return true;
    }
  }
  return false;
};

export const getActiveViewDeterminerFunction = (views: ViewDTO) => {
  return (viewId?: string | null): ViewItemDTO | undefined => {
    if (!viewId) {
      return undefined;
    }
    return views.items.find((item) => item.id === viewId);
  };
};
