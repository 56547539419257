import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { isEqual } from "lodash";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ApiError,
  ContractTemplateCreateV1DTO,
  ContractTemplateUpdateV1DTO,
  ContractTemplateTagDTO,
} from "openapi";
import {
  useContractTemplateQueryV1,
  useContractTemplatesQueryV1,
  useContractTemplateCreateMutationV1,
  useContractTemplateUpdateMutationV1,
} from "shared/api/contract-templates";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { useTeam } from "contexts/team/hooks";
import CircularLoading from "components/CircularLoading/CircularLoading";
import routePaths from "constants/routePaths";
import {
  getDefaultCategory,
  categoriesTeamSelector,
} from "shared/api/organization/categories.helpers";
import { Header, DeleteTemplate } from "./components";
import { ContractTemplateContainer, Wrapper } from "./styles";
import { useTemplate } from "./context";
import TextEditor from "new-components/TextEditor/TextEditor";
import { LexicalEditor } from "lexical";
import { importHTML, handleFileImport } from "new-components/TextEditor/utils";
import { convertDocxToHTML } from "utils/mammoth";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";

export type FormValues =
  | ContractTemplateCreateV1DTO
  | ContractTemplateUpdateV1DTO;

enum TemplateMode {
  CREATE,
  EDIT,
}

const validationSchema = () =>
  Yup.object({
    name: Yup.string().required(),
    content: Yup.string().required(),
    categoryId: Yup.string().required(),
    tagIds: Yup.array().of(Yup.string()),
  });

const autoSaveInterval = 3500;

export const ContractTemplate = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
  const { organizationId, selectedTeamId } = useTeam();
  const [isDirty, setIsDirty] = useState(false);
  const [isFileImported, setIsFileImported] = useState(false);

  const { importedFile, setImportedFile } = useTemplate();
  const { refetch: refetchContractTemplates } =
    useContractTemplatesQueryV1(organizationId);
  const { data } = useContractTemplateQueryV1({
    organizationId,
    templateId: id,
  });
  const editorRef = useRef<LexicalEditor>(null);
  const createTemplate = useContractTemplateCreateMutationV1();
  const updateTemplate = useContractTemplateUpdateMutationV1();
  const { data: categories } = useOrganizationCategoriesQuery(
    organizationId,
    (categories) =>
      categories.filter((category) =>
        categoriesTeamSelector(category, selectedTeamId)
      )
  );
  const defaultCategory = getDefaultCategory(categories, selectedTeamId);
  const currentTemplateTags = data?.tags.map((tag) => tag.id);
  const mode = id === "new" ? TemplateMode.CREATE : TemplateMode.EDIT;

  const defaultTemplateName = t(
    "pages.settings.organization.contractTemplates.fields.name.defaultValue"
  );

  const methods = useForm<FormValues>({
    defaultValues: {
      name: defaultTemplateName,
      content: "",
      categoryId: defaultCategory?.id,
      tagIds: [],
    },
    resolver: yupResolver(validationSchema()),
  });

  useEffect(() => {
    if (data) {
      methods.reset({
        name: data.name,
        content: "",
        categoryId: data.categoryId ?? defaultCategory?.id,
        tagIds: currentTemplateTags,
      });
    }
  }, [id, data, mode]);

  const initialValues = methods.formState.defaultValues;
  const currentValues = methods.watch();

  useEffect(() => {
    const hasDirtyFields = !isEqual(initialValues, currentValues);
    setIsDirty(hasDirtyFields);
  }, [initialValues, currentValues]);

  const setEditorContentFromParentComponent = async () => {
    if (importedFile && editorRef.current) {
      const arrayBuffer = await new Response(importedFile).arrayBuffer();

      const conversionResult = await convertDocxToHTML(arrayBuffer);

      importHTML(conversionResult, editorRef.current);
    }
  };

  useEffect(() => {
    if (isFileImported) void onUpdate();
  }, [isFileImported]);

  useEffect(() => void setEditorContentFromParentComponent(), []);

  const categoryIdFieldValue = useWatch({
    name: "categoryId",
    control: methods.control,
  });

  const handleOnGoBack = () => {
    setImportedFile(null);

    if (isDirty) {
      void onUpdate();
    }

    navigate(routePaths.SETTINGS_CONTRACT_TEMPLATES);
  };

  const onUpdate = async () => {
    try {
      if (!data) return;

      const content = editorRef.current?._editorState.toJSON().root.children
        .length
        ? JSON.stringify(editorRef.current?._editorState.toJSON())
        : "";

      const template = {
        ...(methods.getValues() as ContractTemplateUpdateV1DTO),
        content,
      } as ContractTemplateUpdateV1DTO;

      await updateTemplate.mutateAsync({
        organizationId,
        templateId: data?.id,
        template,
      });
    } catch (error) {
      enqueueSnackbar(
        t("pages.settings.tabs.contractTemplates.snackbars.updateFailure"),
        { variant: "error" }
      );
      if (error instanceof ApiError && error.status === 400) {
        methods.setError("name", {
          message:
            "pages.settings.tabs.contractTemplates.errors.nameAlreadyInUse",
        });
      }
      throw error;
    }
  };

  const onCreate = async ({ isDuplicate }: { isDuplicate?: boolean }) => {
    const prefix = t(
      "pages.settings.organization.contractTemplates.fields.name.duplicatePrefix"
    );

    const content = editorRef.current?._editorState.toJSON().root.children
      .length
      ? JSON.stringify(editorRef.current?._editorState.toJSON())
      : "";

    const template = (
      isDuplicate
        ? {
            ...methods.getValues(),
            content: content,
            categoryId: methods.getValues().categoryId ?? defaultCategory?.id,
            name: `${prefix}${methods.getValues().name as string}`,
          }
        : {
            ...methods.getValues(),
            content: content,
            categoryId: methods.getValues().categoryId ?? defaultCategory?.id,
          }
    ) as ContractTemplateCreateV1DTO;
    const successMessage = isDuplicate
      ? t("pages.settings.tabs.contractTemplates.snackbars.duplicateSuccess", {
          name: template.name,
        })
      : t("pages.settings.tabs.contractTemplates.snackbars.createSuccess");
    const failureMessage = isDuplicate
      ? t("pages.settings.tabs.contractTemplates.snackbars.duplicateFailure", {
          name: template.name,
        })
      : t("pages.settings.tabs.contractTemplates.snackbars.createFailure");

    try {
      const newTemplate = await createTemplate.mutateAsync({
        organizationId,
        template,
      });

      enqueueSnackbar(successMessage, { variant: "success" });
      void refetchContractTemplates();

      navigate(`${routePaths.SETTINGS_CONTRACT_TEMPLATES}/${newTemplate.id}`);
    } catch (error) {
      enqueueSnackbar(failureMessage, { variant: "error" });
      if (error instanceof ApiError && error.status === 400) {
        methods.setError("name", {
          message:
            "pages.settings.tabs.contractTemplates.errors.nameAlreadyInUse",
        });
      }
      throw error;
    }
  };

  const importFile = async () => {
    if (mode === TemplateMode.CREATE && importedFile) {
      try {
        const state = await handleFileImport(importedFile);

        if (!state) return;

        methods.setValue("content", state);

        setImportedFile(null);

        void onCreate({ isDuplicate: false });

        if (editorRef.current) {
          editorRef.current.setEditorState(
            editorRef.current.parseEditorState(state)
          );
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  useEffect(() => {
    if (mode === TemplateMode.CREATE && importedFile) void importFile();
  }, [importedFile]);

  useEffect(() => {
    if (!isDirty) return;
    const interval = setInterval(() => void onUpdate(), autoSaveInterval);
    return () => clearInterval(interval);
  }, [isDirty]);

  if (mode === TemplateMode.EDIT && !data) return <CircularLoading isLoading />;

  const onPickFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !editorRef.current) return;

    if (file) {
      if (file.type === "text/html") {
        const reader = new FileReader();
        reader.onload = (readEvent) => {
          if (readEvent.target?.result && editorRef.current) {
            importHTML(
              readEvent.target.result as unknown as string,
              editorRef.current
            );
          }
        };
        reader.readAsText(file);
      } else {
        if (!editorRef.current) return;
        const arrayBuffer = await new Response(file).arrayBuffer();
        const conversionResult = await convertDocxToHTML(arrayBuffer);
        importHTML(conversionResult, editorRef.current);
      }
      setIsFileImported(true);
    }
  };

  const handleCategoryChange = (categoryId: string) => {
    methods.setValue("categoryId", categoryId);
  };

  const handleTagsChange = (tags: { key: string; value: string }[]) => {
    const currentTagIds = tags.map((tag) => tag.key);
    methods.setValue("tagIds", currentTagIds);
  };

  const getCurrentCategoryId = () => {
    if (mode === TemplateMode.CREATE && !isDirty) {
      return defaultCategory?.id;
    } else if (isDirty && categoryIdFieldValue) {
      return categoryIdFieldValue;
    } else {
      return data?.categoryId;
    }
  };
  const currentCategoryId = getCurrentCategoryId();

  const currentCategory = categories?.find(
    (cat) => cat.id === currentCategoryId
  );

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <form>
          <Header
            control={methods.control}
            handleOnGoBack={handleOnGoBack}
            handleOnImport={onPickFile}
            handleOnDelete={() => setShowDeleteTemplateModal(true)}
            handleTemplateDuplication={() =>
              void onCreate({ isDuplicate: true })
            }
            currentCategory={currentCategory}
            handleCategoryChange={handleCategoryChange}
            categories={categories}
            preselectedTags={data?.tags as ContractTemplateTagDTO[]}
            handleTagsChange={handleTagsChange}
            lastModified={data?.updatedAt ?? data?.createdAt}
            isSaving={updateTemplate.isLoading}
          />

          <ContractTemplateContainer>
            <TextEditor
              currentCategory={currentCategory}
              compact
              showSidebar
              initialState={(editor) => {
                if (data?.content) {
                  try {
                    editor.setEditorState(
                      editor.parseEditorState(data.content)
                    );
                  } catch (e) {
                    importHTML(data.content, editor);
                  }
                }
              }}
            >
              <EditorRefPlugin editorRef={editorRef} />
              <OnChangePlugin
                onChange={(editorState) => {
                  editorState.read(() => {
                    const content = JSON.stringify(editorState);
                    if (methods.getValues().content === "") {
                      methods.reset({
                        ...methods.getValues(),
                        content,
                      });
                      return;
                    }
                    methods.setValue("content", content);
                  });
                }}
              />
            </TextEditor>
          </ContractTemplateContainer>
        </form>
      </FormProvider>

      <DeleteTemplate
        open={showDeleteTemplateModal}
        onClose={() => setShowDeleteTemplateModal(false)}
        currentCategory={currentCategory}
        data={data}
      />
    </Wrapper>
  );
};
