import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  color: var(--text-secondary);

  svg {
    width: 24px;
    stroke: var(--text-secondary);
  }

  svg path {
    stroke: var(--text-secondary);
  }
`;
