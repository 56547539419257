import { SelectChangeEvent } from "@mui/material";
import {
  persistTemporarySearch,
  persistTemporarySearchType,
  retrieveTemporarySearch,
  retrieveTemporarySearchType,
} from "contexts/grid/storage";
import { ViewItemDTO } from "openapi";
import { useEffect, useState } from "react";

export const SEARCH_TYPES = {
  LIST: "list",
  TEXT: "text",
} as const;

export type SearchType = (typeof SEARCH_TYPES)[keyof typeof SEARCH_TYPES];

export type SelectSearchTypeChangedEvent = SelectChangeEvent<SearchType>;

export function useFullTextSearch() {
  const [searchType, setSearchType] = useState(
    retrieveTemporarySearchType(ViewItemDTO.type.CONTRACT)
  );

  const [fullTextSearchQuery, setFullTextSearchQuery] = useState(
    retrieveTemporarySearch(ViewItemDTO.type.CONTRACT)
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setFullTextSearchQuery(
        retrieveTemporarySearch(ViewItemDTO.type.CONTRACT)
      );
      setSearchType(retrieveTemporarySearchType(ViewItemDTO.type.CONTRACT));
    };

    const event = "sessionStorageChanged";

    window.addEventListener(event, handleStorageChange);

    return () => {
      window.removeEventListener(event, handleStorageChange);
    };
  }, []);

  const handleSearchTypeChange = (event: SelectSearchTypeChangedEvent) => {
    persistTemporarySearchType(
      ViewItemDTO.type.CONTRACT,
      event.target.value as SearchType
    );
  };

  const handleFullTextSearchQueryChange = (searchTermText: string) => {
    persistTemporarySearch(ViewItemDTO.type.CONTRACT, searchTermText);
  };

  return {
    fullTextSearchQuery,
    handleFullTextSearchQueryChange,
    handleSearchTypeChange,
    isFullTextSearch:
      searchType === SEARCH_TYPES.TEXT && fullTextSearchQuery !== "",
    searchType,
  };
}
