import { AgGridReact } from "ag-grid-react";
import { TFunction } from "i18next";
import {
  AmountFieldTypeDtoV1,
  ContactFieldTypeDTOV1,
  ContractDTOV1,
  ContractFieldDTOV1,
  CountryFieldTypeDtoV1,
  DateFieldTypeDtoV1,
  DurationFieldTypeDtoV1,
  FormulaFieldData,
  FormulaFieldTypeDtoV1,
  LinkFieldTypeDtoV1,
  ListFieldTypeDtoV1,
  MultiLineTextFieldTypeDtoV1,
  NumberFieldTypeDtoV1,
  SingleLineTextFieldTypeDtoV1,
  TeamDescriptionDto,
  NumberFieldConfiguration,
  CategoryDTO,
  ContractUpdateDto,
  ContractInputDTOV1,
} from "../../openapi";
import dayjs from "dayjs";
import {
  CellClassFunc,
  Column,
  EditableCallback,
  ICellRendererParams,
  ToolPanelVisibleChangedEvent,
  ValueFormatterParams,
  IRowNode,
} from "ag-grid-community";
import {
  ContractDtoWithResolvedParent,
  dateFormatter,
  paymentFormatter,
} from "../../constants/utils";
import { Language } from "../../shared/i18n/i18n";
import {
  evaluateForumla,
  FormulaAmountResult,
  FormulaResult,
} from "components/Datapoints/FormulaDatapoint";
import { evaluateListItem } from "components/Datapoints/ListDatapoint";
import { FormSelectItem } from "../../components/FormItems/FormSelect/FormSelect";
import { getMatchingField } from "utils/helpers";
import React, { MutableRefObject } from "react";
import { getCountriesOptions } from "utils/internationalizations";
import { formatContactName } from "pages/Contact/helpers";
import { ContactDataDTO } from "pages/Contacts/ContactDataDTO";
import { scaleLocaleDecimalNumber } from "utils/helpers";
import { isNumber } from "mathjs";

const notEditableStatuses = [
  ContractDTOV1.status.SIGNING,
  ContractDTOV1.status.PROCESSING,
];

export const exportView = (
  gridRef: React.MutableRefObject<AgGridReact | undefined>,
  viewName: string,
  t: TFunction<"translation">,
  locale: Language,
  categoriesData?: CategoryDTO[],
  fieldsData?: ContractFieldDTOV1[]
) => {
  const fieldsMap = fieldsData?.reduce((acc, field) => {
    acc.set(field.id, field);
    return acc;
  }, new Map<string, ContractFieldDTOV1>());

  const displayedColumns = gridRef.current?.api.getAllDisplayedColumns();
  const exportedColumns = [];
  if (displayedColumns) {
    for (const displayedColumn of displayedColumns) {
      const [, fieldId] = displayedColumn.getColDef().field?.split(".") ?? [];
      const field = fieldsMap?.get(fieldId);

      if (field?.type === ContractFieldDTOV1.type.CONTACT) {
        exportedColumns.push(`${displayedColumn.getColId()}-name`);
      }

      exportedColumns.push(displayedColumn.getColId());
    }
  }

  gridRef.current?.api.exportDataAsExcel({
    sheetName: viewName,
    columnKeys: exportedColumns,
    fileName: `ContractHero_export-${dayjs().format("YYYY-MM-DD")}.xlsx`,
    processCellCallback: (params) => {
      const colDef = params.column.getColDef();
      const contract = params.node?.data as ContractDTOV1 | undefined;

      if (params.column.getColId() === "type") {
        if (!contract?.type) {
          return "";
        }
        return t(`contractType.${contract?.type}`);
      }

      if (params.column.getColId() === "categoryId") {
        return (
          categoriesData?.find(
            (category) => category.id === contract?.categoryId
          )?.name[locale] ?? ""
        );
      }

      if (params.column.getColId() === "tag") {
        return contract?.tags.map((tag) => tag.name).join(",") ?? "";
      }

      const [, fieldId] = colDef.field?.split(".") ?? [];
      const field = fieldsMap?.get(fieldId);

      if (field && field.type === ContractFieldDTOV1.type.NUMBER) {
        if (!params.value) {
          return "";
        }
        return scaleDecimalValue(
          params.value as number,
          field.configuration as NumberFieldConfiguration
        ) as unknown as string;
      }

      // if the field is one of the following types, we need to return the raw value as the formatting is handled by XLSX
      if (
        field &&
        (field.type === ContractFieldDTOV1.type.AMOUNT ||
          field.type === ContractFieldDTOV1.type.DATE ||
          field.type === ContractFieldDTOV1.type.DURATION ||
          (field.type === ContractFieldDTOV1.type.FORMULA &&
            fieldsData &&
            contract))
      ) {
        if (
          field.type === ContractFieldDTOV1.type.FORMULA &&
          fieldsData &&
          contract
        ) {
          const formulaResult = evaluateForumla({
            name: "",
            data: (field.data as FormulaFieldData).formula,
            field: field,
            fields: fieldsData,
            contractData: contract.fields,
          });
          return !isNaN(formulaResult.value)
            ? formulaResult.value.toString()
            : "";
        }

        return params.value as string;
      }

      // try to reuse valueFormatter from the colDef for values that are not handled by the excel exporter
      if (colDef.valueFormatter && typeof colDef.valueFormatter !== "string") {
        const valueFormatterParam: ValueFormatterParams = {
          ...params,
          data: params.node?.data as ContractDTOV1,
          node: params.node ?? null,
          colDef: params.column.getColDef(),
        };
        let val = colDef.valueFormatter(valueFormatterParam);
        if (val === "–") {
          val = "";
        }

        return val;
      }

      return params.value as string;
    },
  });
};

export function defaultValueFormatter(params: ValueFormatterParams) {
  if (!params.value) {
    return "-";
  }

  if (typeof params.value === "string") {
    return params.value.replace(/\r?\n|\r/g, " ");
  }

  return (params.value as number).toString();
}

export function defaultEnumFormatter(
  params: ValueFormatterParams,
  prefix: string,
  t: TFunction<"translation">
) {
  return !params.value ? "–" : t(`${prefix}.${params.value as string}`);
}

export function getCategory(
  categories: CategoryDTO[],
  categoryId: string | null
): CategoryDTO | undefined {
  return categories.find((item) => item.id === categoryId);
}

export function getTeam(
  teamById: (teamId?: string | undefined) => TeamDescriptionDto | null,
  teamId: string | null
) {
  return teamById(teamId ?? undefined);
}

export const scaleDecimalValue = (
  value: number,
  configuration: NumberFieldConfiguration
): number => {
  const decimalScale = configuration?.decimalScale || 2;
  return parseFloat(value.toFixed(decimalScale));
};

export type CellValue = {
  value: string | number | Date | CellValue;
  key?: string;
};

export const cellValueFormatter = (
  params: ValueFormatterParams,
  contractField: ContractFieldDTOV1,
  displayNames: Intl.DisplayNames,
  fields: ContractFieldDTOV1[],
  locale: Language,
  t: TFunction<"translation">,
  contacts: ContactDataDTO[],
  userDateFormat: string
): string => {
  const contractData = params.data as ContractDTOV1;
  let fieldData:
    | AmountFieldTypeDtoV1
    | CountryFieldTypeDtoV1
    | DateFieldTypeDtoV1
    | DurationFieldTypeDtoV1
    | LinkFieldTypeDtoV1
    | ListFieldTypeDtoV1
    | MultiLineTextFieldTypeDtoV1
    | NumberFieldTypeDtoV1
    | SingleLineTextFieldTypeDtoV1
    | FormulaFieldTypeDtoV1
    | ContactFieldTypeDTOV1
    | undefined;

  if (params.data) {
    fieldData = contractData?.fields[contractField.id];
  }
  let value;
  const cellValue = params.value as CellValue;
  if (!cellValue) {
    value = (
      contractData.fields[contractField.id] as
        | AmountFieldTypeDtoV1
        | CountryFieldTypeDtoV1
        | DateFieldTypeDtoV1
        | LinkFieldTypeDtoV1
        | ListFieldTypeDtoV1
        | MultiLineTextFieldTypeDtoV1
        | NumberFieldTypeDtoV1
        | SingleLineTextFieldTypeDtoV1
        | FormulaFieldTypeDtoV1
        | ContactFieldTypeDTOV1
    )?.value;
  } else {
    value = cellValue?.value ?? cellValue;
  }
  switch (contractField.type) {
    case ContractFieldDTOV1.type.DATE:
      return dateFormatter(locale, value as string, userDateFormat);
    case ContractFieldDTOV1.type.AMOUNT:
      return paymentFormatter(
        locale,
        value as number,
        (fieldData as AmountFieldTypeDtoV1)?.currency
      );
    case ContractFieldDTOV1.type.NUMBER:
      if (!value) {
        return "-";
      }
      return scaleDecimalValue(
        value as number,
        contractField.configuration as NumberFieldConfiguration
      ) as unknown as string;
    case ContractFieldDTOV1.type.CONTACT: {
      const contactId = value as string;
      const contact = contacts.find((item) => item.id === contactId);
      if (!contact) {
        return "-";
      }
      return formatContactName(contact);
    }
    case ContractFieldDTOV1.type.LIST: {
      if (!value) {
        return "-";
      }
      return (
        evaluateListItem(
          t,
          {
            definition: contractField,
            values: {
              [contractField.id]: {
                items: [],
                value: value as string,
              },
            },
          },
          {
            value: value as string,
            items: [], // TODO: This is unused all will be removed
          }
        ) || ""
      );
    }
    case ContractFieldDTOV1.type.COUNTRY:
      try {
        const countryKey = (cellValue?.key ?? value) as unknown as string;
        return countryKey ? displayNames.of(countryKey) ?? "-" : "-";
      } catch (e) {
        return "-";
      }
    case ContractFieldDTOV1.type.FORMULA: {
      const result = evaluateForumla({
        name: "",
        data: (contractField.data as FormulaFieldData).formula,
        field: contractField,
        fields: fields,
        contractData: contractData.fields,
      });

      if (isNaN(result.value)) {
        return "-";
      }

      switch (result.type) {
        case ContractFieldDTOV1.type.AMOUNT:
          return paymentFormatter(
            locale,
            result.value,
            (result as FormulaAmountResult).currency
          );
        default:
          return result.value.toString();
      }
    }
    default:
      return (value as string)?.toString() ?? "-";
  }
};

export const getPaymentPriceCycle = (
  paymentCycle: ContractUpdateDto.paymentCycle | null
) => {
  switch (paymentCycle) {
    case ContractUpdateDto.paymentCycle.MONTHLY:
      return 1;
    case ContractUpdateDto.paymentCycle.BIMONTHLY:
      return 2;
    case ContractUpdateDto.paymentCycle.QUARTERLY:
      return 3;
    case ContractUpdateDto.paymentCycle.BIANNUAL:
      return 6;
    case ContractUpdateDto.paymentCycle.YEARLY:
      return 12;
    case ContractUpdateDto.paymentCycle.EVERY_2_YEARS:
      return 24;
    case ContractUpdateDto.paymentCycle.EVERY_3_YEARS:
      return 36;
    default:
      return NaN;
  }
};

export const createUpdateBody = (
  fields: ContractFieldDTOV1[],
  field: string,
  newValue: string | number | CategoryDTO,
  data: ContractDTOV1
): ContractInputDTOV1 | undefined => {
  const fieldsArr = field.split(".");
  const key = fieldsArr[0];

  if (fieldsArr.length === 1) {
    return { [key]: newValue };
  }

  //handle custom fields
  if (fieldsArr.length > 1 && key === "fields") {
    return handleCustomFieldUpdate(fields, fieldsArr, newValue, data);
  }
};

const handleCustomFieldUpdate = (
  fields: ContractFieldDTOV1[],
  fieldsArr: string[],
  newValue: string | number | CategoryDTO | FormSelectItem,
  data: ContractDTOV1
): Record<string, unknown> | undefined => {
  const fieldId = fieldsArr[1];

  const fieldDefinition = getMatchingField(fields, fieldId, data.teamId);
  if (!fieldDefinition) {
    return;
  }
  switch (fieldDefinition.type) {
    case ContractFieldDTOV1.type.DURATION:
      return handleDurationFieldUpdate(data, fieldDefinition);
    case ContractFieldDTOV1.type.AMOUNT:
      return handleAmountFieldUpdate(
        fieldDefinition.id,
        newValue as number,
        data
      );
    default:
      return handleGenericFieldUpdate(fieldDefinition.id, newValue as string);
  }
};

const handleDurationFieldUpdate = (
  data: ContractDTOV1,
  fieldDefinition: ContractFieldDTOV1
) => {
  const fieldId = fieldDefinition.id;
  const durationValue = data.fields[fieldId] as DurationFieldTypeDtoV1;
  return { fields: { [fieldId]: durationValue } };
};

const handleAmountFieldUpdate = (
  fieldId: string,
  newValue: number,
  data: ContractDTOV1
) => {
  if (newValue === undefined) {
    return;
  }
  const amountValue = data.fields[fieldId] as AmountFieldTypeDtoV1;

  return {
    fields: {
      [fieldId]: {
        value: Number(newValue),
        currency: amountValue?.currency ?? "EUR",
      },
    },
  };
};

const handleGenericFieldUpdate = (
  fieldId: string,
  newValue: string | number | Date
) => {
  if (newValue === undefined) {
    return;
  }
  return { fields: { [fieldId]: { value: newValue } } };
};

export const getFieldValue = (fieldId: string, contract: ContractDTOV1) => {
  if (!contract) {
    return;
  }

  return contract.fields[fieldId] as
    | AmountFieldTypeDtoV1
    | CountryFieldTypeDtoV1
    | DateFieldTypeDtoV1
    | LinkFieldTypeDtoV1
    | ListFieldTypeDtoV1
    | MultiLineTextFieldTypeDtoV1
    | NumberFieldTypeDtoV1
    | SingleLineTextFieldTypeDtoV1;
};

export const sortToolPanelColumns = (
  event: ToolPanelVisibleChangedEvent,
  gridRef: MutableRefObject<
    AgGridReact<ContractDtoWithResolvedParent> | undefined
  >
) => {
  let contractMetaDataKeys = [
    "id",
    "name",
    "status",
    "team",
    "type",
    "tag",
    "categoryId",
    "status",
    "createdAt",
    "updatedAt",
    "hasFile",
  ];
  const getContractMetaDataColumns = (hiddenColumns: Column[]) => {
    return hiddenColumns.filter((column) =>
      contractMetaDataKeys.includes(column.getColDef().field || "")
    );
  };

  const getRemainingColumns = (columns: Column[]) => {
    return columns
      .filter(
        (column) =>
          !contractMetaDataKeys.includes(column.getColDef().field || "")
      )
      .sort((a, b) => {
        const headerNameA = a?.getColDef()?.headerName || "";
        const headerNameB = b?.getColDef()?.headerName || "";
        return headerNameA.localeCompare(headerNameB);
      });
  };

  if (
    !gridRef?.current ||
    (event.source !== "api" &&
      event.source !== "sideBarButtonClicked" &&
      event.source !== "sideBarInitializing" &&
      event.source !== "columns")
  ) {
    return;
  }

  const { api } = gridRef.current;
  const toolPanel = api.getToolPanelInstance("columns");
  const allColumns = api.getColumns() || [];
  const displayedColumns = api.getAllDisplayedColumns();

  if (!toolPanel) {
    return;
  }
  const hiddenColumns = allColumns.filter(
    (column) => !displayedColumns.includes(column)
  );

  contractMetaDataKeys = contractMetaDataKeys.filter(
    (field) => !displayedColumns.find((item) => item.getColDef() === field)
  );

  const updatedColumns = [
    ...displayedColumns,
    ...getContractMetaDataColumns(hiddenColumns),
    ...getRemainingColumns(hiddenColumns),
  ];

  const columnsState = updatedColumns.map((column) => {
    return column.getColDef();
  });

  toolPanel.setColumnLayout(columnsState);
};

export const getCellClass: (
  field: ContractFieldDTOV1 | null,
  fields: ContractFieldDTOV1[] | undefined
) => CellClassFunc<ContractDTOV1> = (field, fields) => (params) => {
  const classes = [];

  if (params.column.isCellEditable(params.node)) {
    classes.push("editable-cell");
  } else {
    classes.push("not-editable-cell");
  }

  if (!params.data) {
    return [];
  }

  let formulaResult: FormulaResult | null = null;
  if (field?.type === ContractFieldDTOV1.type.FORMULA && fields) {
    formulaResult = evaluateForumla({
      name: "",
      data: (field.data as FormulaFieldData).formula,
      field: field,
      fields: fields,
      contractData: params.data.fields,
    });
  }

  if (!field || !fields) {
    return classes;
  }

  if (!params.value) {
    return classes;
  }

  const matchedField = getMatchingField(fields, field.id, params.data.teamId);
  if (!matchedField) {
    return classes;
  }

  const type =
    (formulaResult?.type as ContractFieldDTOV1.type) ?? matchedField.type;

  switch (type) {
    case ContractFieldDTOV1.type.AMOUNT: {
      const fieldValue =
        (formulaResult as FormulaAmountResult) ??
        (params.data?.fields?.[matchedField.id] as
          | AmountFieldTypeDtoV1
          | undefined);

      if (fieldValue) {
        classes.push(
          "amount-cell",
          `amount-cell-${fieldValue.currency ?? "EUR"}`
        );
      }
      break;
    }

    case ContractFieldDTOV1.type.NUMBER:
      classes.push("number-cell");
      break;
    case ContractFieldDTOV1.type.DATE:
      classes.push("date-cell");
      break;
    case ContractFieldDTOV1.type.DURATION:
      if (params.column.getColId().endsWith("At")) {
        classes.push("date-cell");
      }
      break;
    default:
      break;
  }

  return classes;
};

export const isContractEditable: (
  hasWriteAccess: (categoryId: string, teamId: string) => boolean
) => EditableCallback<ContractDTOV1> =
  (hasWriteAccess) =>
  ({ data }) => {
    if (!data) {
      return false;
    }

    return hasWriteAccess(data.categoryId, data.teamId);
  };

export const orderedColumns = {
  team: -9,
  type: -8,
  partnerCompany: -7,
  tag: -6,
  categoryId: -5,
  durationStartAt: -4,
  durationEndAt: -3,
  paymentCycle: -2,
  paymentPrice: -1,
  unapprovedAnalysisValues: 1,
};
export const isStatusEditable = (
  data: ContractDTOV1,
  hasWriteAccess: (categoryId: string, teamId: string) => boolean
) => {
  if (!data || notEditableStatuses.includes(data.status)) {
    return false;
  }
  return hasWriteAccess(data.categoryId, data.teamId);
};

export const getFilteredStatuses = () => {
  return [...Object.keys(ContractDTOV1.status)].filter(
    (item) => !notEditableStatuses.includes(item as ContractDTOV1.status)
  );
};

export const countryCellRenderer =
  (locale: Language) => (params: ICellRendererParams) => {
    if (!params.value) {
      return null;
    }
    return getCountryNameByKey(
      locale,
      (params.value as FormSelectItem)?.key as string
    );
  };
export const getCountryNameByKey = (locale: Language, key: string) => {
  const countryItems = getCountriesOptions(locale);
  const country = countryItems.find((c) => c.key === key);
  if (!country) {
    return null;
  }
  return country.value;
};

export const sortByTagsCountAndName = (
  _valueA: unknown,
  _valueB: unknown,
  nodeA?: IRowNode<ContractDTOV1>,
  nodeB?: IRowNode<ContractDTOV1>
) => {
  const nodeATags = nodeA?.data?.tags;
  const nodeBTags = nodeB?.data?.tags;

  if (!nodeATags) return -1;
  if (!nodeBTags) return 1;

  const numberOfTagsA = nodeATags.length;
  const numberOfTagsB = nodeBTags.length;

  if (numberOfTagsA !== numberOfTagsB) return numberOfTagsA - numberOfTagsB;

  const nameA = nodeA?.data?.name ? nodeA.data.name.toLowerCase() : "";
  const nameB = nodeB?.data?.name ? nodeB.data.name.toLowerCase() : "";

  if (!nameA || !nameB) return 0;

  return nameA.localeCompare(nameB);
};

export type FormulaAndAmountField = {
  currency?: string;
  value?: number;
};

export const getFormulaColumnSum = (
  rows: IRowNode[],
  fields: ContractFieldDTOV1[],
  customField: ContractFieldDTOV1,
  locale: Language
) => {
  let sum = 0;
  const rowsWithCurrency:
    | FormulaAndAmountField[]
    | (FormulaResult & { currency: string }) = [];
  for (const row of rows || []) {
    const result = evaluateForumla({
      name: "",
      data: (customField.data as FormulaFieldData).formula,
      field: customField,
      fields: fields,
      contractData: (row.data as ContractDTOV1).fields,
    });
    if (!Number.isNaN(result.value)) {
      sum += result.value;
    }

    if ((result as FormulaResult & { currency: string }).currency) {
      rowsWithCurrency.push(result as FormulaResult & { currency: string });
    }
  }
  const differectCurrencies = checkDifferectCurrencies(rowsWithCurrency);

  if (differectCurrencies) {
    return scaleLocaleDecimalNumber(sum, 2, locale);
  }
  return `€ ${scaleLocaleDecimalNumber(sum, 2, locale)}`;
};

export const getAmountColumnSum = (
  rows: IRowNode[],
  fields: ContractFieldDTOV1[],
  customField: ContractFieldDTOV1,
  locale: Language
) => {
  let sum = 0;
  const rowsWithCurrency: FormulaAndAmountField[] = [];

  for (const row of rows || []) {
    if (!row.data) {
      continue;
    }
    const matchedField = getMatchingField(
      fields,
      customField.id,
      (row.data as ContractDTOV1).teamId
    );

    if (!matchedField) {
      continue;
    }
    const data = (row.data as ContractDTOV1).fields[matchedField.id];
    if (!data) {
      continue;
    }

    if ((data as FormulaAndAmountField).currency) {
      rowsWithCurrency.push(data as FormulaAndAmountField);
    }

    sum += (data as ContractDTOV1 & { value: number }).value;
  }

  const differectCurrencies = checkDifferectCurrencies(rowsWithCurrency);

  if (differectCurrencies) {
    return scaleLocaleDecimalNumber(sum, 2, locale);
  }
  return `€ ${scaleLocaleDecimalNumber(sum, 2, locale)}`;
};

export const checkDifferectCurrencies = (rows: FormulaAndAmountField[]) => {
  return rows.some(
    (item: FormulaAndAmountField) => rows[0].currency !== item.currency
  );
};

export const filterComparator = (
  fieldA: string | number,
  fieldB: string | number,
  contacts: ContactDataDTO[],
  fieldType: ContractFieldDTOV1.type
) => {
  let valueA = fieldA;
  let valueB = fieldB;
  switch (fieldType) {
    case ContractFieldDTOV1.type.CONTACT: {
      const contactA = contacts?.find((item) => item.id === fieldA);
      const contactB = contacts?.find((item) => item.id === fieldB);
      valueA = formatContactName(contactA);
      valueB = formatContactName(contactB);
      break;
    }
  }

  if (!valueA) return -1;
  if (!valueB) return 1;

  if (isNumber(valueA) && isNumber(valueB)) {
    return Number(valueA) - Number(valueB);
  }

  return (valueA as string)
    .toLocaleLowerCase()
    .localeCompare((valueB as string).toLocaleLowerCase());
};
