/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ViewItemDTO = {
  id: string;
  type: ViewItemDTO.type;
  name: string;
  data: string;
  filter: string | null;
  shared: boolean;
  default?: boolean;
  owner: Record<string, any>;
};

export namespace ViewItemDTO {
  export enum type {
    CONTRACT = "CONTRACT",
    CONTACT = "CONTACT",
    TASK = "TASK",
  }
  export enum key {
    CONTRACT = "CONTRACT",
    CONTRACT_DETAILS = "CONTRACT_DETAILS",
    CONTACT = "CONTACT",
    TASK = "TASK",
  }
}
