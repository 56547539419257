import React from "react";
import { useTranslation } from "react-i18next";
import { Heading, Subtitle, Wrapper } from "./styles";

export type RequestModalContentType = {
  name: string;
  description: string;
};

const RequestModalContent = ({
  name,
  description,
}: RequestModalContentType) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Subtitle>
        {t("pages.settings.organization.apps.modal.subtitle")}
      </Subtitle>
      <Heading>
        {t("pages.settings.organization.apps.modal.heading", { name })}
      </Heading>
      <div>{description}</div>
      <div>{t("pages.settings.organization.apps.modal.text")}</div>
    </Wrapper>
  );
};

export default RequestModalContent;
