// @TODO: needs to be refactored
import React, { RefObject, useEffect, useRef, useState } from "react";
import { ContractFieldDTOV1 } from "../../../openapi";
import { TextAreaCellEditor, TextCellEditor } from "./style";
import { CellValue } from "../helpers";
import { CustomCellEditorProps } from "ag-grid-react";

export const CustomCellEditorType: Record<ContractFieldDTOV1.type, unknown> = {
  TEXTFIELD: "text",
  TEXTAREA: "textarea",
  NUMBER: "number",
  LIST: "list",
  LINK: "text",
  DATE: "date",
  AMOUNT: "amount",
  COUNTRY: "country",
  DURATION: "text",
  FORMULA: "text",
  CONTACT: "contact",
};

interface Props extends CustomCellEditorProps {
  type?: string;
  isTitle?: boolean;
  isCustomField?: boolean;
  value: CellValue;
}

export const CustomCellEditor = (props: Props) => {
  const { isCustomField, value: cellValue } = props;
  const [value, setValue] = useState<string>(
    (cellValue?.value ?? cellValue) as string
  );

  const refInput = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event: MouseEvent) {
    if (refInput.current && !refInput.current.contains(event.target as Node)) {
      props.api.stopEditing();
    }
  }

  const handleValueChanged = (value: string) => {
    setValue(value);
    const trimmedValue = value?.trim();
    const formatedValue = isCustomField
      ? { value: trimmedValue }
      : trimmedValue;
    props.onValueChange(formatedValue);
  };

  // focus on the input
  useEffect(() => {
    // get ref from React component
    refInput?.current?.focus();
  }, []);

  return (
    <>
      {props.type === "textarea" ? (
        <TextAreaCellEditor
          ref={refInput as RefObject<HTMLTextAreaElement>}
          value={value}
          rows={4}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleValueChanged(event.target.value)
          }
        />
      ) : (
        <TextCellEditor
          isTitle={props.isTitle as boolean}
          ref={refInput as RefObject<HTMLInputElement>}
          value={value}
          type={props.type}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleValueChanged(event.target.value);
          }}
        />
      )}
    </>
  );
};
CustomCellEditor.displayName = "CustomCellEditor";
