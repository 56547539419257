import React, {
  useEffect,
  RefObject,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { NewModal, CTAButton } from "components";
import FilterIcon from "assets/svg/filter-icon.svg?react";
import CrossIcon from "assets/svg/cross-icon.svg?react";
import { TeamOption, CategoryOption } from "./components";
import { CategoryOptionType, TeamOptionType, ViewMode } from "../../Teams";
import {
  FiltersWrapper,
  FiltersCount,
  CategoryList,
  TeamList,
  SectionTitle,
  Section,
  Footer,
} from "./styles";
import { useTranslation } from "react-i18next";
import { SectionDescription } from "../../styles";

type FiltersPropTypes = {
  viewMode: ViewMode;
  gridRef: RefObject<AgGridReact>;
  teams?: TeamOptionType[];
  categories?: CategoryOptionType[];
  onResetGridAxis: () => void;
  resetAllFilters: () => void;
  selectedCategories?: CategoryOptionType[];
  setSelectedCategories: (options: CategoryOptionType[]) => void;
  selectedTeams?: TeamOptionType[];
  setSelectedTeams: (options: TeamOptionType[]) => void;
  setFilteredRowsIds: Dispatch<SetStateAction<Set<string>>>;
  onColumnsFilter: (fieldId: string) => void;
};

const Filters = ({
  gridRef,
  viewMode,
  teams,
  categories,
  selectedCategories = [],
  setSelectedCategories,
  selectedTeams = [],
  setSelectedTeams,
  resetAllFilters,
  setFilteredRowsIds,
  onColumnsFilter: handleFilteredColumns,
}: FiltersPropTypes) => {
  const { t } = useTranslation();
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const handleOnClose = () => setIsFiltersModalOpen(false);

  const [filtersCount, setFiltersCount] = useState(0);

  const getActiveOptionById = (
    id: string,
    options: CategoryOptionType[] | TeamOptionType[]
  ) => {
    const isActive = !!options?.find((option) => option.id === id);
    return isActive;
  };

  const handleFilteredRows = (id: string) => {
    setFilteredRowsIds((prevFilteredRowsIds) => {
      const newFilteredRowsIds = new Set(prevFilteredRowsIds);

      newFilteredRowsIds.has(id)
        ? newFilteredRowsIds.delete(id)
        : newFilteredRowsIds.add(id);

      gridRef.current?.api.onFilterChanged();

      return newFilteredRowsIds;
    });
  };

  const toggleSelectedCategory = (category: CategoryOptionType) => {
    const isSelected = !!selectedCategories.find(
      (selectedCategory) => selectedCategory.id === category.id
    );

    if (isSelected) {
      const newSelectedCategories = selectedCategories.filter(
        (selectedCategory) => selectedCategory.id !== category.id
      );
      setSelectedCategories(newSelectedCategories);
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }

    viewMode === "team"
      ? handleFilteredColumns(category.id)
      : handleFilteredRows(category.id);
  };

  const toggleSelectedTeam = (team: TeamOptionType) => {
    const isSelected = !!selectedTeams.find(
      (selectedTeam) => selectedTeam.id === team.id
    );

    if (isSelected) {
      const newSelectedTeams = selectedTeams.filter(
        (selectedTeam) => selectedTeam.id !== team.id
      );
      setSelectedTeams(newSelectedTeams);
    } else {
      setSelectedTeams([...selectedTeams, team]);
    }

    viewMode === "category"
      ? handleFilteredColumns(team.id)
      : handleFilteredRows(team.id);
  };

  const toggleSelectAllTeams = () => {
    if (teams) {
      const allSelected = teams.every((team) =>
        selectedTeams.some((selectedTeam) => selectedTeam.id === team.id)
      );

      if (allSelected) {
        setSelectedTeams([]);
        setFilteredRowsIds(new Set());
        gridRef.current?.api.onFilterChanged();
      } else {
        setSelectedTeams(teams);
        const allTeamIds = new Set(teams.map((team) => team.id));
        setFilteredRowsIds(allTeamIds);
        gridRef.current?.api.onFilterChanged();
      }
    }
  };

  useEffect(() => {
    const totalCount = selectedTeams.length + selectedCategories.length;
    setFiltersCount(totalCount);
  }, [selectedTeams, selectedCategories, filtersCount]);

  const hasFilters = filtersCount > 0;

  return (
    <>
      {hasFilters && (
        <CTAButton
          onClick={resetAllFilters}
          variant="secondary"
          name={t("pages.settings.organization.teams.header.filters.main")}
          iconPosition="left"
          icon={<CrossIcon style={{ width: "14px" }} />}
        />
      )}

      <FiltersWrapper>
        <CTAButton
          onClick={() => setIsFiltersModalOpen(true)}
          name={t("pages.settings.organization.teams.header.filters.main")}
          iconPosition="left"
          icon={<FilterIcon />}
          label="Filters"
          variant="secondary"
        />
        {hasFilters && <FiltersCount>{filtersCount}</FiltersCount>}
      </FiltersWrapper>

      <NewModal
        open={isFiltersModalOpen}
        handleClose={handleOnClose}
        title={t("pages.settings.organization.teams.header.filters.main")}
        body={
          <>
            <Section>
              <SectionTitle>
                {t(
                  "pages.settings.organization.teams.header.filters.modal.sections.categories.title"
                )}
              </SectionTitle>

              <SectionDescription>
                {t(
                  "pages.settings.organization.teams.header.filters.modal.sections.categories.description"
                )}
              </SectionDescription>

              <CategoryList>
                {categories?.map((category) => (
                  <CategoryOption
                    key={`category-key-${category.id}`}
                    active={getActiveOptionById(
                      category.id,
                      selectedCategories
                    )}
                    label={category.name}
                    color={category.color}
                    onClick={() => toggleSelectedCategory(category)}
                  />
                ))}
              </CategoryList>
            </Section>

            <Section>
              <SectionTitle>
                <span>
                  {t(
                    "pages.settings.organization.teams.header.filters.modal.sections.teams.title"
                  )}
                </span>
                <CTAButton
                  name={t(
                    "pages.settings.organization.teams.header.filters.modal.sections.teams.selectAll"
                  )}
                  variant="tertiary"
                  onClick={toggleSelectAllTeams}
                />
              </SectionTitle>
              <SectionDescription>
                {t(
                  "pages.settings.organization.teams.header.filters.modal.sections.teams.description"
                )}
              </SectionDescription>
              <TeamList>
                {teams?.map((team) => (
                  <TeamOption
                    key={`team-key-${team.id}`}
                    name={team.name}
                    active={getActiveOptionById(team.id, selectedTeams)}
                    onChange={() => toggleSelectedTeam(team)}
                  />
                ))}
              </TeamList>
            </Section>
          </>
        }
        footer={
          <Footer>
            <CTAButton
              name={t(
                "pages.settings.organization.teams.header.filters.modal.buttons.clearAll"
              )}
              variant="tertiary"
              onClick={resetAllFilters}
              disabled={!hasFilters}
            />
            <CTAButton
              name={t(
                "pages.settings.organization.teams.header.filters.modal.buttons.showResults"
              )}
              onClick={handleOnClose}
              disabled={!hasFilters}
            />
          </Footer>
        }
      />
    </>
  );
};

export default Filters;
