import styled from "@emotion/styled";
import { FormTextField } from "components";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
`;

export const Text = styled.span`
  color: var(--color-text-secondary);
  font-size: ${theme.font.size.caption};
`;

export const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: var(--button-border-radius);

  svg {
    width: 13px;
    height: 13px;
    fill: var(--color-secondary);
    transition: fill 0.25s;
  }
`;

export const ConfirmButton = styled(BaseButton)<{ active?: boolean }>`
  &:hover {
    background-color: var(--color-success-hover-opacity-10);
    svg {
      fill: var(--color-success);
    }
  }

  ${(props) =>
    props.active &&
    `
    background-color: var(--color-success-hover-opacity-10);
    svg {
      fill: var(--color-success);
    }
  `}
`;

export const DeclineButton = styled(BaseButton)<{ active?: boolean }>`
  &:hover {
    background-color: var(--color-danger-hover-opacity-10);
    svg {
      fill: var(--color-danger);
    }
  }

  ${(props) =>
    props.active &&
    `
    background-color: var(--color-danger-hover-opacity-10);
    svg {
      fill: var(--color-danger);
    }
  `}
`;

export const TextArea = styled(FormTextField)`
  margin: 0;
  background-color: var(--color-white);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  background-color: var(--color-background-muted);
  padding: var(--spacing-md);
  border-radius: var(--border-radius);
`;

export const Buttons = styled.div`
  display: flex;
  gap: var(--spacing-md);
  justify-content: flex-end;
`;
