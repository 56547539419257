import React, { useEffect, useMemo, useState } from "react";

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import {
  InitialEditorStateType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { ContentEditable } from "./components/Editor/ContentEditable";
import {
  Components,
  TemplateComponents,
  Title,
} from "./components/Editor/EditorContainer";
import { EditorInner } from "./components/Editor/EditorInner";
import { Placeholder } from "./components/Editor/Placeholder";
import PlaceholderComponent from "./components/PlaceholderComponent/PlaceholderComponent";
import TableCellResizer from "./plugins/TableCellResizer";
import TableHoverActionsPlugin from "./plugins/TableHoverActionsPlugin";
import TemplatePlugin from "./plugins/TemplatePlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin/ToolbarPlugin";
import theme from "./theme";

import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";

import * as Sentry from "@sentry/react";
import { Loader } from "components";
import { useTeam } from "contexts/team/hooks";
import { useLocale } from "hooks";
import { CategoryDTO, ContractFieldDTOV1 } from "openapi";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useTranslation } from "react-i18next";
import { useFieldsQuery } from "shared/api";
import SearchBar, { SearchBarOnChangeValue } from "./components/SearchBar";
import SupportedLexicalNodes from "./config";
import { CheckListPlugin } from "./plugins/CheckListPlugin/CheckListPlugin";
import { FloatingPlaceholderPopupPlugin } from "./plugins/FloatingPlaceholderPopupPlugin";
import ImagesPlugin from "./plugins/ImagePlugin";
import TableCellDragDropPlugin from "./plugins/TableCellDragDropPlugin/TableCellDragDropPlugin";
import PageBreakPlugin from "./plugins/PageBreakPlugin";
import TableActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import "./styles.css";
import { getLocalizedPlaceholderLabel } from "./utils";
import { Layout, Main, Sidebar } from "./styles";
import { getFieldsByType } from "utils/helpers";

export type TextEditorField = ContractFieldDTOV1 & {
  display: string;
  field: string;
};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: unknown) {
  console.error(error);
  Sentry.captureException(error);
}

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith("http") ? fullMatch : `https://${fullMatch}`,
    };
  },
];

type EditorProps = {
  /**
   * Optional initial editor state to pre-populate the editor's content.
   */
  initialState?: InitialEditorStateType;
  /**
   * If true, the editor will be in compact mode, taking up less space
   */
  compact?: boolean;
  currentCategory?: CategoryDTO | undefined;
  /**
   * Show the sidebar allowing the user to drag and drop datapoints into the editor as placeholders
   */
  showSidebar?: boolean;
  /**
   * Allows for custom plguins to be registered depending on the use case of the editor
   * E.g. The document creation editor has a custom plugin for syncing the datapoints
   * between the editors placeholder nodes and the form input fields.
   */
  children?: React.ReactNode;
};

function Editor(props: EditorProps) {
  const initialConfig = {
    namespace: "MyEditor",
    nodes: SupportedLexicalNodes,
    theme,
    onError,
    editorState: props.initialState,
    compact: props.compact,
    showSidebar: props.showSidebar,
  };

  const { organizationId } = useTeam();
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { data: fields, isLoading: fieldsLoading } =
    useFieldsQuery(organizationId);

  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };
  const [zoom] = useState(1);
  const [filteredFields, setFilteredFields] = useState<TextEditorField[]>([]);

  const allFields = useMemo(() => {
    if (!fields) return [];

    return fields?.flatMap((datapoint) =>
      getFieldsByType(datapoint.type).map((field): TextEditorField => {
        const display = getLocalizedPlaceholderLabel(
          datapoint,
          locale,
          t,
          datapoint.visibleId || "",
          field
        );

        return {
          ...datapoint,
          display,
          field,
        };
      })
    );
  }, [fields]);

  const availableFields = useMemo(() => {
    const availableFieldIds = new Set(
      props.currentCategory?.sections
        .map((section) => section.fields.map((field) => field.id))
        .flat()
    );

    return allFields?.filter((field) => availableFieldIds?.has(field.id));
  }, [fields, props.currentCategory]);

  useEffect(() => {
    setFilteredFields(availableFields);
  }, [availableFields]);

  const onFilterChange = (value: SearchBarOnChangeValue) => {
    const filteredItems = availableFields?.filter((item) =>
      item?.display.toLowerCase().includes(value.toLowerCase())
    );
    if (filteredItems) setFilteredFields(filteredItems);
  };

  const isLoading = !fields || fieldsLoading;

  if (isLoading) return <Loader />;
  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Layout>
        <Main fullWidth={!props.showSidebar}>
          <ToolbarPlugin />

          <OverlayScrollbarsComponent defer>
            <EditorInner zoom={zoom} compact={props.compact}>
              <RichTextPlugin
                contentEditable={
                  <div className="editor" ref={onRef}>
                    <ContentEditable />
                  </div>
                }
                placeholder={
                  <Placeholder>{t(`textEditor.emptyPlaceholder`)}</Placeholder>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <ListPlugin />
              <AutoFocusPlugin />
              <AutoLinkPlugin matchers={MATCHERS} />
              <ImagesPlugin />
              <TablePlugin hasCellMerge hasCellBackgroundColor />
              <TabIndentationPlugin />
              <TableCellResizer />
              <TableHoverActionsPlugin />
              <TableActionMenuPlugin />
              <PageBreakPlugin />
              <CheckListPlugin />
              <TemplatePlugin fields={fields} />
              <TableCellDragDropPlugin />
              {floatingAnchorElem && !props.showSidebar && (
                <FloatingPlaceholderPopupPlugin
                  fields={fields ?? []}
                  anchorEl={floatingAnchorElem}
                />
              )}
              {props.children}
            </EditorInner>
          </OverlayScrollbarsComponent>
        </Main>

        {props.showSidebar && (
          <Sidebar>
            <SearchBar onChange={onFilterChange} />

            <TemplateComponents>
              <Title>
                <span>{t("common.headers.fields")}</span>
              </Title>

              <OverlayScrollbarsComponent defer>
                <Components>
                  {filteredFields?.map((field) => (
                    <PlaceholderComponent
                      key={field.id + field.field}
                      datapoint={field}
                      draggable
                    />
                  ))}
                </Components>
              </OverlayScrollbarsComponent>
            </TemplateComponents>
          </Sidebar>
        )}
      </Layout>
    </LexicalComposer>
  );
}

export default Editor;
