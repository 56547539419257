import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { theme } from "theme";

const iconSize = "24px";

export const TagWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};

  svg {
    fill: transparent;
  }
`;

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
`;

export const NameLink = styled(Link)`
  display: flex;
  align-items: center;
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconContainer = styled.span`
  width: ${iconSize};
  display: flex;
  align-items: center;
`;
export const AttachmentIconTiled = styled(AttachmentIcon)`
  transform: rotate(45deg);
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  align-items: center;
`;

export const ChildrenCount = styled.span`
  margin-left: auto;
  color: #e9f3ff;
  padding: 0.25rem 0.5rem;
  background-color: #3182ce;
  border-radius: 0.25rem;
  line-height: 1.4;
`;
