import { TFunction } from "i18next";

export const getLocale = (t: TFunction<"translation">) => {
  const prefix = "pages.contracts.dateFilterOptions";

  return {
    noMatches: t(`${prefix}.NOMATCHES`),
    selectAll: t(`${prefix}.SELECTALL`),
    searchOoo: t(`${prefix}.SEARCHINPUTLABEL`),
    contains: t(`${prefix}.CONTAINS`),
    notContains: t(`${prefix}.NOTCONTAINS`),
    startsWith: t(`${prefix}.STARTSWITH`),
    endsWith: t(`${prefix}.ENDSWITH`),
    andCondition: t(`${prefix}.AND`),
    orCondition: t(`${prefix}.OR`),
    equals: t(`${prefix}.EQUALS`),
    greaterThan: t(`${prefix}.GREATERTHAN`),
    lessThan: t(`${prefix}.LESSTHAN`),
    notEqual: t(`${prefix}.NOTEQUAL`),
    lessThanOrEqual: t(`${prefix}.LESSTHANOREQUAL`),
    greaterThanOrEqual: t(`${prefix}.GREATERTHANOREQUAL`),
    inRange: t(`${prefix}.INRANGE`),
    blank: t(`${prefix}.BLANK`),
    notBlank: t(`${prefix}.NOTBLANK`),
    noRowsToShow: t("pages.contracts.messages.noRowsToShow"),
    totalAndFilteredRows: t("pages.contracts.messages.totalAndFilteredRows"),
    of: t("pages.contracts.messages.of"),
  };
};
