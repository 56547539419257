import styled from "@emotion/styled";
import { CTAButton, FormTextField } from "components";
import AiIcon from "assets/svg/ai.svg?react";
import { theme } from "theme";
import { keyframes } from "@emotion/react";

const gradientMove = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-lg);
  position: relative;
  border-radius: calc(var(--border-radius) + 1px);
  background: var(--gradient-background);
  z-index: 1;
  margin-bottom: var(--spacing-xs);

  :before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border-radius: var(--border-radius);
    background: var(--color-white);
    z-index: -1;
  }
`;

export const Heading = styled.div`
  display: flex;
  gap: var(--spacing-xs);
`;

export const HeadingIcon = styled(AiIcon)`
  flex: 0 0 auto;
  width: 22px;
  height: 22px;
  color: var(--color-base-analysis);
`;

export const HeadingText = styled.div`
  font-weight: ${theme.font.weight.semiBold};
`;

export const TextArea = styled(FormTextField)`
  margin: 0;
`;

export const Button = styled(CTAButton)`
  align-self: flex-start;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
`;

export const GradientTextAnimated = styled.div`
  background: var(--gradient-background);
  background-size: 200%;
  background-clip: text;
  text-fill-color: transparent;
  color: transparent;
  animation-name: ${gradientMove};
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
`;

export const Results = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
`;
