import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NewModal, CTAButton, SectionHeader, Chip } from "components";
import CardWrapper from "components/CardWrapper/CardWrapper";
import routePaths from "constants/routePaths";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import { ButtonsWrapper } from "../../Category/components/styles";
import {
  useDeleteTaskAutomationMutation,
  useOrganizationCategoriesQuery,
} from "shared/api";
import { CategoryDTO, TaskAutomationDTO } from "openapi";
import { FlexRow } from "../styles";
import { getColor } from "components/Chip/helpers";
import { useLocale } from "hooks";
import { Description, Title } from "./styles";

export type DeleteModalConfirmationProps = {
  open: boolean;
  handleClose: () => void;
  selectedTaskAutomation: TaskAutomationDTO;
};

export type DeleteSectionType = {
  selectedTaskAutomation: TaskAutomationDTO;
};

export const DeleteModalConfirmation = ({
  open,
  handleClose,
  selectedTaskAutomation,
}: DeleteModalConfirmationProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { organizationId } = useTeam();
  const { data: categories } = useOrganizationCategoriesQuery(organizationId);
  const filteredCategories = useMemo(() => {
    return categories?.filter((category) =>
      selectedTaskAutomation?.categoryIds?.includes(category.id)
    );
  }, [categories, selectedTaskAutomation]);

  const taskAutomationId = selectedTaskAutomation?.id;

  const { mutateAsync: deleteTaskAutomation } =
    useDeleteTaskAutomationMutation();

  const handleOnDelete = async () => {
    try {
      if (!taskAutomationId || taskAutomationId === "new") return;
      await deleteTaskAutomation({ organizationId, taskAutomationId });
      handleClose();
      navigate(routePaths.SETTINGS_TASK_AUTOMATION);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NewModal
      open={open}
      handleClose={handleClose}
      title={t("pages.settings.organization.taskAutomations.deleteModal.title")}
      fullWidth
      maxWidth="sm"
      body={
        <>
          <Description>
            {t(
              "pages.settings.organization.taskAutomations.deleteModal.description"
            )}
          </Description>
          <Title>{selectedTaskAutomation?.title}</Title>
          <Description>
            {t("pages.settings.organization.taskAutomations.deleteModal.info")}
          </Description>
          <FlexRow>
            {filteredCategories?.map((category: CategoryDTO) => {
              return (
                <Chip
                  key={category?.id}
                  blendMode="soft"
                  color={getColor(category?.color || theme.color.blue[600])}
                >
                  {category?.name[locale]}
                </Chip>
              );
            })}
          </FlexRow>
        </>
      }
      footer={
        <ButtonsWrapper>
          <CTAButton
            label="cancelBtn"
            type="reset"
            onClick={handleClose}
            name={t("common.buttons.cancel")}
            variant="secondary"
          />
          <CTAButton
            label="deleteBtn"
            type="submit"
            variant="primary"
            color="danger"
            onClick={() => handleOnDelete()}
            name={t("common.buttons.deleteConfirm")}
          />
        </ButtonsWrapper>
      }
    />
  );
};

const DeleteSection = ({ selectedTaskAutomation }: DeleteSectionType) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <CardWrapper style={{ margin: `0 0 ${theme.spacing.xxxxl}` }}>
        <SectionHeader
          title={t(
            "pages.settings.organization.taskAutomations.deleteSection.title"
          )}
          noPadding
          baseButtonText={t(
            "pages.settings.organization.taskAutomations.deleteSection.buttonName"
          )}
          onButtonClick={() => {
            setShowDeleteModal(true);
          }}
          buttonVariant="secondary"
          buttonId="task-automation-delete-button"
          buttonColor="danger"
        />
        <p>
          {t(
            "pages.settings.organization.taskAutomations.deleteSection.description"
          )}
        </p>
      </CardWrapper>
      <DeleteModalConfirmation
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        selectedTaskAutomation={selectedTaskAutomation}
      />
    </>
  );
};

export default DeleteSection;
