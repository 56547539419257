import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  svg {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    fill: var(--gradient-main);
  }
  span {
    background: var(--gradient-background);
    background-clip: text;
    text-fill-color: transparent;
  }
`;
