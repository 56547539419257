import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { theme } from "theme";

const preventTextWrapperPropForwarding = (propName: string) => {
  return propName !== "suggestion";
};

export const StyledTextField = styled(TextField, {
  shouldForwardProp: preventTextWrapperPropForwarding,
})`
  .MuiInputBase-root {
    border-width: 1px;
    border-radius: var(--border-radius);
    border-color: var(--color-border-neutral);
    margin: 0;
    ${({ suggestion }: { suggestion?: boolean }) =>
      suggestion && `color: ${theme.color.orange[600]}`};
  }

  label {
    width: calc(100% - 40px) !important;
  }
`;
