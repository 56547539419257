/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { I18nDto } from "./I18nDto";

export type ContractFieldPromptTestDTO = {
  prompt?: Record<string, any> | null;
  type?: ContractFieldPromptTestDTO.type;
  name?: I18nDto;
  contractId: string;
};

export namespace ContractFieldPromptTestDTO {
  export enum type {
    TEXTFIELD = "TEXTFIELD",
    TEXTAREA = "TEXTAREA",
    NUMBER = "NUMBER",
    DATE = "DATE",
    LIST = "LIST",
    AMOUNT = "AMOUNT",
    LINK = "LINK",
    COUNTRY = "COUNTRY",
    FORMULA = "FORMULA",
    DURATION = "DURATION",
    CONTACT = "CONTACT",
  }
}
