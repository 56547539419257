import React, { useEffect, useState } from "react";
import { BackButton } from "../Team/styles";
import { ButtonWithArrow, NewModal, SectionHeader } from "components";
import routePaths from "constants/routePaths";
import { useNavigate, useParams } from "react-router";
import { t } from "i18next";
import CardWrapper from "components/CardWrapper/CardWrapper";

import DeleteModalContent from "./components/DeleteModalContent";
import { CategoryDTO, OrganizationNewService } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { enqueueSnackbar } from "notistack";
import CategorySetting from "./components/CategorySetting";

const Category = () => {
  const navigate = useNavigate();
  const { id: categoryId } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { organizationId } = useTeam();
  const [category, setCategory] = useState<CategoryDTO>();

  useEffect(() => {
    void getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const category = await OrganizationNewService.getCategory(
        organizationId,
        categoryId as string
      );

      setCategory(category);
    } catch (error) {
      enqueueSnackbar(
        t("pages.categoryDetails.messages.loadingCategoryFailed"),
        {
          variant: "error",
        }
      );
    }
  };

  const onBack = () => {
    navigate(routePaths.SETTINGS_CUSTOM_FIELDS);
  };

  if (!category) return null;

  return (
    <>
      <>
        <BackButton>
          <ButtonWithArrow
            onClick={onBack}
            label={t(
              "pages.settings.tabs.subTeams.section.showSubTeams.goBack"
            )}
            ariaLabel="back"
          />
        </BackButton>

        <CategorySetting category={category} />

        <CardWrapper>
          <SectionHeader
            title={t("pages.categoryDetails.sections.delete.title")}
            baseButtonText={t("pages.categoryDetails.buttons.deleteCategory")}
            onButtonClick={() => {
              setShowDeleteModal(true);
            }}
            noPadding
            buttonId="sub-team-delete-button"
            buttonVariant="secondary"
            buttonColor="danger"
          />
          <p>{t("pages.categoryDetails.sections.delete.content")}</p>
        </CardWrapper>
      </>
      <NewModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        title={t("pages.categoryDetails.modals.delete.title")}
        body={
          <DeleteModalContent
            handleClose={() => setShowDeleteModal(false)}
            category={category}
          />
        }
        maxWidth="sm"
      />
    </>
  );
};

export default Category;
