import React, { useState } from "react";
import { useLocale } from "../../../hooks";
import { CellValue } from "../helpers";
import { NumericCellEditor } from "./style";
import { NumberFieldConfiguration } from "openapi";
import { CustomCellEditorProps } from "ag-grid-react";
import { NumberFormatValues } from "react-number-format";

interface CustomCellEditorParams extends CustomCellEditorProps<CellValue> {
  type?: string;
  configuration?: NumberFieldConfiguration | null | undefined;
  isCustomField?: boolean;
  value: CellValue;
}

export const NumberCellEditor = (props: CustomCellEditorParams) => {
  const { isCustomField, value: cellValue, type, configuration } = props;
  const [value, setValue] = useState<number | null>(
    (cellValue?.value ?? cellValue) as number
  );
  const { locale } = useLocale();
  const decimalSeparator = locale === "en" ? "." : ",";
  const thousandSeparator = locale === "de" ? "." : ",";
  const decimalScale = configuration?.decimalScale || 2;

  const handleValueChanged = (value: NumberFormatValues) => {
    setValue(value.floatValue ?? null);
    const formattedValue = isCustomField
      ? { value: value.floatValue }
      : value.floatValue;
    props.onValueChange(formattedValue);
  };

  const isAmount = type === "amount";
  return (
    <NumericCellEditor
      autoFocus={true}
      value={value}
      onValueChange={(val) => {
        handleValueChanged(val);
      }}
      onBlur={() => {
        props.api.stopEditing();
      }}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      fixedDecimalScale={isAmount}
      decimalScale={isAmount ? 2 : decimalScale}
    />
  );
};

NumberCellEditor.displayName = "NumberCellEditor";
