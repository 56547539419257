import React, { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Paper,
} from "@mui/material";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import {
  AmountDatapointDTO,
  CountryDatapointDTO,
  DateDatapointDTO,
  LinkDatapointDTO,
  MultilineDatapointDTO,
  NumberDatapointDTO,
  TextDatapointDTO,
  ListDatapointDTO,
} from "openapi";

export type Option = {
  key: string;
  value: string;
};

export type AutocompleteSelectProps = {
  options: Option[];
  defaultValue?: Option;
  inputRef?: React.Ref<HTMLInputElement>;
  onSelect: (value: Option) => void;
  label: string;
  id?: string;
  dataCy?: string;
  paperComponent?: React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onPapperClick?: (value: string) => void;
  noOptionsText?: string | React.ReactNode;
  value?:
    | string
    | Date
    | undefined
    | number
    | AmountDatapointDTO
    | CountryDatapointDTO
    | DateDatapointDTO
    | LinkDatapointDTO
    | MultilineDatapointDTO
    | NumberDatapointDTO
    | TextDatapointDTO
    | ListDatapointDTO
    | boolean
    | null;
  inputStyles?: React.CSSProperties;
  clearable?: boolean;
};

const AutoCompleteSelect: React.FC<AutocompleteSelectProps> = ({
  options,
  inputRef,
  onSelect,
  label,
  id,
  dataCy,
  paperComponent,
  defaultValue,
  inputStyles,
  onPapperClick,
  noOptionsText,
  startAdornment,
  endAdornment,
  value,
  clearable,
}) => {
  const [open, setOpen] = useState(false);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const paperDivRef = useRef<HTMLDivElement | null>(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const filterOptions = (searchValue: string) => {
    if (!searchValue) {
      setFilteredOptions(options);
      return;
    }
    const filteredOpt = options.filter((option) =>
      option.value.toLowerCase().includes(searchValue.toLowerCase())
    );

    const isExisting = options.some((option) => searchValue === option.value);
    if (searchValue !== "" && !isExisting) {
      setInputValue(searchValue);
    }
    setFilteredOptions(filteredOpt);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target as Node) &&
        paperDivRef.current &&
        !paperDivRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const option = options.find((option) => option.key === value) || null;

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      value={option}
      open={open}
      defaultValue={defaultValue}
      options={filteredOptions}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <StyledTextField
            {...params}
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              startAdornment,
              endAdornment,
            }}
            style={{ marginTop: "1rem", ...inputStyles }}
            variant="outlined"
            label={label}
            size="small"
          />
        );
      }}
      PaperComponent={({ children, ...props }) => (
        <Paper {...props} ref={autocompleteRef}>
          {children}
          <div
            ref={paperDivRef}
            onClick={() => {
              if (onPapperClick) {
                onPapperClick(inputValue);
              }
              setInputValue("");
            }}
          >
            {paperComponent}
          </div>
        </Paper>
      )}
      getOptionLabel={(option) => (option as Option).value}
      isOptionEqualToValue={(option, value) => option.key === value?.key}
      onInputChange={(event, searchValue, reason) => {
        if (reason === "reset") return;
        filterOptions(searchValue);
      }}
      onChange={(event, newValue) => {
        onSelect(newValue as Option);
        setOpen(false);
      }}
      disableClearable={!clearable}
      data-cy={dataCy}
      id={id}
      autoHighlight
      openOnFocus
      onOpen={() => {
        setOpen(true);
        setFilteredOptions(options);
      }}
      noOptionsText={noOptionsText || ""}
      renderOption={(props, option) => (
        <li {...props} key={option.key}>
          {option.value}
        </li>
      )}
      freeSolo
    />
  );
};

export default AutoCompleteSelect;
