import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ContractFieldDTOV1 } from "openapi";
import { useLocale } from "hooks/GlobalStateHooks";
import theme from "theme/theme";
import { CTAButton, NewModal } from "components";
import { useContractDefinitionDeleteMutation } from "../../../../../../../shared/api/contract-definition";
import { useTeam } from "../../../../../../../contexts/team/hooks";
import AlarmIcon from "assets/svg/alarm-icon.svg?react";
import { Box } from "@mui/material";
import {
  AlertIconWrapper,
  AlertText,
  AlertWrapper,
} from "pages/Settings/OrganizationSettings/Team/styles";

type Props = {
  open: boolean;
  handleOnClose: () => void;
  field: ContractFieldDTOV1;
};

const DeleteField = ({ open, handleOnClose, field }: Props) => {
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { organizationId } = useTeam();

  const deleteContractDefinition = useContractDefinitionDeleteMutation();

  if (!field.id) return null;

  const handleOnClick = async () => {
    try {
      await deleteContractDefinition.mutateAsync({
        organizationId: organizationId,
        fieldId: field.id,
      });

      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.fields.modal.delete.success"
        ),
        {
          variant: "success",
        }
      );
      handleOnClose();
    } catch (error) {
      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.fields.modal.delete.success"
        ),
        { variant: "error" }
      );
      handleOnClose();
    }
  };

  const fieldName = field?.name[locale] ? field?.name[locale] : field?.name;

  return (
    <NewModal
      open={open}
      handleClose={handleOnClose}
      title={t(
        "pages.settings.organization.administrator.fields.modal.delete.title",
        { fieldName }
      )}
      fullWidth
      body={
        <>
          {/* @Todo replace this with <MessageBox> component (will be introduced shortly) */}
          <Box
            className="flex-align-center"
            sx={{ marginBottom: theme.spacing.lg }}
          >
            <Box>
              <AlertWrapper>
                <AlertIconWrapper>
                  <AlarmIcon />
                </AlertIconWrapper>
                <AlertText>
                  <h3>
                    <span>
                      {t(
                        "pages.settings.organization.administrator.fields.modal.delete.messageTitle",
                        { fieldName }
                      )}
                    </span>
                  </h3>
                  <span>
                    {t(
                      "pages.settings.organization.administrator.fields.modal.delete.messageDescription"
                    )}
                  </span>
                </AlertText>
              </AlertWrapper>
            </Box>
          </Box>
        </>
      }
      footer={
        <>
          <CTAButton
            variant="secondary"
            type="reset"
            label="cancelDeleteCustomFieldButton"
            onClick={handleOnClose}
            name={t("common.buttons.cancel")}
          />
          <CTAButton
            variant="primary"
            label="deleteCustomFieldButton"
            name={t("common.buttons.delete")}
            onClick={handleOnClick}
            color="danger"
          />
        </>
      }
    />
  );
};

export default DeleteField;
