import React, { useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Tooltip, CircularProgress } from "@mui/material";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ColorPoint } from "../components/Header/components/CategorySelector/styles";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import {
  useContractTemplateCreateMutationV1,
  useContractTemplatesQueryV1,
  useGetAllContractTemplateTagsQuery,
} from "shared/api/contract-templates";
import { categoriesTeamSelector } from "shared/api/organization/categories.helpers";
import { getDefaultCategory } from "shared/api/organization/categories.helpers";
import { useProfileQuery } from "shared/api/profile";
import { useTeam } from "contexts/team/hooks";
import { useLocale } from "hooks";
import {
  CategoryDTO,
  ContractInputDTOV1,
  ContractTemplateTagDTO,
  ContractTemplateV1DTO,
  OrganizationService,
  ContractDTOV1,
} from "openapi";
import { TextField, Tag, DocumentListItem } from "new-components";
import routePaths from "constants/routePaths";
import { TemplateCard } from "./components";
import { ContractTemplateMode } from "../types";
import { AcceptedFileType } from "shared/enums/document.enum";
import {
  AllButton,
  Filters,
  FilterSection,
  FilterTitle,
  List,
  ListItem,
  Container,
  SearchBlock,
  TemplatesContainer,
  TemplatesGallery,
  CreateTemplateCard,
  CreateNewCaption,
  CreateNewButton as StyledCreateNewButton,
  TagList,
  Divider,
  Description,
  NoTemplatesMessage,
  ViewIconButton,
} from "./styles";
import GridIcon from "assets/svg/grid-view.svg?react";
import ListIcon from "assets/svg/list-icon.svg?react";
import { theme } from "theme";
import {
  useAddDocumentMutation,
  useFieldsQuery,
} from "../../../../../shared/api";
import { useQueryClient } from "@tanstack/react-query";

export type ContractTemplateCollectionProps = {
  mode?: ContractTemplateMode;
  handleClose?: () => void;
  contract?: ContractDTOV1;
};

const TemplateCollection = ({
  mode = ContractTemplateMode.EDIT,
  handleClose,
  contract,
}: ContractTemplateCollectionProps) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOwner, organizationId, selectedTeamId, hasWriteAccess } = useTeam();
  const { data: categories } = useOrganizationCategoriesQuery(
    organizationId,
    (categories) =>
      categories.filter((category) =>
        categoriesTeamSelector(category, selectedTeamId)
      )
  );
  const { id: contactId } = useParams();
  const { data: fields } = useFieldsQuery(organizationId);
  const { mutateAsync: addDocumentToContract } = useAddDocumentMutation();
  const queryClient = useQueryClient();
  const defaultCategory = getDefaultCategory(categories, selectedTeamId);
  const { data: userData } = useProfileQuery();

  const getPermittedCategories = () => {
    if (isOwner()) {
      return categories;
    }
    const teamId = contract?.teamId || selectedTeamId;

    return categories?.filter((category) => {
      return (
        category.teams.includes(teamId) &&
        hasWriteAccess(category.id, selectedTeamId)
      );
    });
  };

  const permittedCategories = getPermittedCategories();

  const { data: tags } = useGetAllContractTemplateTagsQuery(organizationId);
  const { data: templates } = useContractTemplatesQueryV1(organizationId);
  const createTemplate = useContractTemplateCreateMutationV1();

  const isEditable = mode === ContractTemplateMode.EDIT;

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [isGridView, setIsGridView] = useState<boolean>(true);

  const filteredTemplates = useMemo(() => {
    return templates?.filter((template) => {
      const filters = [
        () =>
          selectedCategories.length === 0 ||
          selectedCategories.includes(template.categoryId),
        () =>
          selectedTags.length === 0 ||
          template.tags.some((tag) => selectedTags.includes(tag.id)),
        () =>
          !searchTerm ||
          template.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ];
      return filters.every((filter) => filter());
    });
  }, [templates, selectedCategories, selectedTags, searchTerm]);

  const resetFilters = () => {
    setSelectedCategories([]);
    setSelectedTags([]);
    setSearchTerm("");
  };

  const searchByName = (term: string) => setSearchTerm(term);

  const toggleCategory = (category: CategoryDTO) => {
    setSelectedCategories((prev) =>
      prev.includes(category.id)
        ? prev.filter((id) => id !== category.id)
        : [...prev, category.id]
    );
  };

  const toggleTag = (tag: ContractTemplateTagDTO) => {
    setSelectedTags((prev) =>
      prev.includes(tag.id)
        ? prev.filter((id) => id !== tag.id)
        : [...prev, tag.id]
    );
  };

  const applyTemplateToContract = async (
    contractId: string,
    teamId: string,
    template: ContractTemplateV1DTO
  ) => {
    try {
      setIsLoadingTemplate(true);

      const file = new File(
        [
          JSON.stringify({
            templateId: template.id,
            content: template.content,
          }),
        ],
        template.name,
        { type: AcceptedFileType.LEXICAL }
      );
      await addDocumentToContract({
        organizationId,
        teamId: teamId,
        file,
        contractId,
      });

      await OrganizationService.updateContract(teamId, contractId, {
        categoryId: template.categoryId,
      });
      void queryClient.invalidateQueries(["documents"]);
      // the data update calls in handle close should be handled once template is added in contracts
      handleClose?.();
      enqueueSnackbar(
        t(
          "pages.contractDetails.notifications.success_apply_template_to_contract"
        ),
        {
          variant: "success",
        }
      );
      setIsLoadingTemplate(false);
    } catch (error) {
      enqueueSnackbar(
        t(
          "pages.contractDetails.notifications.error_apply_template_to_contract"
        ),
        {
          variant: "error",
        }
      );
    }
  };

  const createContractFromTemplate = async (
    template: ContractTemplateV1DTO
  ) => {
    try {
      if (!permittedCategories) return;

      let fieldObj = {};
      if (contactId && location.pathname.includes(routePaths.CONTACTS)) {
        const partnerCompanyId = fields?.find(
          (definition) => definition.visibleId === "partnerCompany"
        )?.id;
        if (partnerCompanyId) {
          fieldObj = {
            [partnerCompanyId]: {
              value: contactId || "",
            },
          };
        }
      }

      const draftContract = await OrganizationService.createContract(
        selectedTeamId,
        {
          name: t("pages.contractDetails.general.newContractName"),
          status: ContractInputDTOV1.status.DRAFT,
          categoryId: template.categoryId,
          fields: fieldObj,
        }
      );

      if (!draftContract.id) return;

      await applyTemplateToContract(
        draftContract.id,
        draftContract.teamId,
        template
      );

      return draftContract;
    } catch (error) {
      enqueueSnackbar(
        t(
          "pages.contractDetails.notifications.error_apply_template_to_contract"
        ),
        {
          variant: "error",
        }
      );
    }
  };

  const handleOnTemplateCardClick = async (template: ContractTemplateV1DTO) => {
    // @Note: Create draft contract from existing template (user)
    if (mode === ContractTemplateMode.PRESELECT) {
      try {
        const newContractWithTemplate = await createContractFromTemplate(
          template
        );

        handleClose?.();

        if (newContractWithTemplate) {
          navigate(
            `${routePaths.CONTRACTS}/${newContractWithTemplate.id}/edit`
          );
        }

        return;
      } catch (error) {
        enqueueSnackbar(
          t(
            "pages.contractDetails.notifications.error_apply_template_to_contract"
          ),
          {
            variant: "error",
          }
        );
      }
    }

    // @Note: Select and apply template from collection to an existing contract without document (user)
    if (mode === ContractTemplateMode.SELECT) {
      if (!contract) return;
      void applyTemplateToContract(contract.id, contract?.teamId, template);
      navigate(`${routePaths.CONTRACTS}/${contract.id}/edit`);
      handleClose?.();
    }

    // @Note: Navigate to selected template (admin)
    if (isEditable) {
      navigate(`${routePaths.SETTINGS_CONTRACT_TEMPLATES}/${template.id}`);
      return;
    }

    return;
  };

  const hasTemplatesAvailable =
    filteredTemplates && filteredTemplates?.length > 0;

  const handleToggleView = () => setIsGridView((prev) => !prev);

  if (isLoadingTemplate) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const CreateTemplate = () => {
    const handleCreateTemplateClick = async () => {
      try {
        if (!defaultCategory) return;

        const dateFormat = userData?.dateFormat ?? "DD/MM/YYYY";
        const templateName = `${t(
          "pages.settings.organization.contractTemplates.fields.name.defaultValue"
        )} - ${dayjs().format(`${dateFormat} - HH:mm:ss`)}`;

        const newTemplate = await createTemplate.mutateAsync({
          organizationId,
          template: {
            name: templateName,
            content: "",
            categoryId: defaultCategory?.id,
            tagIds: [],
          },
        });

        navigate(`${routePaths.SETTINGS_CONTRACT_TEMPLATES}/${newTemplate.id}`);
        enqueueSnackbar(
          t("pages.settings.tabs.contractTemplates.snackbars.createSuccess"),
          { variant: "success" }
        );
      } catch (error) {
        enqueueSnackbar(
          t("pages.settings.tabs.contractTemplates.snackbars.createFailure"),
          { variant: "error" }
        );
      }
    };

    const CreateNewButton = () => (
      <StyledCreateNewButton $isGridView={isGridView}>+</StyledCreateNewButton>
    );

    const Caption = () => (
      <CreateNewCaption>
        {t(
          "pages.settings.tabs.contractTemplates.gallery.createTemplateCard.caption"
        )}
      </CreateNewCaption>
    );

    const Content = () => {
      const title = t(
        "pages.settings.tabs.contractTemplates.gallery.createTemplateCard.title"
      );

      if (isGridView) {
        return (
          <>
            <CreateNewButton />
            {title}
            <Caption />
          </>
        );
      }

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing.sm,
          }}
        >
          <CreateNewButton />
          {title}
        </div>
      );
    };

    if (!isGridView) {
      return (
        <DocumentListItem
          // HACK: to get the add icon button in the list view
          listItem={{ name: <Content /> }}
          placeholder={<Caption />}
          onClick={handleCreateTemplateClick}
        />
      );
    }

    return (
      <CreateTemplateCard onClick={handleCreateTemplateClick}>
        <Content />
      </CreateTemplateCard>
    );
  };

  const ViewIcon = () => {
    const blue = theme.color.blue[700];
    const content = isGridView
      ? {
          title: t(
            "pages.settings.tabs.contractTemplates.gallery.searchBar.view.listView"
          ),
          color: blue,
        }
      : {
          title: t(
            "pages.settings.tabs.contractTemplates.gallery.searchBar.view.gridView"
          ),
          stroke: blue,
        };

    return (
      <Tooltip describeChild placement="top" title={content.title}>
        <ViewIconButton
          stroke={content.stroke}
          color={content.color}
          onClick={handleToggleView}
        >
          {isGridView ? <ListIcon /> : <GridIcon />}
        </ViewIconButton>
      </Tooltip>
    );
  };

  return (
    <Container>
      <Filters>
        <FilterSection>
          <FilterTitle>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.filters.title.templates"
            )}
          </FilterTitle>
          <div className="all">
            <AllButton
              name={t(
                "pages.settings.tabs.contractTemplates.gallery.filters.title.all"
              )}
              onClick={resetFilters}
              size="stretched"
            />
          </div>
        </FilterSection>

        <FilterSection>
          <FilterTitle>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.filters.title.categories"
            )}
          </FilterTitle>
          <OverlayScrollbarsComponent defer style={{ overflowY: "auto" }}>
            <List>
              {categories?.map((category) =>
                category.name[locale].length < 19 ? (
                  <ListItem
                    key={category.id}
                    onClick={() => toggleCategory(category)}
                    style={{
                      fontWeight: selectedCategories.includes(category.id)
                        ? "bold"
                        : "normal",
                    }}
                  >
                    <ColorPoint color={category.color} />
                    <span>{category.name[locale]}</span>
                  </ListItem>
                ) : (
                  <Tooltip title={category.name[locale]} key={category.id}>
                    <ListItem
                      onClick={() => toggleCategory(category)}
                      style={{
                        fontWeight: selectedCategories.includes(category.id)
                          ? "bold"
                          : "normal",
                      }}
                    >
                      <ColorPoint color={category.color} />
                      <span>{category.name[locale]}</span>
                    </ListItem>
                  </Tooltip>
                )
              )}
            </List>
          </OverlayScrollbarsComponent>
        </FilterSection>

        <Divider />

        <FilterSection>
          <FilterTitle>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.filters.title.tags"
            )}
          </FilterTitle>
          <OverlayScrollbarsComponent>
            <List>
              <TagList>
                {tags?.map((tag) => (
                  <div
                    key={tag.id}
                    onClick={() => toggleTag(tag)}
                    className="tag"
                  >
                    <Tag
                      variant={
                        selectedTags.includes(tag.id) ? "selected" : "tag"
                      }
                    >
                      {tag.name}
                    </Tag>
                  </div>
                ))}
              </TagList>
            </List>
          </OverlayScrollbarsComponent>
        </FilterSection>
      </Filters>

      <OverlayScrollbarsComponent style={{ width: "100%" }}>
        <TemplatesContainer>
          <Description>
            {isEditable
              ? t(
                  "pages.settings.tabs.contractTemplates.gallery.description.edit"
                )
              : t(
                  "pages.settings.tabs.contractTemplates.gallery.description.apply"
                )}
          </Description>

          <SearchBlock>
            <TextField
              fullWidth
              name="search-contract-template"
              onChange={(event) => searchByName(event.target.value)}
              placeholder={t(
                "pages.settings.tabs.contractTemplates.gallery.searchBar.placeholder"
              )}
              value={searchTerm}
            />
            <ViewIcon />
          </SearchBlock>

          <h3>{t("pages.settings.tabs.contractTemplates.gallery.title")}</h3>

          <TemplatesGallery $isGridView={isGridView}>
            {isEditable && <CreateTemplate />}

            {hasTemplatesAvailable ? (
              filteredTemplates?.map((template) => {
                const templateCategory = (
                  isEditable ? categories : permittedCategories
                )?.find((category) => category.id === template.categoryId);

                if (!templateCategory) return null;

                return (
                  <TemplateCard
                    isEditable={isEditable}
                    key={template.id}
                    template={{
                      ...template,
                      content:
                        (template as ContractTemplateV1DTO)?.content ?? "",
                    }}
                    category={templateCategory}
                    onClick={() => handleOnTemplateCardClick(template)}
                    isGridView={isGridView}
                  />
                );
              })
            ) : (
              <NoTemplatesMessage>
                {t("pages.settings.tabs.contractTemplates.gallery.unavailable")}
              </NoTemplatesMessage>
            )}
          </TemplatesGallery>
        </TemplatesContainer>
      </OverlayScrollbarsComponent>
    </Container>
  );
};

export default TemplateCollection;
