import React, { useEffect, useRef, useState } from "react";
import { MenuItem } from "@mui/material";
import { ColoredPoint } from "../../../components";
import { FormSelectItem } from "../../../components/FormItems/FormSelect/FormSelect";
import { CellValue } from "../helpers";
import { useTranslation } from "react-i18next";
import { CategoryDTO } from "../../../openapi";
import { CellEditorField } from "./style";
import { theme } from "theme";
import { useLocale } from "hooks";
import { CustomCellEditorProps } from "ag-grid-react";

interface SelectCellEditorParams extends CustomCellEditorProps<CellValue> {
  values: FormSelectItem[] | string[];
  type?: string;
  translationKey?: string;
  isCustomField?: boolean;
  value: CellValue;
}

export const SelectCellEditor = (props: SelectCellEditorParams) => {
  const {
    type,
    translationKey,
    values,
    value: cellValue,
    isCustomField,
  } = props;
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [value, setValue] = useState<string | CellValue | null>(
    (cellValue?.value ?? cellValue) as string
  );
  const [open, setOpen] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const paperRef = useRef<HTMLDivElement | null>(null);
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab" && event.shiftKey) {
      props.api.tabToPreviousCell();
    }
    //save changes and move to next cell
    else if (event.key === "Tab" && !event.shiftKey) {
      props.api.tabToNextCell();
    }
  };
  const onChange = (value: string) => {
    setOpen(false);
    setValue(value);
    const formattedValue = isCustomField ? { value: value } : value;
    props.onValueChange(formattedValue);
  };

  const menuItem = (
    item: FormSelectItem | string | CategoryDTO,
    index: number
  ) => {
    let menuItem: FormSelectItem | string | CategoryDTO;
    switch (type) {
      case "category":
        menuItem = item as CategoryDTO;
        return (
          <MenuItem key={index} value={menuItem.id}>
            {menuItem.color && <ColoredPoint color={menuItem.color} />}
            <span>{menuItem.name[locale]}</span>
          </MenuItem>
        );
      case "country":
        menuItem = item as FormSelectItem;
        return (
          <MenuItem key={index} value={menuItem.key}>
            <span>{menuItem.value}</span>
          </MenuItem>
        );
      // @TODO: needs to be refactored
      case "contact":
        menuItem = item as FormSelectItem;
        return (
          <MenuItem key={index} value={menuItem.key}>
            <span>{menuItem.value}</span>
          </MenuItem>
        );
      default:
        menuItem = item as string;
        return (
          <MenuItem key={index} value={menuItem}>
            <span>
              {translationKey
                ? t(`enums.${translationKey}.${menuItem}`)
                : menuItem}
            </span>
          </MenuItem>
        );
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        (inputRef.current && inputRef.current.contains(event.target as Node)) ||
        (paperRef.current && paperRef.current.contains(event.target as Node))
      ) {
        return;
      }

      props.api.stopEditing();
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, paperRef]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const keyPressed = event.key;
      //Discard changes
      if (keyPressed === "Escape") {
        props.api.stopEditing(true);
      }
      //save changes and close cellEditors
      if (keyPressed === "Enter") {
        props.api.stopEditing();
      }
    };

    document.addEventListener("keyup", handleKeyDown);

    return () => {
      document.removeEventListener("keyup", handleKeyDown);
    };
  }, []);

  if (values && type === "contact") {
    values.sort((a, b) =>
      (a as FormSelectItem).value.localeCompare((b as FormSelectItem).value)
    );
  }

  return (
    <CellEditorField
      ref={inputRef}
      value={value ?? ""}
      focused={true}
      onChange={(event) => onChange(event.target.value)}
      onClick={() => setOpen(!open)}
      fullWidth
      select={true}
      onKeyDown={onKeyDown}
      size="small"
      SelectProps={{
        open: open,
        MenuProps: {
          PaperProps: {
            ref: paperRef,
          },
          sx: {
            maxHeight: "calc(100% - 40%)",
            "& .MuiPaper-root": {
              boxShadow: theme.shadow.standard,
            },
          },
        },
      }}
      style={{ pointerEvents: "none" }}
    >
      {values?.map((item, index) => menuItem(item, index))}
    </CellEditorField>
  );
};

SelectCellEditor.displayName = "SelectCellEditor";
