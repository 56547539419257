import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { DatePicker } from "components/DatePicker/DatePicker";
import { CustomDateProps } from "ag-grid-react";

const CustomDatepicker = forwardRef((props: CustomDateProps, ref) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const refContainer = useRef<HTMLDivElement>(null);

  const dateRef = useRef<Date | null>();

  const onDateChanged = (selectedDate: string | null) => {
    const date = selectedDate ? new Date(selectedDate) : null;
    dateRef.current = date;
    setSelectedDate(date);
    props.onDateChange(date);
  };
  useImperativeHandle(ref, () => ({
    getDate() {
      return dateRef.current;
    },
    setDate(date: Date | null) {
      dateRef.current = date;
      setSelectedDate(date);
    },
  }));

  useEffect(() => {
    if (props.date) {
      setSelectedDate(props.date);
    }
  }, [props.date]);

  const isoDate = useMemo(
    () => selectedDate?.toISOString() ?? null,
    [selectedDate]
  );

  return (
    <DatePicker
      value={isoDate}
      onDateChanged={onDateChanged}
      inputProps={{
        className: "ag-input-wrapper",
      }}
      datePickerProps={{
        slotProps: {
          popper: {
            className: "ag-custom-component-popup custom-date-filter",
            role: "presentation",
            ref: refContainer,
          },
        },
      }}
    />
  );
});

CustomDatepicker.displayName = "CustomDatepicker";
export default CustomDatepicker;
