import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: ${theme.spacing.xs};
  font-weight: ${theme.font.weight.normal};
  font-size: ${theme.font.size.caption};
  color: ${theme.color.gray[700]};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: ${theme.spacing.sm};
  svg {
    fill: ${theme.color.gray[700]};
    max-width: 14px;
    max-height: 14px;
  }
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xxs};
`;

export const Value = styled.div`
  color: ${theme.color.gray[700]};
  font-size: ${theme.font.size.caption};
  word-break: break-word;
`;
