import React from "react";
import { theme } from "theme";
import { Button } from "./styles";
import { CircularProgress } from "@mui/material";

export type CTAButtonProps = {
  variant?: "primary" | "secondary" | "tertiary";
  size?: "tiny" | "large" | "small" | "stretched" | "square";
  name?: string;
  icon?: JSX.Element | undefined | null;
  loading?: boolean;
  iconPosition?: "left" | "right";
  type?: "button" | "submit" | "reset" | undefined;
  label?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  color?: keyof typeof theme.color | "danger";
  hoverColor?: keyof typeof theme.color | "danger";
  form?: string;
  iconSize?: "tiny" | "small" | "medium" | "large" | undefined;
  children?: React.ReactNode;
};

const CTAButton = React.forwardRef<HTMLButtonElement, CTAButtonProps>(
  function CTAButton(
    {
      variant = "primary",
      size = "large",
      name,
      loading,
      icon,
      iconPosition = "left",
      type = "button",
      label,
      disabled,
      onClick,
      color,
      hoverColor,
      form,
      iconSize,
      children,
      ...props
    }: CTAButtonProps,
    ref
  ) {
    const body = (
      <>
        {loading ? (
          <CircularProgress size="1em" aria-label="button-loader" />
        ) : null}
        {!loading && !!icon ? icon : null}
        {name ? <span>{name}</span> : null}
        {children}
      </>
    );

    return (
      <Button
        ref={ref}
        {...props}
        type={type || "button"}
        aria-label={label || name}
        id={label} // @Todo added for backward compatibility. Use aria-label for testing instead.
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        color={color}
        hoverColor={hoverColor}
        size={size}
        iconPosition={iconPosition}
        form={form}
        iconSize={iconSize}
      >
        {body}
      </Button>
    );
  }
);

export default CTAButton;
