import styled from "@emotion/styled";
import { FormTextField } from "components";
import { breakpoints, theme } from "theme";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: var(--spacing-lg);
  max-width: 100%;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: 0 var(--spacing-lg);
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  justify-content: flex-start;
`;

export const CategoryButton = styled.button<{ active: boolean }>`
  cursor: pointer;
  border: none;
  width: 100%;
  padding: var(--spacing-md) var(--spacing-md);
  background-color: ${({ active }) =>
    active ? "var(--color-primary-active-subtle)" : "transparent"};
  border-radius: var(--button-border-radius);
  text-align: left;
  font-size: ${theme.font.size.body};
  transition: background-color 0.3s;
  :hover {
    background-color: var(--color-primary-hover-subtle);
  }
`;

export const Title = styled.div`
  position: relative;
  flex: 1 1 auto;
  span {
    position: relative;
    padding-right: var(--spacing-sm);
    background-color: var(--color-white);
    font-size: ${theme.font.size.caption};
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: var(--color-border-neutral);
  }
`;

export const SearchBar = styled(FormTextField)`
  max-width: 40%;
  min-width: 200px;
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: var(--spacing-lg);

  @media (max-width: ${breakpoints.xl}) {
    grid-template-columns: 1fr;
  }
`;
