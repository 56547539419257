import React from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { CTAButton, NewModal } from "components";
import { useDeleteContractTemplateTagMutation } from "shared/api/contract-templates";
import { useTeam } from "contexts/team/hooks";

type DeleteModalProps = {
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
  tag: { key: string; value: string };
  selectedTags: { key: string; value: string }[];
  setSelectedTags: (value: { key: string; value: string }[]) => void;
};

const DeleteTagModal = ({
  open,
  handleClose,
  tag: selectedTag,
  refetch,
  selectedTags,
  setSelectedTags,
}: DeleteModalProps) => {
  const { t } = useTranslation();
  const { organizationId } = useTeam();
  const deleteContractTemplateTagMutation =
    useDeleteContractTemplateTagMutation();

  const handleOnConfirm = async () => {
    try {
      await deleteContractTemplateTagMutation.mutateAsync({
        organizationId,
        tagId: selectedTag.key,
      });
      setSelectedTags(
        selectedTags.filter((tag) => tag.key !== selectedTag.key)
      );
      void refetch();
    } catch (error) {
      enqueueSnackbar(
        t("pages.settings.tabs.contractTemplates.tags.snackbars.deleteFailure"),
        { variant: "error" }
      );
    }

    handleClose();
  };

  return (
    <NewModal
      open={open}
      handleClose={handleClose}
      title={t("pages.settings.tabs.contractTemplates.tags.modal.title")}
      body={t("pages.settings.tabs.contractTemplates.tags.modal.description", {
        name: selectedTag.value,
      })}
      footer={
        <>
          <CTAButton
            name={t("common.buttons.cancel")}
            variant="secondary"
            onClick={handleClose}
          />
          <CTAButton
            name={t("common.buttons.delete")}
            variant="primary"
            color="danger"
            onClick={handleOnConfirm}
          />
        </>
      }
    />
  );
};

export default DeleteTagModal;
