import {
  ColDef,
  GridOptions,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";

import { TFunction } from "i18next";
import {
  CategoryDTO,
  ContractDTOV1,
  TeamDescriptionDto,
  TeamPermissionDto,
  TeamPermissionInfoDto,
} from "openapi";
import { Language } from "shared/i18n/i18n";
import { TagCellRenderer } from "./CellRenderers/TagCellRenderer";
import { getDateColumnDefinition } from "table/ColumnDefinitions/DateColumnDefinition.config";
import {
  defaultEnumFormatter,
  defaultValueFormatter,
  getCategory,
  getCellClass,
  getFilteredStatuses,
  getTeam,
  isContractEditable,
  isStatusEditable,
  sortByTagsCountAndName,
} from "./helpers";
import { ChipCellRenderer } from "./CellRenderers/ChipCellRenderer";
import { getContractTypeColor } from "constants/utils";
import { FileCellRenderer } from "./CellRenderers/FileCellRenderer";
import {
  VerifiedCellRenderer,
  VerifiedCountCellRenderer,
} from "./CellRenderers";
import { SelectCellEditor } from "./CellEditors/SelectCellEditor";
import { isNumber } from "mathjs";
import { countVerificationStatus } from "../Contract/helpers";

export const getGridOptions = (columnDefs: ColDef[]): GridOptions => {
  return {
    columnDefs,
    accentedSort: true,
    multiSortKey: "ctrl",
    rowSelection: "multiple",
    groupSelectsChildren: true,
    defaultColDef: {
      minWidth: 180,
      resizable: true,
      sortable: true,
      filter: "agSetColumnFilter",
      suppressHeaderMenuButton: true,
      comparator: sortAttachmentAlphabeticallyComparator,
      valueFormatter: defaultValueFormatter,
      filterParams: {
        valueFormatter: defaultValueFormatter,
      },
      enableValue: false,
      enableRowGroup: false,
      enablePivot: false,
      menuTabs: ["filterMenuTab"],
    },
    // groupIncludeTotalFooter: true,
  };
};

export const sortAttachmentAlphabeticallyComparator = (
  valueA?: string | number | null,
  valueB?: string | number | null,
  nodeA?: IRowNode<ContractDTOV1>,
  nodeB?: IRowNode<ContractDTOV1>
): number => {
  const contractA = nodeA?.data;
  const contractB = nodeB?.data;
  if (contractA?.parentId && contractB?.parentId) {
    return contractA.name
      ?.toLocaleLowerCase()
      .localeCompare(contractB.name?.toLocaleLowerCase());
  } else {
    if (!valueA) return -1;
    if (!valueB) return 1;
    //handle number sorting
    if (isNumber(valueA) && isNumber(valueB)) {
      return valueA - valueB;
    }
    return (valueA || "").toString().localeCompare((valueB || "").toString());
  }
};

export function getColumnDefs(
  t: TFunction<"translation">,
  locale: Language,
  categories: CategoryDTO[],
  teamById: (teamId?: string | undefined) => TeamDescriptionDto | null,
  hasSubteam: boolean,
  hasAiInsights: boolean,
  isLimitedUser: boolean,
  permissionSet: Record<string, TeamPermissionInfoDto> | undefined,
  hasWriteAccess: (categoryId: string, teamId: string) => boolean,
  userDateFormat: string
): ColDef<ContractDTOV1>[] {
  const dateColumnDefinition = getDateColumnDefinition(locale, userDateFormat);
  return [
    {
      field: "id",
      headerName: t("pages.contracts.tableColumns.id"),
      getQuickFilterText: () => "",
      hide: true,
    },
    {
      field: "unapprovedAnalysisValues",
      headerName: t("pages.contracts.tableColumns.aiInsights"),
      getQuickFilterText: () => "",
      cellRenderer: VerifiedCellRenderer,
      suppressSizeToFit: true,
      minWidth: 220,
      hide: true,
      suppressColumnsToolPanel: !hasAiInsights,
      filter: "agSetColumnFilter",
      filterParams: {
        values: [true, false],
        valueFormatter: (params: ValueFormatterParams) =>
          !params.value
            ? t("common.labels.verified")
            : t("common.labels.unverified"),
      },
    },
    {
      field: "verifiedFieldsCount" as keyof ContractDTOV1,
      headerName: t("pages.contracts.tableColumns.verifiedFieldsCount"),
      getQuickFilterText: () => "",
      cellRenderer: VerifiedCountCellRenderer,
      suppressSizeToFit: true,
      minWidth: 220,
      hide: true,
      suppressColumnsToolPanel: !hasAiInsights,
      comparator: (_valueA, _valueB, nodeA, nodeB) => {
        const countA = nodeA.data
          ? countVerificationStatus(nodeA.data)?.verified ?? 0
          : 0;
        const countB = nodeB.data
          ? countVerificationStatus(nodeB.data)?.verified ?? 0
          : 0;
        return countA - countB;
      },
      filter: "agNumberColumnFilter",
      filterValueGetter: (params) => {
        const data = params.data as ContractDTOV1;
        return countVerificationStatus(data)?.verified ?? 0;
      },
    },
    {
      field: "team" as keyof ContractDTOV1,
      headerName: t("pages.contracts.tableColumns.team"),
      hide: !hasSubteam,
      valueGetter: (params: ValueGetterParams) => {
        if (!params || !params.data) return;
        const teamId = (params.data as ContractDTOV1).teamId;
        const result = teamById(teamId);

        return result?.name ?? "-";
      },
      comparator: (valueA: string | null, valueB: string | null) => {
        if (!valueA) return -1;
        if (!valueB) return 1;
        return valueA.localeCompare(valueB);
      },
      filterValueGetter: (params) => {
        const data = params.data as ContractDTOV1;
        const team = getTeam(teamById, data.teamId);

        return team?.name || null;
      },
      filterParams: {
        comparator: (valueA: string | null, valueB: string | null) => {
          if (!valueA) return -1;
          if (!valueB) return 1;
          return valueA.localeCompare(valueB);
        },
      },
    },
    {
      field: "type",
      headerName: t("pages.contracts.tableColumns.type"),
      getQuickFilterText: () => "",
      cellRenderer: ChipCellRenderer,
      filterParams: {
        values: Object.values(ContractDTOV1.type),
        valueFormatter: (params: ValueFormatterParams) =>
          t(`contractType.${params.value as string}`),
      },
      cellRendererParams: (
        params: ICellRendererParams<ContractDTOV1, ContractDTOV1["type"]>
      ) => {
        return {
          color: getContractTypeColor(params.value as ContractDTOV1["type"]),
          value: params.value
            ? t(`contractType.${params.value as string}`)
            : "",
          blendMode: "soft",
        };
      },
    },
    {
      field: "tag" as keyof ContractDTOV1,
      headerName: t("pages.contracts.tableColumns.tag"),
      cellRenderer: TagCellRenderer,
      cellEditorPopup: true,
      comparator: sortByTagsCountAndName,
      filterValueGetter: (params) => {
        return (params.data as ContractDTOV1).tags.map((item) => item?.name);
      },
    },
    {
      field: "categoryId",
      headerName: t("pages.contracts.tableColumns.category"),
      cellRenderer: ChipCellRenderer,
      editable: isContractEditable(hasWriteAccess),
      cellRendererParams: (params: ICellRendererParams) => {
        if (
          !params ||
          !params.data ||
          !(params.data as ContractDTOV1).categoryId
        )
          return;
        const category = getCategory(
          categories,
          (params.data as ContractDTOV1).categoryId
        );
        return {
          color: category?.color,
          value: category?.name[locale],
          blendMode: "hard",
        };
      },
      cellEditor: SelectCellEditor,
      cellEditorParams: (params: ICellEditorParams) => {
        const contract = params.data as ContractDTOV1;
        const getSelectableCategories = () => {
          if (!isLimitedUser) {
            return categories?.filter((c) =>
              c.teams.includes(contract?.teamId)
            );
          }
          if (contract) {
            const categoryIds = permissionSet?.[contract.teamId]?.permissions
              ?.filter(
                (permission) =>
                  permission.level === TeamPermissionDto.level.WRITE
              )
              ?.map((item) => item.categoryId);
            return categories?.filter(
              (item) =>
                categoryIds?.includes(item.id) &&
                item.teams.includes(contract.teamId)
            );
          }
        };
        return {
          values: getSelectableCategories(),
          type: "category",
        };
      },
      comparator: (_valueA, _valueB, nodeA, nodeB, _isInverted) => {
        const categoryA = getCategory(
          categories,
          (nodeA.data as ContractDTOV1).categoryId
        );
        const categoryB = getCategory(
          categories,
          (nodeB.data as ContractDTOV1).categoryId
        );

        const getCategoryName = (category?: CategoryDTO) => {
          if (!category || !category.name) {
            return "";
          }
          return category.name[locale];
        };
        const categoryNameA = getCategoryName(categoryA);
        const categoryNameB = getCategoryName(categoryB);
        if (categoryNameA === categoryNameB) return 0;
        return categoryNameA?.toLowerCase() > categoryNameB?.toLowerCase()
          ? 1
          : -1;
      },
      filterValueGetter: (params) => {
        const data = params.data as ContractDTOV1;
        const category = getCategory(categories, data.categoryId);
        return category?.name[locale] || null;
      },
      filterParams: {
        comparator: (valueA: string | null, valueB: string | null) => {
          if (!valueA) return -1;
          if (!valueB) return 1;
          return valueA.localeCompare(valueB);
        },
      },
      cellClass: getCellClass(null, undefined),
    },
    {
      field: "status",
      headerName: t("common.status"),
      editable: ({ data }) =>
        isStatusEditable(data as ContractDTOV1, hasWriteAccess),
      hide: true,
      cellEditor: SelectCellEditor,
      cellEditorParams: {
        values: getFilteredStatuses(),
        translationKey: "status",
        type: "list",
      },
      valueFormatter: (params: ValueFormatterParams) =>
        defaultEnumFormatter(params, "enums.status", t),
      filterParams: {
        values: Object.values(ContractDTOV1.status),
        valueFormatter: (params: ValueFormatterParams) =>
          defaultEnumFormatter(params, "enums.status", t),
      },
      cellClass: getCellClass(null, undefined),
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      field: "createdAt" as keyof ContractDTOV1,
      headerName: t("pages.contracts.tableColumns.createdAt"),
      ...dateColumnDefinition,
      sort: "asc",
      hide: true,
      getQuickFilterText: () => "",
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      field: "updatedAt",
      headerName: t("pages.contracts.tableColumns.updatedAt"),
      ...dateColumnDefinition,
      hide: true,
      getQuickFilterText: () => "",
    },
    {
      field: "hasFile",
      headerName: t("pages.contracts.tableColumns.hasFile"),
      hide: true,
      cellRenderer: FileCellRenderer,
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => {
          return t(
            `pages.contracts.tableFilters.hasFile.${params.value as string}`
          );
        },
      },
    },

    {
      field: "tasksNumber",
      headerName: t("pages.contracts.tableColumns.tasksNumber"),
      hide: true,
      filter: "agNumberColumnFilter",
    },
  ];
}

export const getSidebarOptions = (t: TFunction<"translation", undefined>) => {
  return {
    toolPanels: [
      {
        id: "columns",
        labelDefault: t("pages.contracts.sidebar.title"),
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: false,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  };
};
