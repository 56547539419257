/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ViewInputDTO = {
  type: ViewInputDTO.type;
  name: string;
  data: string;
  filter: string | null;
  shared: boolean;
  default?: boolean;
};

export namespace ViewInputDTO {
  export enum type {
    CONTRACT = "CONTRACT",
    CONTACT = "CONTACT",
    TASK = "TASK",
  }
}
