/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContractDatapointMetadata } from "./ContractDatapointMetadata";

export type DurationFieldTypeDtoV1 = {
  metadata?: Record<string, ContractDatapointMetadata> | null;
  type?: DurationFieldTypeDtoV1.type | null;
  startDate?: string | null;
  endDate?: string | null;
  interval?: number | null;
  terminationDate?: string | null;
  noticePeriod?: number | null;
  automaticRenewal?: number | null;
  annotationData?: Record<string, any> | null;
  analysisData?: Record<string, any> | null;
};

export namespace DurationFieldTypeDtoV1 {
  export enum type {
    FIXED_TERM = "FIXED_TERM",
    AUTOMATIC_RENEWAL = "AUTOMATIC_RENEWAL",
    INDEFINITE_DURATION = "INDEFINITE_DURATION",
  }
}
