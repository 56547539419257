import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
`;

export const Title = styled.div`
  color: var(--color-text-primary);
  margin-bottom: var(--spacing-sm);
`;

export const Content = styled.div`
  color: var(--color-text-secondary);
`;
