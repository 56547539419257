/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ViewUpdateDTO = {
  type?: ViewUpdateDTO.type;
  name?: string;
  data?: string;
  filter?: string | null;
  shared?: boolean;
  default?: boolean;
};

export namespace ViewUpdateDTO {
  export enum type {
    CONTRACT = "CONTRACT",
    CONTACT = "CONTACT",
    TASK = "TASK",
  }
}
