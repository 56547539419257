import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Tooltip } from "@mui/material";
import { ContractAttachmentListItemDTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { getNavigationToContractViewById } from "constants/utils";
import { NewModal, CTAButton } from "components";
import { ListItem } from "new-components/DocumentListItem/DocumentListItem";
import { useContractQuery } from "shared/api";
import { BinIcon } from "../../styles";
import {
  Body,
  BodyColumn,
  Description,
  DocumentType,
  Title,
  AttachmentsCount,
  AttachmentList,
  AttachmentItem,
  SingleFile,
  MultipleFiles,
  DeleteIcon,
} from "./styles";
import { useDeleteContractDeleteMutation } from "../../../../../../shared/api/contracts";

type Props = {
  listItem: ListItem;
  contractGroup?: ContractAttachmentListItemDTO[];
  refetchOnDelete?: () => void;
  buttonType?: "dropdown";
};

const DeleteContract = ({
  listItem,
  contractGroup,
  refetchOnDelete,
  buttonType,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { hasWriteAccess, selectedTeamId } = useTeam();
  const [showDeleteContractModal, setShowDeleteContractModal] = useState(false);
  const { mutateAsync: deleteContract } = useDeleteContractDeleteMutation();
  const { data: contract } = useContractQuery(selectedTeamId, listItem.id);

  const handleOnClose = () => setShowDeleteContractModal(false);

  const handleOnDelete = async () => {
    if (!listItem.id) return;

    enqueueSnackbar(
      t("pages.contracts.messages.successfulDeletion", {
        contractName: listItem?.name,
      }),
      { variant: "success" }
    );

    let contractId = listItem.parentId ?? undefined;

    if (contractId) {
      if (
        !contractGroup?.find(
          (contractGroupItem) => contractId === contractGroupItem.id
        )
      ) {
        contractId = contractGroup?.find(
          (contractGroupItem) => listItem.id !== contractGroupItem.id
        )?.id;
      }
    }

    navigate(getNavigationToContractViewById(contractId), {
      state: { deleted: true, contractName: listItem?.name },
    });
    await deleteContract({ contractId: listItem.id, teamId: selectedTeamId });

    if (refetchOnDelete) refetchOnDelete();

    handleOnClose();
  };

  const hasAccess = hasWriteAccess(listItem?.categoryId, contract?.teamId);

  if (!hasAccess || !listItem.id) return null;

  const mainContract = contractGroup?.find((doc) => !doc.parentId);
  const isMainContract = mainContract?.id === listItem?.id;
  const attachments = contractGroup?.filter((doc) => doc.parentId);
  const isContractWithAttachments = attachments && attachments.length > 0;

  const modalTitle = () => {
    let title = t("pages.contracts.modals.delete.title.attachment");

    if (isMainContract && isContractWithAttachments) {
      title = t("pages.contracts.modals.delete.title.contractGroup");
    }

    if (isMainContract && !isContractWithAttachments) {
      title = t("pages.contracts.modals.delete.title.contract");
    }

    return title;
  };

  const confirmDeletionButtonLabel = () => {
    let label = t("pages.contracts.modals.delete.submit.attachment");

    if (isMainContract && isContractWithAttachments) {
      label = t("pages.contracts.modals.delete.submit.contractGroup");
    }

    if (isMainContract && !isContractWithAttachments) {
      label = t("pages.contracts.modals.delete.submit.contract");
    }

    return label;
  };

  const getDescription = () => {
    let description = t("pages.contracts.modals.delete.description.attachment");

    if (isMainContract && isContractWithAttachments) {
      description = t(
        "pages.contracts.modals.delete.description.contractGroup"
      );
    }

    if (isMainContract && !isContractWithAttachments) {
      description = t("pages.contracts.modals.delete.description.contract");
    }

    return description;
  };

  const visual =
    isMainContract && isContractWithAttachments ? (
      <MultipleFiles />
    ) : (
      <SingleFile />
    );
  const title = modalTitle();
  const description = getDescription();
  const docType = listItem?.parentId
    ? t("contractType.ATTACHMENT")
    : t("contractType.CONTRACT");
  const deleteButtonLabel = confirmDeletionButtonLabel();

  return (
    <>
      {buttonType === "dropdown" ? (
        <CTAButton
          variant="tertiary"
          label="deleteOptionBtn"
          onClick={() => setShowDeleteContractModal(true)}
          icon={<DeleteIcon />}
          name={t("pages.contractDetails.buttons.deleteContract")}
          color="danger"
          iconSize="medium"
        />
      ) : (
        <Tooltip placement="top" title={t("common.buttons.delete")}>
          <CTAButton
            variant="tertiary"
            icon={<BinIcon />}
            iconSize="medium"
            label="deleteBtn"
            onClick={() => setShowDeleteContractModal(true)}
          />
        </Tooltip>
      )}

      <NewModal
        open={showDeleteContractModal}
        handleClose={() => setShowDeleteContractModal(false)}
        title={title}
        fullWidth
        body={
          <Body>
            <BodyColumn>{visual}</BodyColumn>

            <BodyColumn>
              <Description>{description}</Description>

              <DocumentType>{docType}</DocumentType>

              <Title>{listItem?.name}</Title>

              {isContractWithAttachments && isMainContract && (
                <>
                  <AttachmentsCount>
                    {t("pages.contracts.modals.delete.attachments", {
                      count: attachments.length,
                    })}
                  </AttachmentsCount>

                  <AttachmentList>
                    {attachments.map((attachment) => (
                      <AttachmentItem key={attachment.id}>
                        {attachment.name}
                      </AttachmentItem>
                    ))}
                  </AttachmentList>
                </>
              )}
            </BodyColumn>
          </Body>
        }
        footer={
          <>
            <CTAButton
              onClick={handleOnClose}
              variant="secondary"
              name={t("common.buttons.cancel")}
            />
            <CTAButton
              /**
               * @Note: Raise a flag to discuss whether we want to use a label as aria-label for accessibility
               * since we want uniqueness for our aria labels.
               * */
              label="deleteContractConfirmationButton"
              onClick={handleOnDelete}
              variant="primary"
              color="danger"
              name={deleteButtonLabel}
            />
          </>
        }
      />
    </>
  );
};

export default DeleteContract;
