/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FederatedIdentityRegisterDTO } from "../models/FederatedIdentityRegisterDTO";
import type { LimitedAuthMethodsDTO } from "../models/LimitedAuthMethodsDTO";
import type { LoginDto } from "../models/LoginDto";
import type { ProviderAuthData } from "../models/ProviderAuthData";
import type { RegistrationResponseDto } from "../models/RegistrationResponseDto";
import type { TokenResponseDto } from "../models/TokenResponseDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AuthService {
  /**
   * Login
   * @param requestBody
   * @returns TokenResponseDto
   * @throws ApiError
   */
  public static login(
    requestBody: LoginDto
  ): CancelablePromise<TokenResponseDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/auth/login",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Takes authorization code for an OAuth provider
   * @param provider
   * @param requestBody
   * @returns TokenResponseDto
   * @throws ApiError
   */
  public static handleOAuthAuthorizationCodeForLogin(
    provider: "google" | "microsoft" | "oidc",
    requestBody: ProviderAuthData
  ): CancelablePromise<TokenResponseDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/auth/providers/{provider}/login",
      path: {
        provider: provider,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Takes authorization code for an OAuth provider
   * @param provider
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static handleOAuthAuthorizationCodeForRegistration(
    provider: "google" | "microsoft" | "oidc",
    requestBody: FederatedIdentityRegisterDTO
  ): CancelablePromise<RegistrationResponseDto | TokenResponseDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/auth/providers/{provider}/register",
      path: {
        provider: provider,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Logout
   * @returns any
   * @throws ApiError
   */
  public static logout(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/auth/logout",
    });
  }

  /**
   * Generate tokens to impersonate a user
   * @param id
   * @returns TokenResponseDto
   * @throws ApiError
   */
  public static impersonate(id: string): CancelablePromise<TokenResponseDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/auth/impersonate/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * Return back to admin
   * @returns TokenResponseDto
   * @throws ApiError
   */
  public static exitImpersonation(): CancelablePromise<TokenResponseDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/auth/exit-impersonation",
    });
  }

  /**
   * @param hostname
   * @returns LimitedAuthMethodsDTO
   * @throws ApiError
   */
  public static getAuthMethodsForTeam(
    hostname: string
  ): CancelablePromise<LimitedAuthMethodsDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/auth/methods/{hostname}",
      path: {
        hostname: hostname,
      },
    });
  }

  /**
   * @param hostname
   * @returns any
   * @throws ApiError
   */
  public static getOpenIdConfigurationForTeam(
    hostname: string
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/auth/methods/{hostname}/.well-known/openid-configuration",
      path: {
        hostname: hostname,
      },
    });
  }
}
