import React, { useEffect, useState } from "react";
import { IconButton, Select } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useOverviewState } from "contexts/grid/hooks";
import { ViewItemDTO } from "openapi";
import {
  persistTemporarySearch,
  retrieveTemporarySearch,
} from "contexts/grid/storage";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useTeam } from "contexts/team/hooks";
import { Features } from "constants/features";
import ListIcon from "assets/svg/list-icon.svg?react";
import OCRIcon from "assets/svg/scan-text.svg?react";
import { ListItem, PaperStyles, SearchControl, TextField } from "./styles";
import {
  SEARCH_TYPES,
  SelectSearchTypeChangedEvent,
  useFullTextSearch,
} from "hooks/useFullTextSearch";

type Props = {
  label: string;
};

const pathMapping = {
  [ViewItemDTO.type.CONTRACT]: "contracts",
  [ViewItemDTO.type.TASK]: "tasks",
  [ViewItemDTO.type.CONTACT]: "contacts",
};

export const EntityGridSearchInput = ({ label }: Props) => {
  const { overview } = useOverviewState();
  const location = useLocation();
  const { t } = useTranslation();
  const { hasFeature } = useTeam();
  const path = location.pathname.split("/")[1];
  const isNestedOverview = path !== pathMapping[overview.type];
  const { handleSearchTypeChange, searchType } = useFullTextSearch();

  const showSearchSwitcher =
    hasFeature(Features.FULL_TEXT_SEARCH) &&
    path === pathMapping[ViewItemDTO.type.CONTRACT];

  const [search, setSearch] = useState(
    overview.activePreset || isNestedOverview
      ? ""
      : retrieveTemporarySearch(overview.type)
  );

  const isPathContract = path === pathMapping[ViewItemDTO.type.CONTRACT];

  const onSearchTypeChange = (event: SelectSearchTypeChangedEvent) => {
    if (isPathContract) handleSearchTypeChange(event);

    if (!overview.agGrid.gridRef) {
      return;
    }
    if (event.target.value === SEARCH_TYPES.TEXT && isPathContract) {
      overview.agGrid.gridRef.current?.api.setGridOption(
        "quickFilterText",
        search
      );
    } else {
      overview.agGrid.gridRef.current?.api.setGridOption(
        "quickFilterText",
        search
      );
    }
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (overview.agGrid.initialized) {
        if (!isPathContract || searchType === SEARCH_TYPES.LIST) {
          overview.agGrid.gridRef.current?.api.setGridOption(
            "quickFilterText",
            search
          );
        }
        if (!overview.activePreset && !isNestedOverview) {
          persistTemporarySearch(overview.type, search);
        }
      }
    }, 350);

    return () => clearTimeout(debounceTimeout);
  }, [search]);

  return (
    <TextField
      margin="none"
      size="small"
      name="search"
      label={label}
      value={search}
      onChange={(e) => {
        setSearch(e.currentTarget.value);
      }}
      InputProps={{
        endAdornment: (
          <>
            {search !== "" && (
              <IconButton onClick={() => setSearch("")} aria-label="clear">
                <ClearIcon />
              </IconButton>
            )}
            {showSearchSwitcher && (
              <SearchControl id="searchTypeSwitch">
                <Select
                  aria-label="search-type-select"
                  value={searchType}
                  onChange={onSearchTypeChange}
                  size="small"
                  MenuProps={{
                    PaperProps: {
                      sx: PaperStyles,
                    },
                  }}
                >
                  <ListItem value={SEARCH_TYPES.LIST}>
                    <ListIcon />
                    <span>{t("pages.contracts.SEARCH_TYPES.LIST")}</span>
                  </ListItem>
                  <ListItem value={SEARCH_TYPES.TEXT}>
                    <OCRIcon />
                    <span>{t("pages.contracts.SEARCH_TYPES.TEXT")}</span>
                  </ListItem>
                </Select>
              </SearchControl>
            )}
          </>
        ),
      }}
    />
  );
};
