import React from "react";
import { ICellRendererParams } from "ag-grid-community";
import { ContractDTOV1 } from "openapi";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import routePaths from "constants/routePaths";
import {
  ButtonGroupContainer,
  CellContainer,
  ChildrenCount,
  IconContainer,
  NameLink,
} from "./styles";
import DraftIcon from "assets/svg/status/draft.svg?react";
import ActiveIcon from "assets/svg/status/active.svg?react";
import ProcessingIcon from "assets/svg/status/analysing.svg?react";
import SigningIcon from "assets/svg/status/signing.svg?react";
import CancelledIcon from "assets/svg/status/cancelled.svg?react";
import TerminatedIcon from "assets/svg/status/terminated.svg?react";
import InactiveIcon from "assets/svg/status/inactive.svg?react";
import { retrieveTemporarySearch } from "contexts/grid/storage";
import { useOverviewState } from "contexts/grid/hooks";
import OCRIcon from "assets/svg/scan-text.svg?react";
import { SearchType, SEARCH_TYPES } from "hooks/useFullTextSearch";

const StatusIconMap: Record<ContractDTOV1.status, React.FunctionComponent> = {
  DRAFT: DraftIcon,
  SIGNING: SigningIcon,
  PROCESSING: ProcessingIcon,
  ACTIVE: ActiveIcon,
  CANCELLED: CancelledIcon,
  TERMINATED: TerminatedIcon,
  INACTIVE: InactiveIcon,
};

export const NameCellRenderer = ({
  data,
  node,
  searchType,
}: ICellRendererParams<ContractDTOV1> & {
  searchType: SearchType;
}) => {
  const { t } = useTranslation();
  const { overview } = useOverviewState();

  if (!data?.name) {
    return null;
  }

  const childrenCount = node.allChildrenCount ?? 0;

  const path = `${routePaths.CONTRACTS}/${data.id.toString()}`;
  const name = data.name.replaceAll("_", " ");

  const showOcrIcon =
    searchType === SEARCH_TYPES.TEXT &&
    retrieveTemporarySearch(overview.type).length > 0;

  const hasChildren = childrenCount > 0;
  return (
    <Tooltip title={t(`enums.status.${data.status}`)} className="editable-cell">
      <CellContainer>
        <IconContainer>
          {React.createElement(StatusIconMap[data.status])}
        </IconContainer>
        <NameLink className="hover-underline font-semibold" to={path}>
          {name}
        </NameLink>
        {(hasChildren || showOcrIcon) && (
          <ButtonGroupContainer>
            {showOcrIcon && (
              <OCRIcon
                fill="var(--color-text-primary)"
                height="20px"
                width="20px"
                style={{ minHeight: "20px", minWidth: "20px" }}
              />
            )}
            {hasChildren && <ChildrenCount>{childrenCount}</ChildrenCount>}
          </ButtonGroupContainer>
        )}
      </CellContainer>
    </Tooltip>
  );
};
