import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useTeam } from "contexts/team/hooks";
import { useTeamMemberQuery } from "shared/api/team-members";
import { ButtonWithArrow, Loader, NewModal, SectionHeader } from "components";
import routePaths from "constants/routePaths";
import RemoveTeamMember from "../Organization/components/TeamMembers/DeleteTeamMember";
import Roles from "./Roles/Roles";
import {
  Card,
  Caption,
  IconWrapper,
  UserIcon,
  TitleContent,
  BackButton,
  Name,
} from "./styles";

const Member = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { t } = useTranslation();
  const { selectedTeamId } = useTeam();
  const { id: memberId } = useParams();
  const navigate = useNavigate();

  const { data: member, isLoading } = useTeamMemberQuery(
    selectedTeamId,
    memberId
  );

  const onBack = () => {
    navigate(routePaths.SETTINGS_MEMBERS);
  };

  if (isLoading) return <Loader />;

  if (!member) return null;

  const fullName = `${member.firstname as string} ${member.lastname as string}`;

  return (
    <>
      <>
        <BackButton>
          <ButtonWithArrow
            onClick={onBack}
            label={t("pages.settings.tabs.team.teamMembers.details.goBack")}
          />
        </BackButton>

        <Card>
          <TitleContent>
            <IconWrapper>
              <UserIcon />
            </IconWrapper>

            {t("pages.settings.tabs.team.teamMembers.details.title")}
          </TitleContent>

          <Caption>
            {t("pages.settings.tabs.team.teamMembers.details.name")}
          </Caption>

          <Name>{fullName}</Name>
        </Card>
        <Roles member={member} teamId={selectedTeamId} />

        <Card>
          <SectionHeader
            title={t(
              "pages.settings.tabs.team.teamMembers.modals.delete.deleteHeader"
            )}
            baseButtonText={t(
              "pages.settings.tabs.team.teamMembers.modals.delete.deleteHeader"
            )}
            onButtonClick={() => {
              setShowDeleteModal(true);
            }}
            noPadding
            buttonVariant="secondary"
            buttonColor="danger"
            buttonId="member-delete-button"
          />
          <p>
            {t("pages.settings.tabs.team.teamMembers.modals.delete.subtitle")}
          </p>
        </Card>
      </>

      <NewModal
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        title={t(
          "pages.settings.tabs.team.teamMembers.modals.delete.description"
        )}
        body={
          <RemoveTeamMember
            open={showDeleteModal}
            resetSelectedMemberIndex={onBack}
            handleClose={() => setShowDeleteModal(false)}
            teamMemberFullName={fullName}
            selectedTeamId={selectedTeamId}
            selectedMemberId={memberId}
          />
        }
        maxWidth="sm"
      />
    </>
  );
};

export default Member;
