import React, { useEffect, useMemo, useState } from "react";
import {
  FormProvider,
  useForm,
  UseFormSetError,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AdminService,
  ContactDTO,
  ContactFieldTypeDTOV1,
  ContractFieldDTOV1,
  ContractFieldLimitedDTOV1,
  PendingContractDto,
  PendingContractUpdateDto,
} from "openapi";
import { FormTextField, MessageBox } from "components";
import {
  FormSelect,
  FormSelectItem,
} from "../../../../components/FormItems/FormSelect/FormSelect";
import { Box, Button, Grid } from "@mui/material";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import DownloadIcon from "@mui/icons-material/Download";
import { ModalResultEnum } from "components/Modal/Modal";
import { convertContractName } from "constants/utils";
import saveAs from "file-saver";
import styled from "@emotion/styled";
import { ContractCategoryDto } from "openapi/models/ContractCategoryDto";
import { Block } from "aws-sdk/clients/textract";
import { theme } from "theme";
import AnnotationMenu, { AnnotationData } from "./AnnotationMenu";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import { AnalysisFieldData } from "./AnalysisWrapper";
import {
  parseAmount,
  parseCurrency,
  parseDate,
  parseDurationType,
  parseInterval,
  parsePaymentCycle,
  parsePaymentType,
  parsePaymentMethod,
} from "@contracthero/common";
import { DataPoint } from "components/Datapoints/DataPoint";
import { FormCheckbox } from "components/FormItems/FormCheckbox/FormCheckbox";
import { PDFViewerActionsProvider } from "components/PDFViewer/PDFViewerActionContext";
import { PDFViewer } from "components/PDFViewer/PDFViewer";
import CircularLoading from "components/CircularLoading/CircularLoading";
import { initializeContractFormFieldsData } from "pages/Contract/components/ContractData/helpers";
import { setValidationErrors } from "shared/service/errorResponseService";

const TitleWrapper = styled.h3`
  line-height: 1.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: #1a202c;
  margin: 0;
  margin-bottom: 0.5rem;
`;

type Props = {
  id?: string;
  handleClose: (action?: ModalResultEnum | undefined, values?: unknown) => void;
  refetch: () => Promise<void>;
};

const getInitialValues = (): PendingContractUpdateDto => {
  return {
    name: "",
    categoryId: "",
    analyzedBadly: false,
    fields: {},
  };
};

const Outter = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: ${theme.spacing.md};
`;

const EditPendingContract = ({ id, handleClose, refetch }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<PendingContractUpdateDto>({
    defaultValues: getInitialValues(),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { dirtyFields },
  } = methods;

  const buttonClasses = useStyles();
  const [fileURL, setFileURL] = useState<string | null>(null);
  const [categoryOptions, setCategoryOptions] = useState<FormSelectItem[]>([]);
  const [pendingContractData, setPendingContractData] =
    useState<PendingContractDto>();
  const [ocrData, setOCRData] = useState<Block[]>();

  const [analysisScore, setAnalysisScore] = useState<number>(0);

  const [annotationData, setAnnotationData] = useState<AnnotationData>();
  const { enqueueSnackbar } = useSnackbar();

  const [contactsOfCategory, setContactsOfCategory] = useState<ContactDTO[]>(
    []
  );

  const currentCategoryId = useWatch({
    control,
    name: "categoryId",
  });

  useEffect(() => {
    if (!currentCategoryId) {
      setContactsOfCategory([]);
      return;
    }
    void AdminService.getContactsOfCategory(currentCategoryId).then(
      (contacts) => setContactsOfCategory(contacts)
    );
  }, [currentCategoryId]);

  const analysisCategory = useMemo(() => {
    if (!pendingContractData) {
      return null;
    }

    const fieldsByVisibleId = new Map<string, ContractFieldDTOV1>();
    for (const field of pendingContractData.fieldDefinitions) {
      if (field.visibleId) {
        fieldsByVisibleId.set(field.visibleId, field);
      }
    }

    const buildFieldsArrayFromVisibleIds = (
      ids: string[]
    ): ContractFieldLimitedDTOV1[] => {
      return (
        ids
          .filter((id) => fieldsByVisibleId.has(id))
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((id) => ({ id: fieldsByVisibleId.get(id)!.id }))
      );
    };

    return {
      id: "analysis_category",
      name: "[INTERNAL] Analysis Category",
      color: "#000000",
      default: false,
      teamId: pendingContractData.category.teamId,
      sections: [
        {
          id: "analysis_section",
          name: {
            de: "Analysedaten",
            en: "Analysis Data",
          },
          fields: buildFieldsArrayFromVisibleIds([
            "partnerCompany",
            "duration",
            "paymentCycle",
            "paymentPriceNet",
            "paymentTax",
            "paymentType",
            "iban",
            "bic",
            "paymentMethod",
            "contractNumber",
            "orderNumber",
            "deposit",
          ]),
        },
      ],
    };
  }, [pendingContractData]);

  useEffect(() => {
    if (id) {
      AdminService.getPendingContract(id)
        .then((contract) => {
          setPendingContractData({
            ...contract,
            fields: initializeContractFormFieldsData(
              contract.category,
              contract.fieldDefinitions,
              contract.fields
            ),
          });
        })
        .catch((e) => {
          enqueueSnackbar("Bad request", {
            variant: "error",
          });
          handleClose();
          console.error(e);
        });
      AdminService.getOcrForPendingContract(id)
        .then((ocr) => {
          setOCRData((ocr as Block[]) || []);
        })
        .catch((e) => {
          enqueueSnackbar("Bad request", {
            variant: "error",
          });
          console.error(e);
        });
    }
  }, [id]);

  const fillCategories = (categoriesData: ContractCategoryDto[]) => {
    setCategoryOptions(
      categoriesData.map((item) => {
        return {
          key: item.id,
          value: item.name,
        };
      })
    );
  };

  const openPdfFullscreen = () => {
    if (fileURL) {
      window.open(fileURL);
    }
  };

  useEffect(() => {
    if (pendingContractData) {
      setFileURL(`/api/admin/download/${pendingContractData?.id}`);
    }
  }, [pendingContractData]);

  const downloadPdfFile = () => {
    if (fileURL) {
      saveAs(fileURL, convertContractName(pendingContractData?.name || ""));
    }
  };

  const getField = (visibleId: string) => {
    if (!pendingContractData?.fieldDefinitions) {
      return;
    }
    const field = pendingContractData?.fieldDefinitions.find(
      (field) => field.visibleId === visibleId
    );

    return field ?? null;
  };

  function calculateAnalysisScore() {
    let score = 0;
    let count = 0;
    if (pendingContractData) {
      for (const fieldName of Object.keys(pendingContractData.fields)) {
        const field = pendingContractData?.fields[fieldName];
        if (!field?.analysisData) {
          continue;
        }
        const analysisData = field.analysisData as Record<
          string,
          AnalysisFieldData
        >;
        for (const analysisFieldName of Object.keys(analysisData)) {
          const analysisField = analysisData[analysisFieldName];
          if (analysisField) {
            count++;
            score += analysisField.Score;
          }
        }
      }
    }
    if (count > 0) {
      setAnalysisScore(score / count);
    }
  }

  useEffect(() => {
    if (pendingContractData) {
      calculateAnalysisScore();

      reset(pendingContractData);
      fillCategories(pendingContractData.categories);
    }
  }, [pendingContractData]);

  const onSubmit = async (values: PendingContractUpdateDto) => {
    if (pendingContractData) {
      const dirtyDatapoints = dirtyFields.fields ?? {};
      for (const key of Object.keys(values.fields)) {
        if (!(key in dirtyDatapoints)) {
          delete values.fields[key];
        }
      }

      /**
       * When removing the partner company the value is send as `null` which is not acceptable for the backend
       * filtering out this case manually.
       */
      const fields = structuredClone(values.fields);
      const partnerCompanyDefinition =
        pendingContractData.fieldDefinitions.find(
          (definition) => definition.visibleId === "partnerCompany"
        );
      if (partnerCompanyDefinition) {
        const partnerField = fields[
          partnerCompanyDefinition.id
        ] as ContactFieldTypeDTOV1;
        if (
          partnerField &&
          partnerField.value == null &&
          partnerField.$create == null
        ) {
          delete fields[partnerCompanyDefinition.id];
        }

        if (partnerField && partnerField.$create) {
          const existingPartner = contactsOfCategory.find((contact) => {
            const contactCasted = contact as unknown as {
              name: { value: { value: string } };
            };
            const create = partnerField.$create as unknown as {
              name: { value: { value: string } };
            };
            const equal =
              contactCasted.name.value.value === create.name.value.value;
            return equal;
          });
          if (existingPartner) {
            fields[partnerCompanyDefinition.id] = { value: existingPartner.id };
          }
        }
      }

      try {
        await AdminService.updateContract(pendingContractData.id, {
          name: values.name,
          categoryId: values.categoryId,
          fields: fields,
          analyzedBadly: values.analyzedBadly,
        });

        handleClose();
        await refetch();
      } catch (e) {
        const errorConverter: UseFormSetError<never> = (name, error) => {
          methods.setError(name, error);
        };
        setValidationErrors(
          e,
          errorConverter,
          "pages.contractEdit.forms.generalForm",
          undefined,
          enqueueSnackbar,
          t
        );
      }
    }
  };

  function handleSelection(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    const selection = window.getSelection();
    if (!selection) {
      return;
    }
    const selectedText = selection.toString();
    if (!selectedText) {
      return;
    }
    if (selection.isCollapsed) {
      return;
    }
    if (!pendingContractData) {
      return;
    }
    if (!selection.anchorNode) {
      return;
    }
    if (!selection.focusNode) {
      return;
    }

    const anchor = selection.anchorNode?.parentElement;
    const anchorBlockId = anchor?.dataset.blockId;
    const anchorOffset = selection.anchorOffset;
    const focus = selection.focusNode?.parentElement;
    const focusBlockId = focus?.dataset.blockId;
    const focusOffset = selection.focusOffset;
    if (!anchorBlockId) {
      console.error("No anchorBlockIndex");
      return;
    }
    if (!focusBlockId) {
      console.error("No focusBlockIndex");
      return;
    }

    const range = document.createRange();
    range.setStart(selection.anchorNode, selection.anchorOffset);
    range.setEnd(selection.focusNode, selection.focusOffset);
    const forward = !range.collapsed;

    const annotationData = {
      contractId: pendingContractData.id,
      text: selectedText,
      startBlockId: forward ? anchorBlockId : focusBlockId,
      startOffset: forward ? anchorOffset : focusOffset,
      left: e.clientX,
      top: e.clientY,
      ref: e.target as Element,
      contextMenuVisible: true,
    };
    setAnnotationData(annotationData);
  }

  const fieldHandler: Record<
    string,
    Record<
      string,
      {
        fieldName:
          | "partnerCompany"
          | "durationType"
          | "durationStartAt"
          | "durationEndAt"
          | "durationInterval"
          | "durationCancellationAt"
          | "durationCancellation"
          | "durationRenewal"
          | "paymentCycle"
          | "paymentPrice"
          | "paymentCurrencyCode"
          | "paymentTax"
          | "paymentType"
          | "iban"
          | "bic"
          | "paymentMethod"
          | "contractNumber"
          | "orderNumber"
          | "depositPrice"
          | "depositCurrencyCode";
        converter: (text: string) => string | number | undefined;
      }
    >
  > = {
    partnerCompany: {
      value: {
        fieldName: "partnerCompany",
        converter: (text: string) => text.replaceAll("\n", " "),
      },
    },
    duration: {
      type: {
        fieldName: "durationType",
        converter: parseDurationType,
      },
      startDate: {
        fieldName: "durationStartAt",
        converter: parseDate,
      },
      endDate: {
        fieldName: "durationEndAt",
        converter: parseDate,
      },
      interval: {
        fieldName: "durationInterval",
        converter: parseInterval,
      },
      terminationDate: {
        fieldName: "durationCancellationAt",
        converter: parseDate,
      },
      noticePeriod: {
        fieldName: "durationCancellation",
        converter: parseInterval,
      },
      automaticRenewal: {
        fieldName: "durationRenewal",
        converter: parseInterval,
      },
    },
    paymentCycle: {
      value: {
        fieldName: "paymentCycle",
        converter: parsePaymentCycle,
      },
    },
    paymentPriceNet: {
      value: {
        fieldName: "paymentPrice",
        converter: parseAmount,
      },
      currency: {
        fieldName: "paymentCurrencyCode",
        converter: parseCurrency,
      },
    },
    paymentTax: {
      value: {
        fieldName: "paymentTax",
        converter: parseAmount,
      },
    },
    paymentType: {
      value: {
        fieldName: "paymentType",
        converter: parsePaymentType,
      },
    },
    iban: {
      value: {
        fieldName: "iban",
        converter: (text: string) => text.replaceAll("\n", " "),
      },
    },
    bic: {
      value: {
        fieldName: "bic",
        converter: (text: string) => text.replaceAll("\n", " "),
      },
    },
    paymentMethod: {
      value: {
        fieldName: "paymentMethod",
        converter: parsePaymentMethod,
      },
    },
    contractNumber: {
      value: {
        fieldName: "contractNumber",
        converter: (text: string) => text.replaceAll("\n", " "),
      },
    },
    orderNumber: {
      value: {
        fieldName: "orderNumber",
        converter: (text: string) => text.replaceAll("\n", " "),
      },
    },
    deposit: {
      value: {
        fieldName: "depositPrice",
        converter: parseAmount,
      },
      currency: {
        fieldName: "depositCurrencyCode",
        converter: parseCurrency,
      },
    },
  };

  function addAnnotationData(fieldName?: string, fieldKey?: string) {
    if (!fieldName || !fieldKey) {
      setAnnotationData(undefined);
      return;
    }
    const field = getField(fieldName);
    if (!field) {
      Sentry.captureMessage(
        `Annotating contract: Couldn't find field: ${fieldName}`
      );
      return;
    }
    if (!annotationData) {
      console.log("No annotation data");
      return;
    }

    const handler = fieldHandler[fieldName][fieldKey];
    let value;
    try {
      value = handler.converter(annotationData.text);
    } catch (e) {
      Sentry.captureException(e);
    }
    if (!value) {
      return;
    }

    if (field.type === ContractFieldDTOV1.type.CONTACT) {
      setValue(
        `fields.${field.id}.$create.typeId`,
        pendingContractData?.contactTypes[0].id as never,
        {
          shouldDirty: true,
        }
      );

      setValue(
        `fields.${field.id}.$create.name.value.value`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value,
        {
          shouldDirty: true,
        }
      );

      setValue(`fields.${field.id}.value`, null);
    } else {
      setValue(`fields.${field.id}.${fieldKey}`, value as never, {
        shouldDirty: true,
      });
    }

    // handler.fieldName
    setValue("analyzedBadly", true, {
      shouldDirty: true,
    });
    AdminService.setAnnotationData(annotationData.contractId, field.id, {
      [fieldKey]: {
        startBlockId: annotationData.startBlockId,
        startOffset: annotationData.startOffset,
        text: annotationData.text,
      },
    }).catch((e) => console.error(e));
    setAnnotationData(undefined);
  }

  if (!pendingContractData) {
    return <CircularLoading isLoading />;
  }

  const autosaveChecks = pendingContractData.autosave?.checks;
  return (
    <Outter onMouseUp={() => setAnnotationData(undefined)}>
      <PDFViewerActionsProvider>
        <div>
          <TitleWrapper>
            {`Contract: ${pendingContractData?.name}`}
          </TitleWrapper>
          <Box sx={{ display: "flex" }}>
            {autosaveChecks &&
              Object.keys(autosaveChecks)
                .filter(
                  (key) =>
                    !(autosaveChecks as Record<string, { valid: boolean }>)[key]
                      .valid
                )
                .map((key) => {
                  const check = autosaveChecks as Record<
                    string,
                    { reason: string }
                  >;
                  return (
                    <MessageBox
                      key={key}
                      variation="error"
                      title={key}
                      description={check[key].reason}
                    />
                  );
                })}
          </Box>

          <div>
            <span>Status: {pendingContractData?.analysisStatus} </span>
            <span>Score: {analysisScore.toFixed(2)}</span>
          </div>
          <div>
            Team (Organization): {pendingContractData.teamName} (
            {pendingContractData.organizationName})
          </div>
          <form
            name="adminContractEditForm"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            style={{
              margin: "1rem 0",
              maxWidth: "36rem",
            }}
          >
            <FormProvider {...methods}>
              <Button
                className={buttonClasses.previewHeaderButton}
                onClick={openPdfFullscreen}
              >
                <OpenWithIcon fontSize="small" sx={{ marginRight: "0.5rem" }} />
                {t(
                  "pages.admin.tabs.pendingContracts.modal.buttons.fullscreen"
                )}
              </Button>
              <Button
                className={buttonClasses.previewHeaderButton}
                onClick={downloadPdfFile}
              >
                <DownloadIcon fontSize="small" sx={{ marginRight: "0.5rem" }} />
                {t(
                  "pages.admin.tabs.pendingContracts.modal.buttons.downloadPdf"
                )}
              </Button>
              <FormTextField
                name="name"
                label={t(
                  "pages.admin.tabs.pendingContracts.modal.contractTitle"
                )}
                control={control}
              />
              <div style={{ display: "grid", gridTemplateColumns: "95% auto" }}>
                <FormSelect
                  name="categoryId"
                  options={categoryOptions}
                  label={t("pages.contractEdit.forms.generalForm.category")}
                  control={control}
                />
              </div>
              {pendingContractData
                ? analysisCategory?.sections.map((section) => (
                    <div key={section.id}>
                      <span>{section.name.de}</span>
                      {section.fields.map((field) => (
                        <Grid key={field.id} item xs={12} md={6}>
                          <DataPoint
                            id={field.id}
                            definitions={pendingContractData.fieldDefinitions}
                            values={pendingContractData.fields}
                            contacts={contactsOfCategory}
                            refetchContacts={() => Promise.resolve()}
                            contactTypes={pendingContractData.contactTypes}
                            contactDefinitions={
                              pendingContractData.contactDefinitions
                            }
                            editable
                            showAnalysis
                            admin
                            categoryId={pendingContractData.categoryId}
                            contractId={pendingContractData.id}
                            teamId={pendingContractData.category.teamId}
                          />
                        </Grid>
                      ))}
                    </div>
                  ))
                : null}
              <hr />
              <FormCheckbox
                control={control}
                name="analyzedBadly"
                label={t(
                  "pages.admin.tabs.pendingContracts.modal.analyzedBadly"
                )}
              />
              <Grid container spacing={1.25}>
                <Grid item xs={12} md={6}>
                  <Button
                    type="submit"
                    size="large"
                    className={buttonClasses.baseButtonBlue}
                  >
                    {t("pages.admin.tabs.pendingContracts.modal.buttons.save")}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    onClick={() => handleClose()}
                    size="large"
                    className={buttonClasses.baseLinkButton}
                    sx={{ textTransform: "none" }}
                  >
                    {t(
                      "pages.admin.tabs.pendingContracts.modal.buttons.cancel"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </FormProvider>
          </form>
        </div>
        <div style={{ position: "sticky" }}>
          <Box sx={{ position: "sticky", top: 0 }}>
            {fileURL && (
              <div style={{ height: "75vh" }}>
                <PDFViewer
                  fileURL={fileURL}
                  fileName={
                    pendingContractData?.name
                      ? convertContractName(pendingContractData?.name)
                      : undefined
                  }
                  textractBlocks={ocrData}
                  handleSelection={handleSelection}
                />
              </div>
            )}
          </Box>
        </div>
        {annotationData && annotationData.contextMenuVisible && (
          <AnnotationMenu
            annotationData={annotationData}
            addAnnotationData={addAnnotationData}
          />
        )}
      </PDFViewerActionsProvider>
    </Outter>
  );
};

export default EditPendingContract;
