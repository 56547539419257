import React, { useEffect, useState } from "react";
import { CardTitle } from "components";
import { Trans, useTranslation } from "react-i18next";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { Button, IconButton } from "@mui/material";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { SectionHeader } from "./style";
import BasicTable from "components/BasicTable/BasicTable";
import { useGetAccessTokensQuery } from "shared/api/users";
import { Header, Row } from "components/BasicTable/types";
import { AccessTokenDataDTO, AccessTokenDTO } from "openapi";
import { DeleteIcon } from "../../../OrganizationSettings/Organization/components/TeamRoles/styles";
import AccessTokenDeleteModal from "./components/AccessTokenDeleteModal";
import { GenerateAccessTokenModal } from "./components/GenerateAccessTokenModal";
import globalConfig from "shared/config/global.config";
import { SimpleLink } from "new-components";

const TRANSLATION_PREFIX = "pages.settings.tabs.accessToken.table";
const PublicApiSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModelOpen] = useState<boolean>(false);
  const [selectedAccessToken, setSelectedAccessToken] =
    useState<AccessTokenDTO>();
  const [rowsData, setRowsData] = useState<
    (AccessTokenDataDTO & { action: JSX.Element })[]
  >([]);

  const { data: accessTokens } = useGetAccessTokensQuery();

  const headers: Header[] = [
    {
      key: "name",
      name: t(`${TRANSLATION_PREFIX}.columnNames.name`),
    },
    {
      key: "createdAt",
      name: t(`${TRANSLATION_PREFIX}.columnNames.createdAt`),
    },
    {
      key: "action",
      name: "",
    },
  ];

  const handleCloseDeleteModal = () => {
    setIsDeleteModelOpen(false);
  };

  useEffect(() => {
    if (accessTokens) {
      const rowsData = accessTokens.map((item) => ({
        ...item,
        action: (
          <IconButton
            className="delete"
            onClick={() => {
              setSelectedAccessToken(item);
              setIsDeleteModelOpen(true);
            }}
          >
            <DeleteIcon color="error" fontSize="small" />
          </IconButton>
        ),
      }));

      setRowsData(rowsData);
    }
  }, [accessTokens]);

  return (
    <>
      <CardWrapper>
        <SectionHeader>
          <div>
            <CardTitle>{t(`${TRANSLATION_PREFIX}.title`)}</CardTitle>
            <Trans i18nKey={`${TRANSLATION_PREFIX}.description`}>
              <SimpleLink
                target="_blank"
                to={globalConfig.REACT_APP_PUBLIC_API_DOC_URL as string}
              />
            </Trans>
          </div>
          <Button
            className={classes.baseButton}
            onClick={() => setIsOpen(true)}
          >
            {t(`${TRANSLATION_PREFIX}.button`)}
          </Button>
        </SectionHeader>
        <BasicTable headers={headers} data={rowsData as Row[]} />
      </CardWrapper>

      <GenerateAccessTokenModal isOpen={isOpen} setIsOpen={setIsOpen} />

      <AccessTokenDeleteModal
        isOpen={isDeleteModalOpen}
        handleOnClose={handleCloseDeleteModal}
        selectedAccessToken={selectedAccessToken as AccessTokenDTO}
      />
    </>
  );
};

export default PublicApiSection;
