/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddMemberToRoleDTO } from "../models/AddMemberToRoleDTO";
import type { CategoryCreateDTOV1 } from "../models/CategoryCreateDTOV1";
import type { CategoryDTOV1 } from "../models/CategoryDTOV1";
import type { CategoryUpdateDTOV1 } from "../models/CategoryUpdateDTOV1";
import type { ContactCreateDTO } from "../models/ContactCreateDTO";
import type { ContactDatapointDefinitionCreateDTO } from "../models/ContactDatapointDefinitionCreateDTO";
import type { ContactDatapointDefinitionDTO } from "../models/ContactDatapointDefinitionDTO";
import type { ContactDTO } from "../models/ContactDTO";
import type { ContactDuplicatesDTO } from "../models/ContactDuplicatesDTO";
import type { ContactTypeCreateDTO } from "../models/ContactTypeCreateDTO";
import type { ContactTypeDTO } from "../models/ContactTypeDTO";
import type { ContactUpdateDTO } from "../models/ContactUpdateDTO";
import type { ContractDTOV1 } from "../models/ContractDTOV1";
import type { ContractFieldCreateDTOV1 } from "../models/ContractFieldCreateDTOV1";
import type { ContractFieldDTOV1 } from "../models/ContractFieldDTOV1";
import type { ContractFieldUpdateDTOV1 } from "../models/ContractFieldUpdateDTOV1";
import type { ContractFileDTO } from "../models/ContractFileDTO";
import type { ContractInputDTOV1 } from "../models/ContractInputDTOV1";
import type { ImportDataDTO } from "../models/ImportDataDTO";
import type { OCRContractDataDTO } from "../models/OCRContractDataDTO";
import type { ParentTeamDTO } from "../models/ParentTeamDTO";
import type { RoleCreateDTO } from "../models/RoleCreateDTO";
import type { RoleDetailsDTO } from "../models/RoleDetailsDTO";
import type { RoleDTO } from "../models/RoleDTO";
import type { RoleUpdateDTO } from "../models/RoleUpdateDTO";
import type { TaskNewDTO } from "../models/TaskNewDTO";
import type { TeamMemberBasicInfoDTO } from "../models/TeamMemberBasicInfoDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class OrganizationService {
  /**
   * Get the list of contract fields.
   * @param id
   * @returns ContractFieldDTOV1
   * @throws ApiError
   */
  public static getFieldsOfTeam(
    id: string
  ): CancelablePromise<Array<ContractFieldDTOV1>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{id}/contract-fields",
      path: {
        id: id,
      },
    });
  }

  /**
   * Create a contract field.
   * @param id
   * @param requestBody
   * @returns ContractFieldDTOV1
   * @throws ApiError
   */
  public static createContractField(
    id: string,
    requestBody: ContractFieldCreateDTOV1
  ): CancelablePromise<ContractFieldDTOV1> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/organizations/{id}/contract-fields",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Update a contract field.
   * @param id
   * @param fieldId
   * @param requestBody
   * @returns ContractFieldDTOV1
   * @throws ApiError
   */
  public static updateContractField(
    id: string,
    fieldId: string,
    requestBody: ContractFieldUpdateDTOV1
  ): CancelablePromise<ContractFieldDTOV1> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/v1/organizations/{id}/contract-fields/{fieldId}",
      path: {
        id: id,
        fieldId: fieldId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete a contract field.
   * @param id
   * @param fieldId
   * @returns ContractFieldDTOV1
   * @throws ApiError
   */
  public static deleteContractField(
    id: string,
    fieldId: string
  ): CancelablePromise<ContractFieldDTOV1> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/v1/organizations/{id}/contract-fields/{fieldId}",
      path: {
        id: id,
        fieldId: fieldId,
      },
    });
  }

  /**
   * Get the list of contract categories.
   * @param id
   * @returns CategoryDTOV1
   * @throws ApiError
   */
  public static getCategoriesOfTeam(
    id: string
  ): CancelablePromise<Array<CategoryDTOV1>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{id}/contract-categories",
      path: {
        id: id,
      },
    });
  }

  /**
   * Create a contract category.
   * @param id
   * @param requestBody
   * @returns CategoryDTOV1
   * @throws ApiError
   */
  public static createContractCategory(
    id: string,
    requestBody: CategoryCreateDTOV1
  ): CancelablePromise<CategoryDTOV1> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/organizations/{id}/contract-categories",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get a single contract category by id.
   * @param categoryId
   * @param id
   * @returns CategoryDTOV1
   * @throws ApiError
   */
  public static getCategoryById(
    categoryId: string,
    id: string
  ): CancelablePromise<CategoryDTOV1> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{id}/contract-categories/{categoryId}",
      path: {
        categoryId: categoryId,
        id: id,
      },
    });
  }

  /**
   * Update a contract category.
   * @param id
   * @param categoryId
   * @param requestBody
   * @returns CategoryDTOV1
   * @throws ApiError
   */
  public static updateContractCategory(
    id: string,
    categoryId: string,
    requestBody: CategoryUpdateDTOV1
  ): CancelablePromise<CategoryDTOV1> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/v1/organizations/{id}/contract-categories/{categoryId}",
      path: {
        id: id,
        categoryId: categoryId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete a contract category.
   * @param categoryId
   * @param id
   * @returns CategoryDTOV1
   * @throws ApiError
   */
  public static deleteContractCategory(
    categoryId: string,
    id: string
  ): CancelablePromise<CategoryDTOV1> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/v1/organizations/{id}/contract-categories/{categoryId}",
      path: {
        categoryId: categoryId,
        id: id,
      },
    });
  }

  /**
   * Get all contracts the user has access to in the organization or for certain team.
   * @param id
   * @param search
   * @param dataMode
   * @returns ContractDTOV1
   * @throws ApiError
   */
  public static getContractsOfTeam(
    id: string,
    search?: string,
    dataMode?: "CURRENT_TEAM" | "INCLUDE_SUBTEAMS"
  ): CancelablePromise<Array<ContractDTOV1>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{id}/contracts",
      path: {
        id: id,
      },
      query: {
        search: search,
        dataMode: dataMode,
      },
    });
  }

  /**
   * Get all OCRed contracts the user has access to in the organization or for certain team.
   * @param id
   * @returns OCRContractDataDTO
   * @throws ApiError
   */
  public static getOcRedContractsOfTeam(
    id: string
  ): CancelablePromise<Array<OCRContractDataDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{id}/ocr-contracts",
      path: {
        id: id,
      },
    });
  }

  /**
   * Get a specific contract by id the user has access to.
   * @param teamId
   * @param contractId
   * @returns ContractDTOV1
   * @throws ApiError
   */
  public static getContractOfTeamById(
    teamId: string,
    contractId: string
  ): CancelablePromise<ContractDTOV1> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{teamId}/contracts/{contractId}",
      path: {
        teamId: teamId,
        contractId: contractId,
      },
    });
  }

  /**
   * Update contract.
   * @param teamId
   * @param contractId
   * @param requestBody
   * @returns ContractDTOV1
   * @throws ApiError
   */
  public static updateContract(
    teamId: string,
    contractId: string,
    requestBody: ContractInputDTOV1
  ): CancelablePromise<ContractDTOV1> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/v1/organizations/{teamId}/contracts/{contractId}",
      path: {
        teamId: teamId,
        contractId: contractId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get a specific contract file metadata by id.
   * @param teamId
   * @param contractId
   * @returns any
   * @throws ApiError
   */
  public static getContractFile(
    teamId: string,
    contractId: string
  ): CancelablePromise<ContractFileDTO | null> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{teamId}/contracts/{contractId}/file",
      path: {
        teamId: teamId,
        contractId: contractId,
      },
    });
  }

  /**
   * Create contract.
   * @param teamId
   * @param requestBody
   * @returns ContractDTOV1
   * @throws ApiError
   */
  public static createContract(
    teamId: string,
    requestBody: ContractInputDTOV1
  ): CancelablePromise<ContractDTOV1> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/organizations/{teamId}/contracts",
      path: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Import contracts.
   * @param teamId
   * @param requestBody
   * @returns ImportDataDTO
   * @throws ApiError
   */
  public static importContracts(
    teamId: string,
    requestBody: Array<ContractInputDTOV1>
  ): CancelablePromise<ImportDataDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/organizations/{teamId}/batch/contracts",
      path: {
        teamId: teamId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param teamId
   * @param categoryId
   * @returns TeamMemberBasicInfoDTO
   * @throws ApiError
   */
  public static getMembersWithAccessToCategory(
    teamId: string,
    categoryId: string
  ): CancelablePromise<Array<TeamMemberBasicInfoDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{teamId}/categories/{categoryId}/members",
      path: {
        teamId: teamId,
        categoryId: categoryId,
      },
    });
  }

  /**
   * Get the team data for the specific team.
   * @param id
   * @returns ParentTeamDTO
   * @throws ApiError
   */
  public static getOrganizationById(
    id: string
  ): CancelablePromise<ParentTeamDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @returns RoleDTO
   * @throws ApiError
   */
  public static getRoles(id: string): CancelablePromise<Array<RoleDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{id}/roles",
      path: {
        id: id,
      },
    });
  }

  /**
   * @param id
   * @param requestBody
   * @returns RoleDTO
   * @throws ApiError
   */
  public static createRole(
    id: string,
    requestBody: RoleCreateDTO
  ): CancelablePromise<RoleDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/organizations/{id}/roles",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param roleId
   * @param id
   * @returns RoleDetailsDTO
   * @throws ApiError
   */
  public static getRole(
    roleId: string,
    id: string
  ): CancelablePromise<RoleDetailsDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{id}/roles/{roleId}",
      path: {
        roleId: roleId,
        id: id,
      },
    });
  }

  /**
   * @param roleId
   * @param id
   * @returns RoleDTO
   * @throws ApiError
   */
  public static deleteRole(
    roleId: string,
    id: string
  ): CancelablePromise<RoleDTO> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/v1/organizations/{id}/roles/{roleId}",
      path: {
        roleId: roleId,
        id: id,
      },
    });
  }

  /**
   * @param roleId
   * @param id
   * @param requestBody
   * @returns RoleDTO
   * @throws ApiError
   */
  public static updateRole(
    roleId: string,
    id: string,
    requestBody: RoleUpdateDTO
  ): CancelablePromise<RoleDTO> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/v1/organizations/{id}/roles/{roleId}",
      path: {
        roleId: roleId,
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param memberId
   * @param id
   * @returns RoleDTO
   * @throws ApiError
   */
  public static getRolesOfMember(
    memberId: string,
    id: string
  ): CancelablePromise<Array<RoleDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{id}/members/{memberId}/roles",
      path: {
        memberId: memberId,
        id: id,
      },
    });
  }

  /**
   * @param memberId
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static addRoleToMember(
    memberId: string,
    id: string,
    requestBody: AddMemberToRoleDTO
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/organizations/{id}/members/{memberId}/roles",
      path: {
        memberId: memberId,
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param memberId
   * @param roleId
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static removeRoleFromMember(
    memberId: string,
    roleId: string,
    id: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/v1/organizations/{id}/members/{memberId}/roles/{roleId}",
      path: {
        memberId: memberId,
        roleId: roleId,
        id: id,
      },
    });
  }

  /**
   * @param memberId
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static removeMemberFromOrganization(
    memberId: string,
    id: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/v1/organizations/{id}/members/{memberId}",
      path: {
        memberId: memberId,
        id: id,
      },
    });
  }

  /**
   * @param organizationId
   * @param teamId
   * @returns ContactDTO
   * @throws ApiError
   */
  public static getContacts(
    organizationId: string,
    teamId: string
  ): CancelablePromise<Array<ContactDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{organizationId}/teams/{teamId}/contacts/entities",
      path: {
        organizationId: organizationId,
        teamId: teamId,
      },
    });
  }

  /**
   * @param contactId
   * @param organizationId
   * @param teamId
   * @returns ContactDTO
   * @throws ApiError
   */
  public static getContact(
    contactId: string,
    organizationId: string,
    teamId: string
  ): CancelablePromise<ContactDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{organizationId}/teams/{teamId}/contacts/entities/{contactId}",
      path: {
        contactId: contactId,
        organizationId: organizationId,
        teamId: teamId,
      },
    });
  }

  /**
   * @param organizationId
   * @param requestBody
   * @returns ContactDTO
   * @throws ApiError
   */
  public static createContact(
    organizationId: string,
    requestBody: ContactCreateDTO
  ): CancelablePromise<ContactDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/organizations/{organizationId}/contacts/entities",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param contactId
   * @param organizationId
   * @param requestBody
   * @returns ContactDTO
   * @throws ApiError
   */
  public static updateContact(
    contactId: string,
    organizationId: string,
    requestBody: ContactUpdateDTO
  ): CancelablePromise<ContactDTO> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/v1/organizations/{organizationId}/contacts/entities/{contactId}",
      path: {
        contactId: contactId,
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param contactId
   * @param organizationId
   * @returns any
   * @throws ApiError
   */
  public static deleteContact(
    contactId: string,
    organizationId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/v1/organizations/{organizationId}/contacts/entities/{contactId}",
      path: {
        contactId: contactId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * @param organizationId
   * @returns ContactDatapointDefinitionDTO
   * @throws ApiError
   */
  public static getContactDatapointsDefinitions(
    organizationId: string
  ): CancelablePromise<Array<ContactDatapointDefinitionDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{organizationId}/contacts/definitions",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * @param organizationId
   * @param requestBody
   * @returns ContactDatapointDefinitionDTO
   * @throws ApiError
   */
  public static createContactDatapointDefinition(
    organizationId: string,
    requestBody: ContactDatapointDefinitionCreateDTO
  ): CancelablePromise<ContactDatapointDefinitionDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/organizations/{organizationId}/contacts/definitions",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param definitionId
   * @param organizationId
   * @returns ContactDatapointDefinitionDTO
   * @throws ApiError
   */
  public static getContactDatapointDefinition(
    definitionId: string,
    organizationId: string
  ): CancelablePromise<ContactDatapointDefinitionDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{organizationId}/contacts/definitions/{definitionId}",
      path: {
        definitionId: definitionId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * @param organizationId
   * @param requestBody
   * @returns ContactTypeDTO
   * @throws ApiError
   */
  public static createContactType(
    organizationId: string,
    requestBody: ContactTypeCreateDTO
  ): CancelablePromise<ContactTypeDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/organizations/{organizationId}/contacts/types",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param organizationId
   * @returns ContactTypeDTO
   * @throws ApiError
   */
  public static getContactTypes(
    organizationId: string
  ): CancelablePromise<Array<ContactTypeDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{organizationId}/contacts/types",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * @param typeId
   * @param organizationId
   * @returns ContactTypeDTO
   * @throws ApiError
   */
  public static getContactType(
    typeId: string,
    organizationId: string
  ): CancelablePromise<ContactTypeDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{organizationId}/contacts/types/{typeId}",
      path: {
        typeId: typeId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * @param contactId
   * @param organizationId
   * @param teamId
   * @returns ContractDTOV1
   * @throws ApiError
   */
  public static getContractsByContact(
    contactId: string,
    organizationId: string,
    teamId: string
  ): CancelablePromise<Array<ContractDTOV1>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{organizationId}/teams/{teamId}/contacts/{contactId}/contracts",
      path: {
        contactId: contactId,
        organizationId: organizationId,
        teamId: teamId,
      },
    });
  }

  /**
   * @param contactId
   * @param organizationId
   * @param teamId
   * @returns TaskNewDTO
   * @throws ApiError
   */
  public static getTasksByContact(
    contactId: string,
    organizationId: string,
    teamId: string
  ): CancelablePromise<Array<TaskNewDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{organizationId}/teams/{teamId}/contacts/{contactId}/tasks",
      path: {
        contactId: contactId,
        organizationId: organizationId,
        teamId: teamId,
      },
    });
  }

  /**
   * @param organizationId
   * @returns ContactDuplicatesDTO
   * @throws ApiError
   */
  public static getNonDuplicates(
    organizationId: string
  ): CancelablePromise<ContactDuplicatesDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/v1/organizations/{organizationId}/contacts/non-duplicates",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * @param contactId
   * @param otherContactId
   * @param organizationId
   * @returns any
   * @throws ApiError
   */
  public static setContactAsNonDuplicateOf(
    contactId: string,
    otherContactId: string,
    organizationId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/v1/organizations/{organizationId}/contacts/entities/{contactId}/non-duplicates/{otherContactId}",
      path: {
        contactId: contactId,
        otherContactId: otherContactId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * @param contactId
   * @param otherContactId
   * @param organizationId
   * @returns any
   * @throws ApiError
   */
  public static removeContactAsNonDuplicateOf(
    contactId: string,
    otherContactId: string,
    organizationId: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/v1/organizations/{organizationId}/contacts/entities/{contactId}/non-duplicates/{otherContactId}",
      path: {
        contactId: contactId,
        otherContactId: otherContactId,
        organizationId: organizationId,
      },
    });
  }
}
