import styled from "@emotion/styled";
import { theme } from "theme";
import { AppStatus, APP_STATUSES } from "../../types";

const getStatusColor = (status: AppStatus) => {
  switch (status) {
    case APP_STATUSES.AVAILABLE:
      return "var(--color-clay-inverted)";
    case APP_STATUSES.PENDING:
      return "var(--color-kaki-300)";
    case APP_STATUSES.CONNECTED:
      return "var(--color-clay-inverted)";
    default:
      return "var(--color-clay-inverted)";
  }
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-lg);
  background-color: var(--color-white);
  border: 1px solid var(--color-border-neutral);
  border-radius: var(--border-radius);
  transition: border-color 0.4s, background-color; 0.4s;
  cursor: pointer;
  
  :hover {
    border-color: var(--color-primary-hover);
    background-color: var(--color-primary-hover-opacity-05);
  }
`;

export const Header = styled.div`
  display: flex;
  gap: var(--spacing-sm);
`;

export const Logo = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const Placeholder = styled.div`
  width: 24px;
  height: 24px;
  border: 4px solid var(--color-background-muted);
  background-color: var(--color-border-neutral);
  border-radius: var(--button-border-radius);
`;

export const Status = styled.div<{ status: AppStatus }>`
  flex: 0 0 auto;
  padding: var(--spacing-xs) var(--spacing-sm);
  margin-left: auto;
  font-size: ${theme.font.size.caption};
  background-color: ${({ status }) => getStatusColor(status)};
  border-radius: var(--button-border-radius);
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
`;

export const Name = styled.div`
  color: var(--color-text-primary);
`;

export const Description = styled.div`
  color: var(--color-text-secondary);
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.xl};
  width: 100%;
`;
