import React from "react";
import { useTranslation } from "react-i18next";
import WarningIcon from "@mui/icons-material/Warning";
import { CTAButton, NewModal } from "components";
import theme from "theme/theme";

type Props = {
  open: boolean;
  handleOnClose: () => void;
  handleOnDelete: () => void;
  isLoading?: boolean;
};

export const DeleteTaskModalContent = ({
  open,
  handleOnClose,
  handleOnDelete,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  return (
    <NewModal
      open={open}
      handleClose={handleOnClose}
      title={t("pages.tasks.modal.delete.title")}
      icon={<WarningIcon sx={{ fill: theme.color.red[600] }} />}
      body={<div>{t("pages.tasks.modal.delete.description")}</div>}
      footer={
        <>
          <CTAButton
            onClick={handleOnClose}
            type="button"
            name={t("common.buttons.cancel")}
            variant="secondary"
          />
          <CTAButton
            onClick={handleOnDelete}
            type="button"
            name={t("common.buttons.delete")}
            color="danger"
            disabled={isLoading}
          />
        </>
      }
    />
  );
};

export default DeleteTaskModalContent;
