import { Link as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";
import { theme } from "theme";

export const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
  height: calc(100% - ${theme.spacing.md});
  flex: 0 0 auto;
`;

export const Container = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
  align-items: center;

  .MuiFormControl-root {
    margin: 0 !important;
  }
`;
