import React from "react";
import Cross from "assets/svg/cross-icon.svg?react";
import { DialogProps } from "@mui/material";
import "overlayscrollbars/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {
  Header,
  IconWrapper,
  TitleWrapper,
  TitleText,
  DialogContent,
  ButtonContainer,
  Footer,
  ModalDialog,
} from "./styles";
import CTAButton from "components/CTAButton/CTAButton";

export type ModalProps = {
  open: boolean;
  handleClose: () => void;
  title: string | JSX.Element;
  headerProps?: {
    style: React.CSSProperties;
  };
  withDefaultTitleStyle?: boolean;
  body: string | JSX.Element;
  footer?: JSX.Element;
  icon?: JSX.Element | null;
  disableBackdropClick?: boolean;
  bodyProps?: {
    noPadding?: boolean;
  };
} & DialogProps;

const Modal = ({
  disableBackdropClick = false,
  open,
  handleClose,
  headerProps,
  title,
  icon,
  withDefaultTitleStyle,
  body,
  footer,
  bodyProps,
  ...otherProps
}: ModalProps) => {
  if (!open) return <></>;

  const onClose = (_event: React.ReactEventHandler, reason: string) => {
    const allowBackDropClose =
      reason === "backdropClick" && !disableBackdropClick;
    if (allowBackDropClose) handleClose();
  };

  return (
    // The empty title removes the default dialog tooltip behavior
    <ModalDialog
      open={open}
      {...otherProps}
      onClose={onClose}
      title=""
      data-testid="modal"
    >
      <Header {...headerProps} hasTitle={!!title}>
        {title && (
          <TitleWrapper>
            {!!icon && (
              <IconWrapper data-testid="modal-icon">{icon}</IconWrapper>
            )}

            <TitleText
              styled={withDefaultTitleStyle}
              hasIcon={!!icon}
              data-testid="modal-title"
            >
              {title}
            </TitleText>
          </TitleWrapper>
        )}

        <ButtonContainer>
          <CTAButton
            variant="tertiary"
            icon={<Cross />}
            onClick={handleClose}
            color="gray"
            iconSize="small"
            data-cy="closeIconButton"
          />
        </ButtonContainer>
      </Header>
      {body && (
        <OverlayScrollbarsComponent
          defer
          style={{
            overflowY: "auto",
          }}
        >
          <DialogContent
            noPadding={bodyProps?.noPadding}
            data-testid="modal-body"
          >
            {body}
          </DialogContent>
        </OverlayScrollbarsComponent>
      )}

      {footer && <Footer>{footer}</Footer>}
    </ModalDialog>
  );
};

export default Modal;
