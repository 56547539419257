import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const Subtitle = styled.div`
  font-size: var(--copy-h3);
  font-weight: ${theme.font.weight.bold};
`;

export const Heading = styled.div`
  font-weight: ${theme.font.weight.semiBold};
`;
