import React, { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import categoriesData from "./data/categoriesData.json";
import appsData from "./data/appsData.json";
import { AppDTO, AppStatus } from "./types";
import AppCard from "./components/AppCard/AppCard";
import {
  Cards,
  Categories,
  Wrapper,
  Title,
  Sidebar,
  CategoryButton,
  SearchBar,
  Main,
} from "./styles";

const Apps = () => {
  const { control, watch } = useForm();
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState("all");
  const [filteredAppData, setFileteredAppData] = useState<AppDTO[]>(appsData);

  const searchTerm = watch("search") as string;

  const onCategoryClick = (id: string) => {
    setActiveCategory(id);
    let filteredList: AppDTO[] | [] | undefined = appsData;
    if (searchTerm) {
      filteredList = filterBySubstring(searchTerm, appsData);
    }
    filteredList = filterByCategory(id, filteredList) || [];
    setFileteredAppData(filteredList);
  };

  const onSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    let filteredList: AppDTO[] | [] | undefined = appsData;
    if (activeCategory) {
      filteredList = filterByCategory(activeCategory, appsData);
    }
    filteredList = filterBySubstring(searchTerm, filteredList) || [];
    setFileteredAppData(filteredList);
  };

  const filterBySubstring = (string: string, list?: AppDTO[]) => {
    const filteredList = list?.filter((item) =>
      item.name.toLowerCase().includes(string)
    );
    if (filteredList) {
      return filteredList;
    }
  };

  const filterByCategory = (id: string, list?: AppDTO[]) => {
    let filteredList;
    if (id !== "all") {
      filteredList = list?.filter((item) => item?.categories?.includes(id));
      if (filteredList) {
        return filteredList;
      }
    } else {
      return list;
    }
  };

  return (
    <Wrapper>
      <Sidebar>
        <Categories>
          <Title>
            <span>Category</span>
          </Title>
          <div>
            {/* @Todo find other places where it is used / may be used and create a component */}
            <CategoryButton
              onClick={() => onCategoryClick("all")}
              active={activeCategory === "all"}
            >
              All
            </CategoryButton>
            {categoriesData.map((category) => (
              <CategoryButton
                onClick={() => onCategoryClick(category.id)}
                key={category.id}
                active={category.id === activeCategory}
              >
                {category.name}
              </CategoryButton>
            ))}
          </div>
        </Categories>
      </Sidebar>
      <Main>
        <SearchBar
          name="search"
          control={control}
          label={t("common.components.search.placeholder")}
          InputProps={{
            onInput: onSearchInput,
          }}
          margin="none"
        />
        {filteredAppData.length ? (
          <Cards>
            {filteredAppData.map((app) => (
              <AppCard
                name={app.name}
                description={app.description}
                status={app.status as AppStatus}
                logoPath={app.logo_path}
                key={app.id}
              />
            ))}
          </Cards>
        ) : (
          <div>{t("pages.settings.organization.apps.messages.noResults")}</div>
        )}
      </Main>
    </Wrapper>
  );
};

export default Apps;
