import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { NewModal, CTAButton, SectionHeader } from "components";
import { useDeleteWebhookMutation } from "shared/api";
import CardWrapper from "components/CardWrapper/CardWrapper";
import routePaths from "constants/routePaths";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import { EventOptionLabel } from "../styles";
import { Body, Title, Description, URL, EventList } from "../styles";

type DeleteModalConfirmationProps = {
  open: boolean;
  handleClose: () => void;
  handleOnDelete: () => void;
  events: string[];
  url: string;
};

export const DeleteModalConfirmation = ({
  open,
  handleClose,
  handleOnDelete,
  events,
  url,
}: DeleteModalConfirmationProps) => {
  const { t } = useTranslation();

  return (
    <NewModal
      open={open}
      handleClose={handleClose}
      title={t("pages.settings.organization.webhooks.common.delete.title")}
      fullWidth
      maxWidth="sm"
      body={
        <Body>
          <Title>
            {t(
              "pages.settings.organization.webhooks.common.delete.modal.title"
            )}
          </Title>
          <Description>
            {t(
              "pages.settings.organization.webhooks.common.delete.modal.description"
            )}
          </Description>
          <URL>{url}</URL>
          <EventList>
            {events.map((event, index) => (
              <EventOptionLabel key={index}>{event}</EventOptionLabel>
            ))}
          </EventList>
        </Body>
      }
      footer={
        <>
          <CTAButton
            name={t("common.buttons.cancel")}
            variant="secondary"
            onClick={handleClose}
          />
          <CTAButton
            name={t("common.buttons.deleteConfirm")}
            variant="primary"
            color="danger"
            onClick={() => {
              void handleOnDelete();
              handleClose();
            }}
          />
        </>
      }
    />
  );
};

const DeleteSection = ({ url, events }: { url: string; events: string[] }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: webhookId } = useParams();
  const { organizationId } = useTeam();
  const { mutateAsync: deleteWebhook } = useDeleteWebhookMutation();

  const handleOnDelete = async () => {
    try {
      if (!webhookId || webhookId === "new") return;
      await deleteWebhook({ webhookId, organizationId });
      navigate(routePaths.SETTINGS_WEBHOOK);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CardWrapper style={{ margin: `0 0 ${theme.spacing.xxxxl}` }}>
      <SectionHeader
        title={t("pages.settings.organization.webhooks.common.delete.title")}
        subTitle={t(
          "pages.settings.organization.webhooks.common.delete.description",
          { url }
        )}
        noPadding
        onButtonClick={() => setShowDeleteModal(true)}
        buttonType="button"
        buttonVariant="secondary"
        baseButtonText={t(
          "pages.settings.organization.webhooks.common.delete.buttonLabel"
        )}
        buttonColor="danger"
      />

      <DeleteModalConfirmation
        open={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleOnDelete={handleOnDelete}
        events={events}
        url={url}
      />
    </CardWrapper>
  );
};

export default DeleteSection;
