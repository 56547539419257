import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div<{
  variant?: string;
}>`
  display: ${(props) => (props.variant === "row" ? "flex" : "block")};
  justify-content: ${(props) =>
    props.variant === "row" ? "space-between" : "initial"};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: ${theme.spacing.xxs};
  font-weight: ${theme.font.weight.normal};
  font-size: ${theme.font.size.caption};
  color: ${theme.color.gray[700]};
`;

export const TitleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: ${theme.spacing.sm};
  svg {
    fill: ${theme.color.gray[700]};
    max-width: 14px;
    max-height: 14px;
  }
`;

export const Values = styled.div`
  font-size: ${theme.font.size.body};
`;

export const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xxs};
`;

export const PreviousValue = styled.div<{
  lineThrough?: boolean;
  oldBg?: string;
}>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xxs};
  font-size: ${theme.font.size.body};
  padding: 0 ${(props) => (props.oldBg ? theme.spacing.sm : "0")};
  text-decoration: ${(props) => (props.lineThrough ? "line-through" : "none")};
  background-color: ${(props) => (props.oldBg ? props.oldBg : "transparent")};
  border-radius: ${theme.labelBorderRadius};
  word-break: break-word;
`;

export const CurrentValue = styled.div<{ newBg?: string; variant?: string }>`
  padding: 0 ${(props) => (props.newBg ? theme.spacing.sm : "0")};
  font-size: ${(props) =>
    props.variant === "row" ? theme.font.size.caption : theme.font.size.body};
  background-color: ${(props) => (props.newBg ? props.newBg : "transparent")};
  color: ${(props) =>
    props.variant === "row" ? theme.color.gray[800] : "inherit"};
  border-radius: ${theme.labelBorderRadius};
  word-break: break-word;
`;

export const ValueIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  svg {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
  }
`;
