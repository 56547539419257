import styled from "@emotion/styled";
import { theme } from "theme";

export const ModalBody = styled.div`
  border: 1px dashed ${theme.color.blue[600]};
  border-radius: ${theme.borderRadius};
  padding: ${theme.spacing.lg};

  p:nth-child(2) {
    color: ${theme.color.gray[600]};
  }
`;

export const Content = styled.div<{ highlighted?: boolean }>`
  height: 104px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${theme.spacing.md};
  cursor: default;
  transition: background-color 0.15s ease-in-out;

  ${({ highlighted }) =>
    !highlighted &&
    `
    height: 160px;
    cursor: pointer;
    flex-direction: row;
    align-items: center;

    :hover {
      background-color: ${theme.color.blue[200]}80;
    }
  `}

  svg {
    fill: ${theme.color.black};
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  align-items: flex-start;
`;

export const Paragraph = styled.p<{ bold?: boolean }>`
  ${({ bold }) =>
    bold &&
    `
    font-weight: ${theme.font.weight.semiBold};
  `}

  margin: 0;
`;
