import React, { useEffect, useState } from "react";
import { Control } from "react-hook-form";
import { IconButton } from "@mui/material";
import { ContractTemplateTagDTO, CategoryDTO } from "openapi";
import SaveStatus from "new-components/SaveStatus/SaveStatus";
import { Title, Import, CategorySelector, Tags } from "./components";
import {
  Wrapper,
  Container,
  HeadersIcons,
  TemplateIcon,
  ArrowIcon,
  Group,
} from "./styles";

export type HeaderProps = {
  control: Control;
  handleOnGoBack: () => void;
  handleOnImport: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnDelete: () => void;
  handleTemplateDuplication: () => void;
  lastModified?: string;
  categoryId?: string;
  handleCategoryChange: (value: string) => void;
  categories?: CategoryDTO[];
  currentCategory?: CategoryDTO;
  handleTagsChange?: (tags: { key: string; value: string }[]) => void;
  preselectedTags: ContractTemplateTagDTO[];
  isSaving: boolean | null;
};

const Header = ({
  handleOnGoBack,
  handleOnImport,
  handleOnDelete,
  handleTemplateDuplication,
  control,
  lastModified,
  handleCategoryChange,
  handleTagsChange,
  categories,
  currentCategory,
  preselectedTags,
  isSaving,
}: HeaderProps) => {
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoadingSave(false), 2000);
    return () => setIsLoadingSave(true);
  }, [isSaving]);

  return (
    <Wrapper>
      <Container>
        <HeadersIcons>
          <TemplateIcon />
          <IconButton onClick={handleOnGoBack}>
            <ArrowIcon />
          </IconButton>
        </HeadersIcons>

        <Group>
          <Title control={control} name="name" lastModified={lastModified} />
          <CategorySelector
            handleCategoryChange={handleCategoryChange}
            categories={categories}
            currentCategory={currentCategory}
          />
          <Tags
            isEditable
            maxTagsCount={3}
            preselectedTags={preselectedTags}
            handleOnChange={handleTagsChange}
          />
        </Group>
      </Container>

      <Container>
        <SaveStatus isLoading={isLoadingSave} />

        <Import
          handleOnImport={handleOnImport}
          handleDeleteContractTemplate={handleOnDelete}
          handleTemplateDuplication={handleTemplateDuplication}
        />
      </Container>
    </Wrapper>
  );
};

export default Header;
