import React from "react";
import Popover from "@mui/material/Popover";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  PopoverContainer,
  PopoverOption,
  paperPropsStyles,
} from "./popoverStyles";
import { useTranslation } from "react-i18next";

type Props = {
  id: string;
  shallDisallowDelete?: boolean;
  deleteOptionId?: string;
  editOptionId?: string;
  open: boolean;
  popoverElement?: HTMLButtonElement | null;
  handleOnClosePopover: () => void;
  setOpenEditModal: (value: boolean) => void;
  setOpenDeleteModal: (value: boolean) => void;
};

const PopoverComponent = ({
  id,
  shallDisallowDelete = false,
  deleteOptionId,
  editOptionId,
  open,
  popoverElement,
  setOpenEditModal,
  setOpenDeleteModal,
  handleOnClosePopover,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Popover
      id={open ? `popover-id-${id}` : undefined}
      open={open}
      anchorEl={popoverElement}
      onClose={handleOnClosePopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{ sx: paperPropsStyles }}
    >
      <PopoverContainer>
        {!shallDisallowDelete && (
          <PopoverOption
            id={deleteOptionId}
            onClick={() => setOpenDeleteModal(true)}
            danger
          >
            <DeleteForeverIcon fontSize="small" />
            <span>{t("common.buttons.delete")}</span>
          </PopoverOption>
        )}

        <PopoverOption id={editOptionId} onClick={() => setOpenEditModal(true)}>
          <EditIcon fontSize="small" />
          <span>{t("common.buttons.edit")}</span>
        </PopoverOption>
      </PopoverContainer>
    </Popover>
  );
};

export default PopoverComponent;
