import React from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { Container, Link } from "./styles";
import { routePaths } from "constants/";
import { AutoCompleteSelect } from "components/FormItems";
import { ContractFieldDTOV1, OCRContractDataDTO } from "openapi";
import { CTAButton } from "components";
import ArrowIcon from "assets/svg/arrow-right.svg?react";

type Props = {
  control: unknown;
  contracts: OCRContractDataDTO[];
  onChange: (e: { key: string; value: string }) => void;
  onPromptSubmit: () => void;
  fieldType: ContractFieldDTOV1.type | string;
};

const ContractSelector = ({
  control,
  onChange: onContractSelect,
  contracts,
  onPromptSubmit,
  fieldType,
}: Props) => {
  const { t } = useTranslation();

  const isContractSelected = useWatch({
    name: "contractId",
    control: control as Control,
  }) as string;

  const contractsOptions = contracts
    .map((contract) => {
      return {
        key: contract.id,
        value: contract.name,
      };
    })
    .sort((a, b) => a.value.localeCompare(b.value));

  const contract = contracts.find(
    (contract) => contract.id === isContractSelected
  );

  const currentContractLink = `${routePaths.CONTRACTS}/${contract?.id ?? ""}`;

  return (
    <Container>
      <Controller
        name="contractId"
        control={control as Control}
        render={({ field }) => (
          <AutoCompleteSelect
            {...field}
            aria-label="taskContractsAutocompleteList"
            id="contractsAutocompleteList"
            options={contractsOptions}
            onSelect={(value: { key: string; value: string }) => {
              onContractSelect(value);
            }}
            label={t(
              "pages.contractEdit.modals.customField.prompt.placeholders.contractSelect"
            )}
            endAdornment={
              <Tooltip
                title={t(
                  "pages.contractEdit.modals.customField.prompt.tooltips.test"
                )}
              >
                <CTAButton
                  variant="primary"
                  icon={<ArrowIcon />}
                  size="tiny"
                  iconSize="medium"
                  disabled={!isContractSelected || !fieldType}
                  onClick={(event) => {
                    event.stopPropagation();
                    onPromptSubmit();
                  }}
                />
              </Tooltip>
            }
          />
        )}
      />
      {isContractSelected && (
        <Link
          to={currentContractLink ?? ""}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Tooltip
            title={t(
              "pages.contractEdit.modals.customField.prompt.tooltips.contractLink"
            )}
          >
            <LaunchIcon fontSize="small" />
          </Tooltip>
        </Link>
      )}
    </Container>
  );
};

export default ContractSelector;
