import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import currencies from "../../utils/currencies-list.json";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./ag-theme.css";

import {
  CellValueChangedEvent,
  CheckboxSelectionCallbackParams,
  ColDef,
  ExcelStyle,
  GridOptions,
  GridReadyEvent,
  RowDataUpdatedEvent,
  RowSelectedEvent,
  SelectionChangedEvent,
  SideBarDef,
  ToolPanelVisibleChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { NameCellRenderer } from "pages/Contracts/CellRenderers/NameCellRenderer";
import { useTranslation } from "react-i18next";
import { ContractDtoWithResolvedParent } from "constants/utils";
import { Box } from "@mui/material";
import CustomDatepicker from "pages/Contracts/Gridcomponents/CustomDatepicker";
import { useOverviewActions, useOverviewState } from "contexts/grid/hooks";
import { CustomCellEditor } from "../../pages/Contracts/CellEditors/CustomCellEditor";
import { patchScrollbar } from "./utils";
import { getCellClass, isContractEditable } from "pages/Contracts/helpers";
import { useTeam } from "contexts/team/hooks";
import CustomStatusBar from "pages/Contracts/CustomStatusBar/CustomStatusBar";
import { ContractDTOV1 } from "openapi";
import { getLocale } from "./locale";
import { AGGridBaseTableContext } from "./types";
import FloatingActionToolbar from "new-components/FloatingActionToolbar";
import {
  floatingActionToolbarInitialPosition,
  FloatingActionToolbarPosition,
} from "new-components/FloatingActionToolbar/FloatingActionToolbar";
import { getCssVariableInPx } from "utils/helpers";
import CTAButton, { CTAButtonProps } from "components/CTAButton/CTAButton";
import MoveIcon from "assets/svg/move2.svg?react";
import DownloadIcon from "assets/svg/download-icon.svg?react";
import MoveContractsModal from "pages/Contracts/Modals/MoveContractsContent";
import { Features } from "constants/features";
import { useBulkDownloadDocuments } from "shared/api/organization";
import { useSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { CellSelectionChangedEvent } from "ag-grid-community/dist/types/core/events";
import Loader from "../Loader/Loader";
import { SearchType } from "hooks/useFullTextSearch";

export type AGGridBaseTableProps = {
  gridOptions: GridOptions;
  rowData: ContractDtoWithResolvedParent[] | undefined;
  gridName: string;
  sidebarOptions: string | boolean | SideBarDef;
  onGridReady: (event: GridReadyEvent) => void;
  gridRef: React.MutableRefObject<
    AgGridReact<ContractDtoWithResolvedParent> | undefined
  >;
  onCellValueChanged: (
    event: CellValueChangedEvent<ContractDtoWithResolvedParent>
  ) => void;
  onToolPanelVisibleChanged?: (event: ToolPanelVisibleChangedEvent) => void;
  allowSelection?: boolean;
  shouldFilterOnClient?: boolean;
  context?: AGGridBaseTableContext;
  isLoading?: boolean;
  searchType?: SearchType;
};

const AgGridBaseTable = ({
  gridOptions,
  sidebarOptions,
  rowData,
  gridName,
  onGridReady,
  gridRef,
  onCellValueChanged,
  onToolPanelVisibleChanged,
  allowSelection = false,
  shouldFilterOnClient = true,
  context,
  isLoading,
  searchType,
}: AGGridBaseTableProps) => {
  const { gridStateProps } = useOverviewState();
  const { gridProps } = useOverviewActions();
  const { t } = useTranslation();
  const { hasWriteAccess, hasFeature, organizationId } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const hasBulkDownloadFeature = hasFeature(Features.BULK_DOWNLOAD);
  const canSelectMultipleRows = allowSelection || hasBulkDownloadFeature;
  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      field: "name",
      minWidth: 300,
      editable: isContractEditable(hasWriteAccess),
      cellEditor: CustomCellEditor,
      cellEditorParams: {
        type: "text",
        isTitle: true,
      },
      headerName: t("pages.contracts.tableColumns.title"),
      cellClass: getCellClass(null, undefined),
      cellRendererParams: {
        innerRenderer: NameCellRenderer,
        innerRendererParams: { searchType },
        suppressCount: true,
      },
      checkboxSelection: canSelectMultipleRows
        ? (params: CheckboxSelectionCallbackParams<ContractDTOV1>) => {
            return params.node.level === 0;
          }
        : false,
      headerCheckboxSelection: canSelectMultipleRows,
      headerCheckboxSelectionFilteredOnly: true,
    }),
    [hasWriteAccess]
  );

  const gridWrapperRef = React.createRef<HTMLDivElement>();

  const [toolbarPosition, setToolbarPosition] =
    useState<FloatingActionToolbarPosition>(
      floatingActionToolbarInitialPosition
    );
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [selectedContracts, setSelectedContracts] = useState<ContractDTOV1[]>(
    []
  );

  const getDataPath = useCallback(
    (data: ContractDtoWithResolvedParent): string[] => {
      const path = [];
      let currentParent = data.parent;
      while (currentParent) {
        path.push(currentParent.id);
        currentParent = currentParent.parent;
      }
      path.reverse();

      path.push(data.id);
      return path;
    },
    []
  );

  const excelStyles: ExcelStyle[] = useMemo(() => {
    const styles: ExcelStyle[] = [
      {
        id: "date-cell",
        dataType: "DateTime",
        numberFormat: {
          format: "dd.MM.yyyy",
        },
      },
    ];

    styles.push(
      ...currencies.map((currency) => ({
        id: `amount-cell-${currency.cc}`,
        numberFormat: {
          format: `#,##0.00\\ [$${currency.symbol}]`,
        },
      }))
    );

    return styles;
  }, []);

  const updateToolbarPosition = useCallback(() => {
    if (gridWrapperRef.current) {
      const gridRect = gridWrapperRef.current.getBoundingClientRect();

      const lastRowHeight =
        gridRef.current?.api?.getDisplayedRowAtIndex(0)?.rowHeight || 40;
      const spacingBelowGrid = getCssVariableInPx("--spacing-lg");
      const statusBarElement = document.querySelector(".ag-status-bar");

      const top =
        gridRect.bottom +
        window.scrollY -
        lastRowHeight * 2 +
        spacingBelowGrid -
        Number(statusBarElement?.getBoundingClientRect().height);
      const left = gridRect.left + gridRect.width / 2;

      setToolbarPosition({
        top: top,
        left: left,
        width: gridRect.width,
        height: lastRowHeight,
      });
    }
  }, [gridWrapperRef]);

  useEffect(() => {
    window.addEventListener("resize", updateToolbarPosition);

    return () => {
      window.removeEventListener("resize", updateToolbarPosition);
    };
  }, [gridWrapperRef]);

  const handleDeselectAll = () => gridRef.current?.api.deselectAll();

  useEffect(() => {
    if (gridRef.current?.api) {
      if (isLoading) {
        gridRef.current?.api?.showLoadingOverlay();
        return;
      }
      gridRef.current?.api.hideOverlay();
    }
  }, [gridRef.current?.api, isLoading]);

  const onSelectionChanged = (
    e:
      | SelectionChangedEvent
      | RowSelectedEvent
      | RowDataUpdatedEvent
      | CellSelectionChangedEvent
  ) => {
    const selected = e.api.getSelectedNodes();

    setSelectedContracts(
      selected
        .sort((a, b) => {
          if (a.rowIndex === null && b.rowIndex === null) {
            return 0;
          } else if (a.rowIndex === null) {
            return 1;
          } else if (b.rowIndex === null) {
            return -1;
          } else {
            return a.rowIndex - b.rowIndex;
          }
        })
        .map((node) => node.data as ContractDTOV1)
    );
  };

  const bulkDownloadDocuments = useBulkDownloadDocuments();
  const handleBulkDownload = useCallback(async () => {
    if (!gridRef.current) {
      return;
    }

    const contractIds: string[] = [];

    for (const node of gridRef.current.api.getSelectedNodes()) {
      if (node.data?.id && node.data.type === ContractDTOV1.type.MAIN_CONTRACT)
        contractIds.push(node.data.id);
    }

    try {
      if (!organizationId || contractIds.length === 0) {
        throw new Error();
      }

      enqueueSnackbar(t("pages.contracts.messages.preparingBulkDownload"), {
        variant: "info",
        autoHideDuration: 7000,
      });

      await bulkDownloadDocuments.mutateAsync({
        organizationId,
        requestBody: { contractIds },
      });

      handleDeselectAll();
    } catch (e) {
      enqueueSnackbar(t("pages.contracts.messages.bulkDownloadError"), {
        variant: "error",
      });
      Sentry.captureException(e);
    }
  }, [bulkDownloadDocuments, gridRef.current, organizationId]);

  const FloatingActionToolbarButtons = useMemo(() => {
    const Button = (props: Partial<CTAButtonProps>) => {
      return <CTAButton variant="tertiary" {...props} />;
    };

    return (
      <>
        {allowSelection ? (
          <Button
            name={t("common.buttons.move")}
            onClick={() => {
              setShowMoveModal(true);
            }}
            icon={<MoveIcon />}
            label={t("pages.contracts.modals.moveContracts.title")}
          />
        ) : null}
        {hasBulkDownloadFeature ? (
          <Button
            icon={<DownloadIcon />}
            name={t("common.buttons.download")}
            variant="tertiary"
            onClick={async () => await handleBulkDownload()}
          />
        ) : null}
      </>
    );
  }, [allowSelection, t]);
  if (!shouldFilterOnClient) {
    gridStateProps.quickFilterText = "";
  }

  return (
    <Box
      id={`ag-${gridName}-grid`}
      data-testid="ag-grid-base-table"
      className="ag-theme-alpine"
      sx={{
        flex: 1,
        pointerEvents: "auto",
      }}
      ref={gridWrapperRef}
    >
      {selectedContracts.length > 0 ? (
        <FloatingActionToolbar
          buttons={FloatingActionToolbarButtons}
          numberSelected={selectedContracts.length}
          onClose={handleDeselectAll}
          position={toolbarPosition}
        />
      ) : null}

      <AgGridReact
        loadingOverlayComponent={Loader}
        animateRows
        autoGroupColumnDef={autoGroupColumnDef}
        components={{ agDateInput: CustomDatepicker }}
        context={context}
        embedFullWidthRows
        excelStyles={excelStyles}
        excludeChildrenWhenTreeDataFiltering
        getDataPath={getDataPath}
        getRowId={(params) => params.data.id}
        gridOptions={gridOptions}
        localeText={getLocale(t)}
        onCellValueChanged={onCellValueChanged}
        onGridReady={(e) => {
          patchScrollbar();
          onGridReady(e);
          updateToolbarPosition();
        }}
        onCellSelectionChanged={onSelectionChanged}
        onRowDataUpdated={onSelectionChanged}
        onRowSelected={onSelectionChanged}
        onSelectionChanged={onSelectionChanged}
        onToolPanelVisibleChanged={onToolPanelVisibleChanged}
        ref={gridRef as unknown as RefObject<AgGridReact>}
        rowData={rowData}
        sideBar={sidebarOptions}
        statusBar={{
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
            { statusPanel: CustomStatusBar, align: "right" },
          ],
        }}
        suppressContextMenu
        suppressRowClickSelection={true}
        suppressRowDrag
        treeData
        {...gridProps}
        {...gridStateProps}
      />
      {showMoveModal ? (
        <MoveContractsModal
          handleClose={() => {
            setShowMoveModal(false);
          }}
          contracts={selectedContracts}
        />
      ) : null}
    </Box>
  );
};

export default AgGridBaseTable;
