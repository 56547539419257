import styled from "@emotion/styled";
import { theme } from "theme";

type ButtonProps = {
  variant?: "primary" | "secondary" | "tertiary";
  size?: "tiny" | "large" | "small" | "stretched" | "square";
  iconPosition?: "left" | "right";
  color?: keyof typeof theme.color | "danger";
  hoverColor?: keyof typeof theme.color | "danger";
  iconSize?: "tiny" | "small" | "medium" | "large" | undefined;
};

export const Button = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  padding: ${theme.spacing.md} ${theme.spacing.xl};
  outline: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: var(--button-border-radius);
  font-size: ${theme.font.size.body};
  font-weight: ${theme.font.weight.semiBold};
  font-family: inherit; // TODO This is stupid, somehow the styled.button reset the font-family so I have to set it. Someone make it go away
  cursor: pointer;
  line-height: ${theme.spacing.xl};
  transition: all 0.2s;
  gap: ${theme.spacing.sm};
  max-height: 44px;
  & > svg {
    ${({ iconSize }) => {
      switch (iconSize) {
        case "tiny":
          return `
          width: 12px;
          height: 12px;
          `;
        case "small":
          return `
          width: 16px;
          height: 16px;
          `;
        case "medium":
          return `
            width: 20px;
            height: 20px;
            `;
        case "large":
          return `
          width: 32px;
          height: 32px;
          `;
        default:
          return `
          width: 24px;
          height: 24px;
          `;
      }
    }}
    fill: currentColor;
  }
  ${({ variant, color, hoverColor }: ButtonProps) => {
    switch (variant) {
      case "primary":
        return `
        background-color: ${
          color === "danger"
            ? "var(--color-danger)"
            : color
            ? theme.color[color][600]
            : "var(--color-primary)"
        };
          color: var(--button-color-primary);
          &:hover {
           background-color: ${
             color === "danger"
               ? "var(--color-danger-hover)"
               : hoverColor === "danger"
               ? "var(--color-danger-hover)"
               : hoverColor
               ? theme.color[hoverColor][500]
               : "var(--color-primary-hover)"
           };
          }
          &:focus:not(:active) {
            outline:  3px solid ${
              color ? color : "var(--color-primary-active)"
            };

            outline: 3px solid ${
              color === "danger"
                ? "var(--color-danger-active)"
                : color
                ? color
                : "var(--color-primary-active)"
            };
          }
          &:active {
            background-color: ${
              color === "danger"
                ? "var(--color-danger-active)"
                : color
                ? color
                : "var(--color-primary-active)"
            };
          }
          :focus:not(:focus-visible) {
            outline: none;
          }
        `;
      case "secondary":
        return `
          background-color: transparent;
          color: ${
            color === "danger"
              ? "var(--color-danger)"
              : color
              ? color
              : "var(--color-primary)"
          };
          border-color: ${
            color === "danger"
              ? "var(--color-danger)"
              : color
              ? color
              : "var(--color-primary)"
          };
          &:hover {
            background-color: ${
              color === "danger"
                ? "var(--color-danger-hover-opacity-05)"
                : hoverColor === "danger"
                ? "var(--color-danger-hover-opacity-05)"
                : hoverColor
                ? theme.color[hoverColor][500]
                : "var(--color-primary-hover-opacity-05)"
            };
            color: ${
              color === "danger"
                ? "var(--color-danger)"
                : "var(--color-primary)"
            }
            };
            svg {
              fill: ${
                color === "danger"
                  ? "var(--color-danger)"
                  : "var(--color-primary)"
              }
              };
            }
          }
          &:focus:not(:active) {
            outline:  2px solid ${
              color === "danger"
                ? "var(--color-danger-hover-opacity-10)"
                : color
                ? color
                : "var(--color-primary-hover-opacity-10)"
            };
          }
          &:active {
            background-color: ${
              color === "danger"
                ? "var(--color-danger-hover-opacity-10)"
                : color
                ? color
                : "var(--color-primary-hover-opacity-10)"
            };
          }
          :focus:not(:focus-visible) {
            outline: none;
          }

        `;
      case "tertiary":
        return `
          padding: ${theme.spacing.xs};
          color: ${
            color === "danger"
              ? "var(--color-danger)"
              : color
              ? color
              : "var(--color-primary)"
          };
          &:hover {
            color: ${
              color === "danger"
                ? "var(--color-danger-hover)"
                : color
                ? color
                : "var(--color-primary-hover)"
            };
            background-color: ${
              color === "danger"
                ? "var(--color-danger-hover-opacity-05)"
                : "var(--color-primary-hover-opacity-05)"
            };
          }
          &:focus:not(:active) {
            outline:  3px solid ${
              color === "danger"
                ? "var(--color-danger-hover-opacity-10)"
                : "var(--color-primary-hover-opacity-10)"
            };
          }
          &:active {
            background-color: ${
              color === "danger"
                ? "var(--color-danger-hover-opacity-10)"
                : "var(--color-primary-hover-opacity-10)"
            };
          }
          :focus:not(:focus-visible) {
            outline: none;
          }
        `;
    }
  }}

  ${({ size }) => {
    switch (size) {
      case "tiny":
        return `
          padding: 0;
          max-height: 24px;
        `;
      case "small":
        return `
          padding: ${theme.spacing.sm} ${theme.spacing.lg};
          max-height: 32px;
        `;
      case "stretched":
        return `
          width: 100%;
          justify-content: center;
        `;
      case "square":
        return `
            padding: ${theme.spacing.md};
          `;
    }
  }}

  ${({ iconPosition }) =>
    iconPosition === "right" &&
    `
      flex-direction: row-reverse;
    `}

  ${({ disabled, variant }) =>
    disabled &&
    `
      pointer-events: none;
      ${
        variant === "primary"
          ? `
            background-color: ${theme.color.gray[300]};
            color: ${theme.color.gray[500]};
          `
          : variant === "secondary"
          ? `
            border-color: ${theme.color.gray[300]};
            color: ${theme.color.gray[500]};
          `
          : `
            color: ${theme.color.gray[500]};
          `
      }
    `}
`;
