import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { TabsContainer, TabsWrapper, TabItem } from "./styles";
import { TextArea } from "../../styles";

export type TabProps = {
  name: string;
  key: string;
  placeholder: string;
};

export type TabsProps = {
  tabs: TabProps[];
  scrollable?: boolean;
  setSelectedTab: (value: number) => void;
};

const Tabs = ({ tabs, scrollable, setSelectedTab }: TabsProps) => {
  const { control } = useFormContext();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setSelectedTab(newValue);
  };

  return (
    <>
      <TabsWrapper aria-label="tabs">
        <TabsContainer
          value={activeTab}
          onChange={handleChange}
          scrollButtons
          allowScrollButtonsMobile
          variant={scrollable ? "scrollable" : "standard"}
        >
          {tabs.map((item, index) => (
            <TabItem label={item.name} key={index} />
          ))}
        </TabsContainer>
      </TabsWrapper>
      {tabs.map(
        (item, index) =>
          activeTab === index && (
            <TextArea
              key={item.key}
              placeholder={item.placeholder}
              control={control}
              name={item.key}
              multiline
              rows={4}
            />
          )
      )}
    </>
  );
};

export default Tabs;
