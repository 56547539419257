import React, { forwardRef } from "react";
import BaseTag, { BaseTagProps } from "./BaseTag";
import AIIcon from "assets/svg/ai.svg?react";
import AIFormulaIcon from "assets/svg/ai-formula.svg?react";
import TagIcon from "assets/svg/tag-icon.svg?react";
import SearchDocIcon from "assets/svg/search-doc.svg?react";
import UsedIcon from "assets/svg/used.svg?react";
import { theme } from "theme";
import PendingIcon from "assets/svg/pending-icon.svg?react";

type CustomTagProps = {
  variant: "custom";
} & BaseTagProps;

type PresetTagProps = {
  variant:
    | "ai"
    | "ai-inverted"
    | "active"
    | "search-doc"
    | "tag"
    | "selected"
    | "ai-formula"
    | "main-contract"
    | "related"
    | "pending";
  children?: string | React.JSX.Element;
  className?: string;
  shape?: string;
};

const getShape = (variant: string): string => {
  return ["ai", "active", "ai-formula", "search-doc"].includes(variant)
    ? "square"
    : variant;
};

export type TagProps = CustomTagProps | PresetTagProps;

// @todo: move this to new theme file once we agreed upon the setup
// Tobi mentioned we want to have use case specific variables, so i started implementing it here
const TAG_VARIANTS = {
  active: {
    backgroundColor: theme.color.green[100],
    icon: <UsedIcon style={{ color: theme.color.green[900] }} />,
  },
  ai: {
    backgroundColor: theme.color.purple[200],
    icon: <AIIcon style={{ color: theme.color.purple[900] }} />,
  },
  "ai-formula": {
    backgroundColor: theme.color.purple[200],
    icon: <AIFormulaIcon style={{ color: theme.color.purple[900] }} />,
  },
  "ai-inverted": {
    backgroundColor: theme.color.purple[900],
    color: theme.color.white,
    icon: <AIIcon style={{ color: theme.color.white }} />,
  },
  "search-doc": {
    backgroundColor: `#ECEFFA`,
    icon: <SearchDocIcon style={{ color: "#364FA6" }} />,
  },
  tag: {
    backgroundColor: theme.color.blue[100],
    icon: <TagIcon style={{ fill: theme.color.blue[900] }} />,
  },
  selected: {
    backgroundColor: theme.color.indigo[200],
    icon: <TagIcon style={{ fill: theme.color.indigo[900] }} />,
  },
  "main-contract": {
    backgroundColor: `var(--color-primary)`,
    color: theme.color.white,
  },
  related: {
    backgroundColor: `#98fcc6`,
    color: theme.color.black,
  },

  pending: {
    backgroundColor: `#FFF4E5`,
    color: "#B57000",
    icon: <PendingIcon style={{ fill: "#B57000" }} />,
  },
} as const;

const Tag = forwardRef<HTMLDivElement, TagProps>((props, ref) => {
  const shape = getShape(props.variant);

  if (props.variant === "custom") {
    return <BaseTag {...props} ref={ref} shape={shape} />;
  }

  const variant = TAG_VARIANTS[props.variant];

  return <BaseTag {...props} {...variant} ref={ref} shape={shape} />;
});
Tag.displayName = "Tag";

export default Tag;
